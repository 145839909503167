import { actionTypes } from '../../../../common/const/actionTypes';
import { CompanyApi } from '../../../../api/companies/companiesApi';
import { CompanyFilter } from '../../../../model/filters/CompanyFilter';
import { CompanyCollection, TradeCompany } from '../../../../model/Company';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getCompaniesAction = (filter: CompanyFilter, pageNumber: number, itemsPage: number, moduleId: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	dispatch(setCompanyFilterCompleted(filter));
	CompanyApi.FilterAsync(filter, pageNumber, itemsPage, moduleId).then((content) => {
		dispatch(getCompaniesCompleted(content));
	});
};

const getCompaniesCompleted = (companyCollection: CompanyCollection) => ({
	type: actionTypes.GET_COMPANIES,
	payload: companyCollection,
	meta: {
		httpEnd: true,
	},
});

const setCompanyFilterCompleted = (filter: CompanyFilter) => ({
	type: actionTypes.SET_COMPANIES_FILTER,
	payload: filter,
	meta: {
		httpEnd: true,
	},
});

export const getTradeCompaniesAction = (moduleId: number, tradeId?: number) => (dispatch) => {
	dispatch(httpCallStartAction());

	CompanyApi.GetCompanyByTradeAsync(moduleId, tradeId).then((content) => {
		dispatch(getTradeCompaniesCompleted(content));
	});
};
const getTradeCompaniesCompleted = (companyCollection: TradeCompany[]) => ({
	type: actionTypes.GET_COMPANIES_WITH_TRADE,
	payload: companyCollection,
	meta: {
		httpEnd: true,
	},
});

