import { DocumentFile } from '.';

export interface DocumentBase {
	id: number;
	companyName: string;
	documentationTypeNameEnslish: string;
	documentationTypeNameSpanish: string;
	subDocumentationTypeNameEnslish: string;
	subDocumentationTypeNameSpanish: string;
	date: Date;
	project: string;
	countryNameSpanish: string;
	countryNameEnglish: string;
	remark: string;
	originalTypeNameEnglish: string;
	originalTypeNameSpanish: string;
	copy: string;
	location: string;
	amount?: number;
	parties: string;
	tradeId: number;
	comments: string;
}

export interface DocumentDetail {
	id: number;
	companyId: number;
	companyName: string;
	documentationTypeId: number;
	documentationSubTypeId: number;
	date: Date;
	project: string;
	countryId?: number;
	remark: string;
	originalId?: number;
	copy: string;
	location: string;
	amount: string;
	parties: string;
	comments: string;
	simple: string;
	documentsSp: DocumentFile[];
	deleteSPFiles: string[];
}
export interface DocumentsCollection {
	list: DocumentBase[];
	totalNumberOfRows: number;
}

export const createEmptyDocuments = (): DocumentsCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};

export const createEmptyDocument = (): DocumentBase => {
	return {
		id: 0,
		companyName: '',
		documentationTypeNameEnslish: '',
		documentationTypeNameSpanish: '',
		subDocumentationTypeNameEnslish: '',
		subDocumentationTypeNameSpanish: '',
		date: null,
		project: '',
		countryNameSpanish: '',
		countryNameEnglish: '',
		remark: '',
		originalTypeNameEnglish: '',
		originalTypeNameSpanish: '',
		copy: '',
		location: '',
		amount: 0,
		parties: '',
		tradeId: 0,
		comments: '',
	};
};

export const createEmptyDocumentDetail = (): DocumentDetail => {
	return {
		id: 0,
		companyId: 0,
		companyName: '',
		documentationTypeId: 0,
		documentationSubTypeId: 0,
		date: null,
		project: '',
		countryId: null,
		remark: '',
		originalId: null,
		copy: '',
		location: '',
		amount: '',
		parties: '',
		comments: '',
		simple: '',
		documentsSp: [],
		deleteSPFiles: [],
	};
};
