import { actionTypes } from '../../../common/const/actionTypes';
import { CompanyApi } from '../../../api/companies/companiesApi';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { TradeCompany } from '../../../model';

export const getTradeCompaniesAction = (moduleId: number, tradeId? : number) => (dispatch) => {
	dispatch(httpCallStartAction());

	CompanyApi.GetCompanyByTradeAsync(moduleId, tradeId).then((content) => {
		dispatch(getCompaniesCompleted(content));
	});
};
const getCompaniesCompleted = (companyCollection: TradeCompany[]) => ({
	type: actionTypes.GET_COMPANIES_WITH_TRADE,
	payload: companyCollection,
	meta: {
		httpEnd: true,
	},
});