import * as React from 'react';
import { Deed, Stockholder, Language, TradeCompany } from '../../../../model';
import { ActualStockholdersGrid } from './Grids/actualStockholdersGrid';
import { getResourcesFile } from '../../../../utils/extensions';
import { ActualStockholdersForm } from './Forms/actualStockholdersForm';
import { TooltipHost } from 'office-ui-fabric-react';
interface IProps {
	deed: Deed;
	stockholders: Stockholder[];
	stockholder: Stockholder;
	companies: TradeCompany[];
	maxShares: number;
	updateStockholders: (stockholders: Stockholder[]) => void;
	language: Language;
	isReadOnly: boolean;
	showForm: () => void;
	editStockholder: (stockholder: Stockholder, index: number, isEdit: boolean) => void;
	addStockholder: (stockholders: Stockholder, oldStockholder: Stockholder) => void;
	index: number;
	cancelEditStockholder: (isEdit: boolean) => void;
	setFinishedEdit: (finishedEdit: boolean) => void;
	isEdit:boolean;
	updateStockholder: (stockholder: Stockholder) => void;
	setStockholder: (stockholder: Stockholder) => void;
}

export class ActualStockholdersTab extends React.Component<IProps, {}> {
	public render() {
		let grid = (
			<ActualStockholdersGrid
				stockholders={this.props.stockholders}
				deleteStockholder={this.deleteStockholder}
				companies={this.props.companies}
				editStockholder={this.props.editStockholder}
				language={this.props.language}
				isReadOnly={this.props.isReadOnly}
				maxShares={this.props.maxShares}
				index={this.props.index}
				cancelEditStockholder={this.props.cancelEditStockholder}
				setFinishedEdit={this.props.setFinishedEdit}
			/>
		);
		let form = (
			<ActualStockholdersForm
				addStockholder={this.props.addStockholder}
				companies={this.props.companies}
				stockholders={this.props.deed.stockholders}
				language={this.props.language}
				stockholder={this.props.stockholder}
				isReadOnly={this.props.isReadOnly}
				maxShares={this.props.maxShares}
				isEdit={this.props.isEdit}
				updateStockholder={this.props.updateStockholder}
				setStockholder={this.props.setStockholder}
				cleanStockholderEdit= {this.props.cancelEditStockholder}
				setFinishedEdit={this.props.setFinishedEdit}
				index={this.props.index}
			/>
		);
		let language = getResourcesFile(this.props.language);

		return (
			<>
				<div className='flex-option'>
					<div className='positionTab'>
						<div className='positionTab_newPositionForm'>{form}</div>
					</div>
					<div className='ms-Grid-col ms-md9'>
						<div className='ms-Grid-col ms-xl6 item-rows'>
							<span>
								{this.props.stockholders?.length > 1 ? (
									<span>
										{this.props.stockholders?.length} {language['deeds']['list']['table']['stockholders']}
									</span>
								) : this.props.stockholders?.length === 1 ? (
									<span>
										{this.props.stockholders?.length} {language['deeds']['list']['table']['stockholder']}
									</span>
								) : (
									<span>{language['generic']['list']['table']['noResult']}</span>
								)}
							</span>
						</div>
						<div>
							<table className='internal-table'>
								<thead>
									<tr className='main-container__content__table-navigationRow'>
										<th>
											<TooltipHost
												content={language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
													'society'
												].toUpperCase()}
											>
												<label>
													{
														language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
															'society'
														]
													}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
													'n-shares'
												].toUpperCase()}
											>
												<label>
													{
														language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
															'n-shares'
														]
													}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
													'start-numeration'
												].toUpperCase()}
											>
												<label>
													{
														language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
															'start-numeration'
														]
													}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
													'numeration'
												].toUpperCase()}
											>
												<label>
													{
														language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
															'numeration'
														]
													}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
													'percentage'
												].toUpperCase()}
											>
												<label>
													{
														language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
															'percentage'
														]
													}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
													'operation-type'
												].toUpperCase()}
											>
												<label>
													{
														language['deeds']['form']['sections']['actual-shareholders']['table']['columns'][
															'operation-type'
														]
													}
												</label>
											</TooltipHost>
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody>{grid}</tbody>
							</table>
						</div>
					</div>
				</div>
			</>
		);
	}

	public readonly deleteStockholder = (stockholder: Stockholder) => {
		let stockholders = this.props.stockholders.slice();
		let stockholderIndex = stockholders.findIndex((item) => item === stockholder);
		stockholders.splice(stockholderIndex, 1);
		this.props.updateStockholders(stockholders);
	};
}
