import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { languages } from '../../../common/const/resources';
import { routeUrls } from '../../../common/const/routes';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { DeedGrid, Language, User, UserState } from '../../../model';
import { DeedCollection } from '../../../model/Deed';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { ActiveDeedFilter } from '../../../model/filters/ActiveDeedFilter';
import { DeedFilter } from '../../../model/filters/DeedFilter';
import { OrderBy } from '../../../model/filters/OrderBy';
import * as utils from '../../../utils/extensions';

export interface IProps {
	page: number;
	deeds: DeedGrid[];
	user: User;
	filter: DeedFilter;
	language: Language;
	deleteDeed(id: number);
	setDeedFilter: (filter: DeedFilter) => DeedFilter;
	deedCollection: DeedCollection;
	getDeeds: (filter: DeedFilter, pageNumber: number, itemsPage: number) => Promise<DeedCollection>;
	exportToExcel: (filter: DeedFilter) => Promise<any>;
	onchangeFilter: any;
	currentUser: UserState;
}

export interface IState {
	id: number;
	showModal: boolean;
	collapsed: boolean;
	filter: DeedFilter;
	orderByCompany: string;
	orderByDeedType: string;
	orderByDeedSubtype: string;
	orderByDocumentDate: string;
	orderByNotary: string;
	orderByInscription: string;
	orderByProtocol: string;
}

class DeedsDataGrid extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			showModal: false,
			collapsed: false,
			orderByCompany: 'ms-Icon--ChevronUpSmall',
			orderByDeedType: 'ms-Icon--ChevronUpSmall',
			orderByDeedSubtype: 'ms-Icon--ChevronUpSmall',
			orderByDocumentDate: 'ms-Icon--ChevronUpSmall',
			orderByNotary: 'ms-Icon--ChevronUpSmall',
			orderByInscription: 'ms-Icon--ChevronUpSmall',
			orderByProtocol: 'ms-Icon--ChevronUpSmall',
			filter: this.props.filter,
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ filter: this.props.filter });
		}
	}

	public render() {
		const { filter } = this.state;
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		// let userRole = this.props.currentUser.currentUser?.roles[0];
		// let userBusinessId = utils.getBusinessNewPowerByRole(userRole);

		let userIdRoles: Array<number>=[];

		this.props.currentUser.currentUser?.roles.forEach((userRole) => {
			let tempUserBusinessId = utils.getBusinessNewPowerByRole(userRole);
			userIdRoles.push(tempUserBusinessId);
		});			

		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.deedCollection.totalNumberOfRows > 1 ? (
								<span>
									{this.props.deedCollection?.totalNumberOfRows} {language['generic']['list']['table']['results']}
								</span>
							) : this.props.deedCollection.totalNumberOfRows === 1 ? (
								<span>
									{this.props.deedCollection.totalNumberOfRows} {language['generic']['list']['table']['result']}
								</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={this.props.deedCollection.totalNumberOfRows > 0 ? 'button-download' : 'button-download isDisabled'}
							onClick={this.handleExportClick}
						>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th>
									<div className='table-header'>
										<span>{language['deeds']['list']['table']['columns']['society']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActiveDeedFilter.Company === filter.CurrentFilter &&
													filter.CompanyNameOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompany}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActiveDeedFilter.Company === filter.CurrentFilter &&
													filter.CompanyNameOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompany}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['deeds']['list']['table']['columns']['type']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													(ActiveDeedFilter.TypeSpanish === filter.CurrentFilter ||
														ActiveDeedFilter.TypeEnglish === filter.CurrentFilter) &&
													filter.DeedTypeOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByDeedType}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													(ActiveDeedFilter.TypeSpanish === filter.CurrentFilter ||
														ActiveDeedFilter.TypeEnglish === filter.CurrentFilter) &&
													filter.DeedTypeOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByDeedType}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['deeds']['list']['table']['columns']['subtype']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													(ActiveDeedFilter.SubtypeSpanish === filter.CurrentFilter ||
														ActiveDeedFilter.SubtypeEnglish === filter.CurrentFilter) &&
													filter.SubtypeDeedNameOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByDeedSubtype}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													(ActiveDeedFilter.SubtypeSpanish === filter.CurrentFilter ||
														ActiveDeedFilter.SubtypeEnglish === filter.CurrentFilter) &&
													filter.SubtypeDeedNameOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByDeedSubtype}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['deeds']['list']['table']['columns']['document-date']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActiveDeedFilter.DocumentDate === filter.CurrentFilter &&
													filter.DocumentDateOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByDocumentDate}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActiveDeedFilter.DocumentDate === filter.CurrentFilter &&
													filter.DocumentDateOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByDocumentDate}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['deeds']['list']['table']['columns']['protocol']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActiveDeedFilter.protocol === filter.CurrentFilter &&
													filter.ProtocolOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByProtocol}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActiveDeedFilter.protocol === filter.CurrentFilter &&
													filter.ProtocolOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByProtocol}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['deeds']['list']['table']['columns']['notary']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActiveDeedFilter.Notary === filter.CurrentFilter && filter.NotaryOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByNotary}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActiveDeedFilter.Notary === filter.CurrentFilter && filter.NotaryOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByNotary}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['deeds']['list']['table']['columns']['inscription']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActiveDeedFilter.Inscription === filter.CurrentFilter &&
													filter.InscriptionOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByInscription}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActiveDeedFilter.Inscription === filter.CurrentFilter &&
													filter.InscriptionOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByInscription}
											></i>
										</div>
									</div>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.props.deeds.map((item) => {
								let deedTypeName = item.deedTypeNameSpanish;
								let deedSubTypeName = item.subDeedTypeNameSpanish;
								//let isBusinessItem = userBusinessId > 0 && userBusinessId?.toString() !== item.tradeId?.toString();

								let isBusinessItem = true;	
								
								if(userIdRoles.includes(0) || userIdRoles.includes(parseInt(item.tradeId))){
									isBusinessItem=false;
								}

								if (this.props.language.name.includes(ActiveLanguage.English)) {
									deedTypeName = item.deedTypeNameEnglish;
									deedSubTypeName = item.subDeedTypeNameEnglish;
								}
								return (
									<tr>
										<td className='mainColumn'>{item.companyName}</td>
										<td>{deedTypeName}</td>
										<td>{deedSubTypeName}</td>
										{item.documentDate !== null ? (
											<td>{utils.formatDate(item.documentDate, this.props.language)}</td>
										) : (
											<td></td>
										)}
										<td>{item.notaryProtocolNumber}</td>
										<td>{item.notaryName}</td>
										<td>{item.recordDataInscription}</td>
										<td className='deleteItem'>
											<div>
												<DefaultButton
													className='optionsGrid'
													text='. . .'
													menuProps={{
														shouldFocusOnMount: true,
														items: [
															{
																key: 'View',
																onClick: () => {
																	this.handleViewClick(item.id);
																},
																iconProps: {
																	iconName: 'View',
																	className: 'context-menu__icon',
																},
																name: language['deeds']['list']['table']['options']['view'],
															},
															{
																key: 'Edit',
																onClick: () => {
																	this.props.setDeedFilter(this.props.filter);
																	this.props.history.push('/deeds/edit/' + item.id);
																},
																iconProps: {
																	iconName: 'Edit',
																	className: 'context-menu__icon',
																},
																name: language['deeds']['list']['table']['options']['edit'],
																disabled: isBusinessItem,
															},
															{
																key: 'delete',
																iconProps: {
																	iconName: 'Delete',
																	className: 'context-menu__icon',
																},
																onClick: () => {
																	this.ShowModal(item.id);
																},
																name: language['deeds']['list']['table']['options']['delete'],
																disabled: isBusinessItem,
															},
														],
													}}
												/>
											</div>
											<DialogComponent
												showModal={this.state.showModal}
												TitleDialog={language['generic']['dialog']['title']}
												QuestionDialog={language['generic']['dialog']['question']}
												onSuccess={() => {
													this.Delete();
												}}
												onFail={() => {
													this.Fail();
												}}
												CancelTitle={language['generic']['dialog']['cancel']}
												SuccessTitle={language['generic']['dialog']['confirm']}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{
					<AdvancedPagination
						currentPage={this.props.page}
						items={this.props.deedCollection.totalNumberOfRows}
						link={routeUrls.deedsPagination}
						updateMaxPageItems={this.handleItemsPerPageChange}
						actualPageItems={this.state.filter.ItemsPage}
						language={this.props.language}
					/>
				}
			</>
		);
	}

	private readonly Delete = () => {
		this.props.deleteDeed(this.state.filter, this.props.language, this.state.id);
		this.Fail();
	};

	private Fail() {
		this.setState({ showModal: false, id: 0 });
	}

	private ShowModal(id: number) {
		this.setState({ showModal: true, id: id });
	}

	public handleViewClick(id: number) {
		this.props.history.push('/deeds/view/' + id);
	}

	public readonly handleOrderByCompany = (event) => {
		let filter: DeedFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedFilter.Company;
		let orderBy: string = '';

		if (filter.CompanyNameOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CompanyNameOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CompanyNameOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByCompany: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeeds(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDeedType = (event) => {
		let filter: DeedFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedFilter.TypeSpanish;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActiveDeedFilter.TypeEnglish;
		}

		let orderBy: string = '';

		if (filter.DeedTypeOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DeedTypeOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DeedTypeOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByDeedType: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeeds(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDeedSubtype = (event) => {
		let filter: DeedFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedFilter.SubtypeSpanish;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActiveDeedFilter.SubtypeEnglish;
		}

		let orderBy: string = '';

		if (filter.SubtypeDeedNameOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.SubtypeDeedNameOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.SubtypeDeedNameOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByDeedSubtype: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeeds(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDocumentDate = (event) => {
		let filter: DeedFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedFilter.DocumentDate;
		let orderBy: string = '';

		if (filter.DocumentDateOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DocumentDateOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DocumentDateOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByDocumentDate: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeeds(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByNotary = (event) => {
		let filter: DeedFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedFilter.Notary;
		let orderBy: string = '';

		if (filter.NotaryOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.NotaryOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.NotaryOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByNotary: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeeds(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByInscription = (event) => {
		let filter: DeedFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedFilter.Inscription;
		let orderBy: string = '';

		if (filter.InscriptionOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.InscriptionOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.InscriptionOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByInscription: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeeds(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByProtocol = (event) => {
		let filter: DeedFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedFilter.protocol;
		let orderBy: string = '';

		if (filter.ProtocolOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.ProtocolOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.ProtocolOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByProtocol: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeeds(filter, 1, filter.ItemsPage);
	};

	public handleExportClick = () => {
		let filter: DeedFilter = { ...this.state.filter };
		this.props.exportToExcel(filter);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: DeedFilter = { ...this.state.filter };
		filter.ItemsPage = itemsPage;
		this.setState({ filter });
		this.props.getDeeds(filter, 1, itemsPage);
	};
}

export default withRouter(DeedsDataGrid);
