import moment from 'moment';
import { IComboBoxOption } from 'office-ui-fabric-react';
import * as toastr from 'toastr';
import { languages } from '../common/const/resources';
import useHistory from '../history';
import { Language, OrderBy } from '../model';
import { ActiveLanguage } from '../model/enums/activeLanguage';
import { store } from '../store';
import { userRoles } from './enums/userRolesEnum';

const browserHistory = useHistory;

export function isURLValid(url: string): boolean {
	try {
	  new URL(url);
	  return true;
	} catch (error) {
	  return false;
	}
  }

/*export function isURLRegExValid(url: string): boolean {
	//var regex = /^(https?:\/\/)?([\w\-]+\.)+[a-z]{2,6}(\S*)$/i;
	//var regex = /^(https?:\/\/)?([\w.-]+\.[a-z]{2,})(\S*)$/;
	return regex.test(url);
 }*/
 

export function isNullOrEmpty(s: string): boolean {
	return !s;
}

export function showError(message: string): void {
	toastr.error(message, '', {
		closeButton: true,
		newestOnTop: false,
		progressBar: true,
		positionClass: 'toast-top-right',
	});
}

export function showSuccess(message: string): void {
	toastr.success(message, '', {
		closeButton: true,
		newestOnTop: false,
		progressBar: true,
		positionClass: 'toast-top-right',
	});
}

export function DecideSortingOrder(orderBy: OrderBy): string {
	return orderBy === OrderBy.Asc ? 'Asc' : 'Desc';
}

export function getParsedDate(date: Date): Date {
	if (date !== null) {
		//let parsedDate = new Date(date);
		//parsedDate.setHours(0,0,0,0);
		//parsedDate=dateAdd(parsedDate,"hour",12);  //Sumamos 12 horas (ningún usuario se encontrará en un uso horario de 11 horas de diferencia)
		
		let parsedDate=moment(date, 'YYYY-MM-DD HH:mm:ss').startOf('day').toDate();
		return parsedDate;
	}
	return date;
}

export function getParsedLocaleDate(date: Date): string {
	return date ? new Date(date).toLocaleDateString() : null;
}

export function generateRandomGuid(start: boolean): string {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
	if (start) {
		return `?${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
	} else {
		return `&${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
	}
}

export function getResourcesFile(language: Language): any {
	let resourcesFile = languages.languageES;

	if (language.name.includes(ActiveLanguage.English)) {
		resourcesFile = languages.languageEN;
	}

	return resourcesFile;
}

export function navigateBack(): void {
	browserHistory.goBack();
}

export function dateRangeToString(from: Date, to: Date, language?: Language): string {

	let format = 'DD/MM/YYYY';
	if (language?.name.includes(ActiveLanguage.English)) {
		format = 'MM/DD/YYYY';
	}

	let fromString = from != null ? moment(from).format(format) : '... ';
	let toString = to != null ? moment(to).format(format) : ' ...';

	return fromString + ' - ' + toString;
}


export function formatDate(date: Date | string, language?: Language): string {
	let fechaDate;
	let localeDate = '';
	let day = '';
	let month = '';
	let year = '';
	if (date != null) {
		if (typeof date === 'string'){
			day = date.substring(8,10).padStart(2,'0');
			month = date.substring(5, 7).padStart(2,'0');
			year = date.substring(0,4).padStart(2,'0');
		} else {
			fechaDate=date;
			day = fechaDate.getDate().toString().padStart(2,'0');
			month = (fechaDate.getMonth()+1).toString().padStart(2,'0');
			year = fechaDate.getFullYear().toString();
		}

		if (language?.name.includes(ActiveLanguage.English)) {
			localeDate = month + '/' + day + '/' + year;
		}else{
		localeDate = day + '/' + month + '/' + year;
		}
	}
	return localeDate;
}

export function formatDateInputText(fechaString: string): Date | null {
	const partesFecha = fechaString.split("/");

	// Validar que el string tenga el formato correcto
	if (partesFecha.length !== 3) {
	  return null;
	}
  
	// Convertir las partes a números
	const dia = parseInt(partesFecha[0]);
	const mes = parseInt(partesFecha[1]);
	let anio = parseInt(partesFecha[2]);

	console.log(anio);

  
	// Validar que las partes sean números válidos
	if (isNaN(dia) || isNaN(mes) || isNaN(anio)) {
	  return null;
	}
  
	if(anio < 40){
		anio = anio + 2000;
	}
	// Crear un objeto Date con las partes
	const fecha = new Date(anio, mes - 1, dia);
  
	// Validar que la fecha sea válida
	if (isNaN(fecha.getTime())) {
	  return null;
	}
  
	// Devolver la fecha
	return fecha;
}


/*
export function formatDate(date: Date | string, language?: Language): string {
	let format = 'DD/MM/YYYY';
	if (language?.name.includes(ActiveLanguage.English)) {
		format = 'MM/DD/YYYY';
	}
	let localeDate = '';
	if (date != null) {
		localeDate = moment(date).format(format);
		//localeDate = moment(date, format).startOf('day').toString();
	}
	return localeDate;
}
*/

export function getBusinessByRole(userRole: string): number {
	let business = 0;
	switch (userRole) {
		case userRoles.ajAeropuertos:
		case userRoles.tesoAeropuertos:
		case userRoles.ajAeropuertosH:
			business = 1;
			break;
		case userRoles.ajConstruccion:
		case userRoles.tesoConstruccion:
		case userRoles.ajConstruccionH:
			business = 2;
			break;
		case userRoles.ajCintra:
		case userRoles.tesoCintra:
		case userRoles.ajCintraH:
			business = 3;
			break;
		case userRoles.ajFeim:
		case userRoles.ajFeimH:
			business = 8;
			break;
		default:
			business = 0;
	}
	return business;
}

export function getBusinessNewPowerByRole(userRole: string): number {
	let business = 0;
	switch (userRole) {
		case userRoles.admin:
			business=0;
			break;
		case userRoles.ajAeropuertos:
		case userRoles.ajAeropuertosH:
			business = 1;
			break;
		case userRoles.ajConstruccion:
		case userRoles.ajConstruccionH:
			business = 2;
			break;
		case userRoles.ajCintra:
		case userRoles.ajCintraH:
			business = 3;
			break;
		case userRoles.ajCorporacion:
		case userRoles.ajCorporacionH:
			business = 4;
			break;
		case userRoles.ajFeim:
		case userRoles.ajFeimH:
			business = 8;
			break;
		default:
			business = 0;
	}
	return business;
}

export function getCompaniesMasterByRole(userRole: string): number {
	let business = 0;
	switch (userRole) {
		case userRoles.ajAeropuertosH:
			business = 1;
			break;
		case userRoles.ajConstruccionH:
			business = 2;
			break;
		case userRoles.ajCintraH:
			business = 3;
			break;
		case userRoles.ajFeimH:
			business = 8;
			break;
		default:
			business = 0;
	}
	return business;
}

export function downloadBlob(blob: any, fileName: string): void {
	const downloadLink = document.createElement('a');

	blob.blob().then(function (myBlob) {
		downloadLink.href = URL.createObjectURL(myBlob);
		downloadLink.download = fileName;
		downloadLink.click();
	});
}

export function isInRole(role: string): boolean {
	let userRoles = [];
	let user = store.getState().users.currentUser;
	if (user) {
		userRoles = user.roles;
	}

	return userRoles.includes(role);
}

export function isInRoles(roles: string[]): boolean {
	let userRoles = [];
	let user = store.getState().users.currentUser;
	if (user && user.roles) {
		userRoles = user.roles;
	}
	var filteredRoles = roles.filter((x) => userRoles.includes(x));
	if (filteredRoles.length > 0) {
		return true;
	}
	return false;
}

export function isNL(onlyNetherlands: string[]): boolean {
	let userRoles = [];
	let user = store.getState().users.currentUser;
	if (user) {
		userRoles = user.roles;
	}
	return userRoles.every(x => onlyNetherlands.includes(x))
}

export function formatNameCapitalize(name: string): string {
	return name
		.trim()
		.toLowerCase()
		.split(' ')
		.map(function (word) {
			return word[0]?.toUpperCase() + word?.substr(1).toLowerCase();
		})
		.join(' ');
}

export function getExcelNames(name: string){
	let language = store.getState().language;
	let resources = getResourcesFile(language);

	return resources['excel'][name]
}
export function getPdfNames(name: string){
	let language = store.getState().language;
	let resources = getResourcesFile(language);

	return resources['pdf'][name]
}
export function compare(a: IComboBoxOption, b: IComboBoxOption) {
	const bandA = a.text.toUpperCase();
	const bandB = b.text.toUpperCase();
  
	let comparison = 0;
	if (bandA > bandB) {
	  comparison = 1;
	} else if (bandA < bandB) {
	  comparison = -1;
	}
	return comparison;
}

export function getUserTrade(){
	let business = 0;
	var userRole = store.getState().users?.currentUser?.roles[0];
	switch (userRole) {
		case userRoles.ajAeropuertos:
		case userRoles.tesoAeropuertos:
		case userRoles.ajAeropuertosH:
			business = 1;
			break;
		case userRoles.ajConstruccion:
		case userRoles.tesoConstruccion:
		case userRoles.ajConstruccionH:
			business = 2;
			break;
		case userRoles.ajCintra:
		case userRoles.tesoCintra:
		case userRoles.ajCintraH:
			business = 3;
			break;
		case userRoles.ajCorporacion:
		case userRoles.tesoCorporacion:
		case userRoles.ajCorporacionH:
			business = 4;
			break;
		case userRoles.ajFeim:
		case userRoles.ajFeimH:
			business = 8;
			break;
		default:
			business = 0;
			break;
	}
	return business;
}
