import * as React from 'react';
import { BodyPosition, BaseType, Language, Person, TradeCompany, AdminBody, Deed, createEmptyAdminBody } from '../../../../model';
import { AdminBodyGrid } from './Grids/adminBodyGrid';
import { getResourcesFile } from '../../../../utils/extensions';
import { AdminBodyForm } from './Forms/adminBodyForm';
import { ComboBox, IComboBoxOption, TooltipHost } from 'office-ui-fabric-react';
import { ActiveLanguage } from '../../../../model/enums/activeLanguage';

interface IProps {
	bodyTypes: BaseType[];
	members: BodyPosition[];
	peopleNames: Person[];
	positionTypes: BaseType[];
	language: Language;
	position: BodyPosition;
	companies: TradeCompany[];
	isReadOnly: boolean;
	index: number;
	isEdit: boolean;
	deed: Deed;
	addPosition: (position: BodyPosition) => void;
	updateAdminBody: (members: BodyPosition[]) => void;
	showForm: () => void;
	editPosition: (position: BodyPosition, index: number, isEdit: boolean) => void;
	getPeopleNames: (filter: string) => Promise<Person[]>;
	cancelEditPosition: (isEdit: boolean) => void;
	setFinishedEdit: (finishedEdit: boolean) => void;
	updatePosition: (position: BodyPosition) => void;
	setPosition: (position: BodyPosition) => void;
	updateBody: (body: AdminBody) => void;
}

export class AdminBodyTab extends React.Component<IProps, {}> {
	public render() {
		let language = getResourcesFile(this.props.language);

		let grid = (
			<AdminBodyGrid
				bodyMembers={this.props.members}
				deleteBodyMember={this.deleteBodyMember}
				peopleNames={this.props.peopleNames}
				positionTypes={this.props.positionTypes}
				language={this.props.language}
				editPosition={this.props.editPosition}
				isReadOnly={this.props.isReadOnly}
				companies={this.props.companies}
				index={this.props.index}
				cancelEditPosition={this.props.cancelEditPosition}
				setFinishedEdit={this.props.setFinishedEdit}
			/>
		);

		let form = (
			<AdminBodyForm
				peopleNames={this.props.peopleNames}
				getPeopleNames={this.props.getPeopleNames}
				addPosition={this.props.addPosition}
				companies={this.props.companies}
				positionTypes={this.props.positionTypes}
				language={this.props.language}
				position={this.props.position}
				isReadOnly={this.props.isReadOnly}
				isEdit={this.props.isEdit}
				updatePosition={this.props.updatePosition}
				setPosition={this.props.setPosition}
				cleanPositionEdit={this.props.cancelEditPosition}
				setFinishedEdit={this.props.setFinishedEdit}
			/>
		)

		
		let bodyTypes: IComboBoxOption[] = [];
		if (this.props.bodyTypes) {
			this.props.bodyTypes.forEach((item) => {
				bodyTypes.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}

		return (
			<>
				<div className='flex-option'>
					<div className="positionTab">
						<div className="positionTab_newPositionForm">
							{form}
						</div>
					</div>
					<div className='ms-Grid-col ms-md9'>
					<div className='flex-option'>
							<div className='ms-Grid-col ms-xl4 item-rows'>
								<span>
									{this.props.members?.length > 1 ? (
										<span>{this.props.members?.length} {language['deeds']['list']['table']['positions']}</span>
									) : this.props.members?.length === 1 ? (
										<span>{this.props.members?.length} {language['deeds']['list']['table']['position']}</span>
									) : (
										<span>{language['generic']['list']['table']['noResult']}</span>
									)}
								</span>
							</div>
							<div className='ms-Grid-col ms-xl8 item-rows body-type'>
								<span>{language['deeds']['form']['sections']['administrative-body']['fields']['body-type']['title']}</span>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={bodyTypes.sort((a, b) => a.text.localeCompare(b.text))}
									placeholder={
										language['deeds']['form']['sections']['administrative-body']['fields']['body-type']['placeholder']
									}
									onChange={this.handleBodyTypeChange}
									selectedKey={this.props.deed.body?.bodyTypeId}
									useComboBoxAsMenuWidth
									disabled={this.props.isReadOnly}
								/>
							</div>
						</div>
						<div>
							<table className='internal-table'>
								<thead>
									<tr className='main-container__content__table-navigationRow'>
										<th>
											<TooltipHost
												content={language['deeds']['form']['sections']['administrative-body']['fields']['person']['title'].toUpperCase()}
											>
												<label>
													{language['deeds']['form']['sections']['administrative-body']['fields']['person']['title']}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={language['deeds']['form']['sections']['administrative-body']['fields']['society']['title'].toUpperCase()}
											>
												<label>
													{language['deeds']['form']['sections']['administrative-body']['fields']['society']['title']}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={language['deeds']['form']['sections']['administrative-body']['fields']['charge-type']['title'].toUpperCase()}
											>
												<label>
													{language['deeds']['form']['sections']['administrative-body']['fields']['charge-type']['title']}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={
													language['deeds']['form']['sections']['administrative-body']['fields']['nomination-date'][
														'title'
													].toUpperCase()
												}
											>
												<label>
													{
														language['deeds']['form']['sections']['administrative-body']['fields']['nomination-date'][
															'title'
														].toUpperCase()
													}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={
													language['deeds']['form']['sections']['administrative-body']['fields']['end-nomination-date'][
														'title'
													].toUpperCase()
												}
											>
												<label>
													{
														language['deeds']['form']['sections']['administrative-body']['fields']['end-nomination-date'][
														'title'
														]
													}
												</label>
											</TooltipHost>
										</th>
										<th>
											<TooltipHost
												content={
													language['deeds']['form']['sections']['administrative-body']['fields']['cessation-date'][
														'title'
													].toUpperCase()
												}
											>
												<label>
													{
														language['deeds']['form']['sections']['administrative-body']['fields']['cessation-date'][
														'title'
														]
													}
												</label>
											</TooltipHost>
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody>{grid}</tbody>
							</table>
						</div>
					</div>
				</div></>
		);
	}

	public readonly deleteBodyMember = (bodyMember: BodyPosition) => {
		let members = this.props.members.slice();
		let memberIndex = members?.findIndex((item) => item === bodyMember);
		members.splice(memberIndex, 1);
		this.props.updateAdminBody(members);
	};

	private readonly handleBodyTypeChange = (event, option): void => {
		let deed = {...this.props.deed}
		let body = deed.body;
		if((body === null || body === undefined)){
			body = createEmptyAdminBody();
		}
		body.bodyTypeId = parseInt(option?.key);
		this.props.updateBody(body);
	};
}
