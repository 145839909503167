import { actionTypes } from '../../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { DeedApi } from '../../../../api/deed';
import { Deed } from '../../../../model';

export const getDeedAction = (id: number) => (dispatch) => {
    dispatch(httpCallStartAction());
    DeedApi.GetByIdAsync(id).then((result) => {
        dispatch(getDeedCompleted(result));
    });
};

const getDeedCompleted = (result: Deed) => ({
    type: actionTypes.GET_DEED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});