import * as React from 'react';
import { DatePicker, DayOfWeek, IDatePickerStrings, TextField } from 'office-ui-fabric-react';
import { RecordData, Language } from '../../../../model';
import { getResourcesFile } from '../../../../utils/extensions';
import * as utils from '../../../../utils/extensions';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { ActiveLanguage } from '../../../../model/enums/activeLanguage';
import { languages } from '../../../../common/const/resources';

interface IProps {
	recordData: RecordData;
	updateRecordData: (recordData: RecordData) => void;
	language: Language;
	isReadOnly: boolean;
}

export class RegistryDataTab extends React.Component<IProps, {}> {
	public render() {
		let language = getResourcesFile(this.props.language);
		let calendarStrings: IDatePickerStrings = language['calendar'];
		return (
			<div>
				<div className='recordForm'>
					<div className='ms-Grid-row recordForm_inputs flex-option'>
						<div className='ms-Grid-col ms-xl3 main-container__content_edit_block'>
							<p>{language['deeds']['form']['sections']['registral-data']['fields']['commercial-register']['title']}</p>
							<TextField
								type='text'
								className=' main-container__content_edit_block-inner'
								value = {utils.isNullOrEmpty(this.props.recordData.merchandRecord) ? '' : this.props.recordData.merchandRecord}
								onChange={this.handleMerchantRecordChange}
								onBlur={this.handleMerchantRecordBlur}
								disabled={this.props.isReadOnly}
								maxLength={250}
							/>
							<p>{language['deeds']['form']['sections']['registral-data']['fields']['volume']['title']}</p>
							<TextField
								type='text'
								className=' main-container__content_edit_block-inner'
								value = {utils.isNullOrEmpty(this.props.recordData.volume) ? '' : this.props.recordData.volume}
								onChange={this.handleVolumeChange}
								onBlur={this.handleVolumeBlur}
								disabled={this.props.isReadOnly}
								maxLength={150}
							/>
							<p>{language['deeds']['form']['sections']['registral-data']['fields']['section']['title']}</p>
							<TextField
								type='text'
								className=' main-container__content_edit_block-inner'
								value = {utils.isNullOrEmpty(this.props.recordData.section) ? '' : this.props.recordData.section}
								onChange={this.handleSectionChange}
								onBlur={this.handleSectionBlur}
								disabled={this.props.isReadOnly}
								maxLength={150}
							/>
							<p>{language['deeds']['form']['sections']['registral-data']['fields']['page']['title']}</p>
							<TextField
								type='text'
								className=' main-container__content_edit_block-inner'
								value = {utils.isNullOrEmpty(this.props.recordData.folio) ? '' : this.props.recordData.folio}
								onChange={this.handleFolioChange}
								onBlur={this.handleFolioBlur}
								disabled={this.props.isReadOnly}
								maxLength={50}
							/>
						</div>
						<div className='ms-Grid-col ms-xl3 main-container__content_edit_block'>
							<p>{language['deeds']['form']['sections']['registral-data']['fields']['sheet']['title']}</p>
							<TextField
								type='text'
								className=' main-container__content_edit_block-inner'
								value = {utils.isNullOrEmpty(this.props.recordData.sheet) ? '' : this.props.recordData.sheet}
								onChange={this.handleSheetChange}
								onBlur={this.handleSheetBlur}
								disabled={this.props.isReadOnly}
								maxLength={50}
							/>

							<p>{language['deeds']['form']['sections']['registral-data']['fields']['inscription']['title']}</p>
							<TextField
								type='text'
								className=' main-container__content_edit_block-inner'
								value = {utils.isNullOrEmpty(this.props.recordData.inscription) ? '' : this.props.recordData.inscription}
								onChange={this.handleInscriptionChange}
								onBlur={this.handleInscriptionBlur}
								disabled={this.props.isReadOnly}
								maxLength={250}
							/>
							<p>{language['deeds']['form']['sections']['registral-data']['fields']['inscription-date']['title']}</p>
							<div className='datepickerContainer'>
								<DatePicker
									formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
									className={''}
									firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
									allowTextInput={true}
									parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
									placeholder={language['generic']['date']}
									strings={calendarStrings}
									value={utils.getParsedDate(this.props.recordData?.inscriptionDate)}
									onSelectDate={this.handleInscriptionDateChange}
									disabled={this.props.isReadOnly}
								/>
								<IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => this.onClearDate()} disabled={this.props.isReadOnly} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private readonly handleMerchantRecordChange = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.merchandRecord = value.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleMerchantRecordBlur = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.merchandRecord = value.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleSheetChange = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.sheet = value.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleSheetBlur = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.sheet = value.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleFolioChange = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.folio = value.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleFolioBlur = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.folio = value.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleVolumeChange = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.volume = value.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleVolumeBlur = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.volume = value.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleSectionChange = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.section = value.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleSectionBlur = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.section = value.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleInscriptionChange = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.inscription = value.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleInscriptionBlur = (value) => {
		let recordData = { ...this.props.recordData };
		recordData.inscription = value.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleInscriptionDateChange = (value): void => {
		let recordData = { ...this.props.recordData };
		recordData.inscriptionDate = value;
		this.props.updateRecordData(recordData);
	};

	private readonly onClearDate = (): void => {
		this.handleInscriptionDateChange(null);
    };		
}
