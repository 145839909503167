import { OrderBy } from '../../model/filters/OrderBy';
import { ActiveCompanyOrderFilter } from '../../model/filters/ActiveCompanyOrderFilter';
import { ISelectOption } from '../../common/customSelect/customSelect';

export interface CompanyFilter {
	SocietyOrder: OrderBy;
	TradeOrder: OrderBy;
	CifOrder: OrderBy;
	CountryOrder: OrderBy;
	ConstitutionDateOrder: OrderBy;

	SocietiesId: number[];
	SocietiesName?: string[];
	TradeId: number;
	TradeName?: string;
	Cif: string;
	CountryId: number;
	CountryName?: string;
	UniqueCode?: string;

	CurrentFilter: ActiveCompanyOrderFilter;
	ItemsPage: number;
	selectedCompanies: ISelectOption[];
}
export const createEmptyCompanyFilter = (): CompanyFilter => {
	return {
		CurrentFilter: ActiveCompanyOrderFilter.Society,
		SocietyOrder: OrderBy.Asc,
		TradeOrder: OrderBy.Asc,
		CifOrder: OrderBy.Asc,
		CountryOrder: OrderBy.Asc,
		ConstitutionDateOrder: OrderBy.Asc,
		SocietiesId: [],
		SocietiesName: [],
		TradeId: 0,
		Cif: '',
		CountryId: 0,
		ItemsPage: 10,
		UniqueCode: '',
		selectedCompanies: []
	};
};
