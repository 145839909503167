export interface BodyTypesGrid {
	id: number;
	descriptionSpanish: string;
	descriptionEnglish: string;
	tradeId: number;
}

export interface BodyTypesCollection {
	list: BodyTypesGrid[];
	totalNumberOfRows: number;
}

export interface BodyTypes {
	id: number;
	descriptionSpanish: string;
	descriptionEnglish: string;
}

export const createEmptyBodyTypes = (): BodyTypesCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};

export const createBodyType = (): BodyTypes => {
	return {
		id: 0,
		descriptionSpanish: '',
		descriptionEnglish: '',
	};
};
