import { connect } from 'react-redux';
import { DeedType, Language } from '../../model';
import { StateReducer } from '../../reducers';
import { getDeedTypeAction, getDeedTypePrimariesAction, saveDeedTypeAction } from './actions/index';
import { DeedTypeComponent } from './crud/deedTypeComponent';

const mapStateToProps = (state: StateReducer) => ({
	user: state.user,
	language: state.language,
	deedType: state.deedType,
	deedTypeCollection: state.deedTypeCollection,
	deedTypeFilter: state.deedTypeFilter,
	deedTypeResponse: state.deedTypeResponse,
	deedTypePrimaries: state.deedTypePrimaries,
	loading: state.http?.inProgress
});

const mapDispatchToProps = (dispatch: any) => ({
	getDeedType: (id: number) => dispatch(getDeedTypeAction(id)),
	getDeedTypePrimaries: () => dispatch(getDeedTypePrimariesAction()),
	saveDeedType: (deedType: DeedType, language: Language) => dispatch(saveDeedTypeAction(deedType, language)),
});


export const DeedTypeContainer = connect(mapStateToProps, mapDispatchToProps)(DeedTypeComponent);

