import { DocumentTypeApi } from '../../../api/documentType/documentTypeApi';
import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DocumentType } from '../../../model';

export const getDocumentTypePrimariesAction = () => (dispatch) => {
	dispatch(httpCallStartAction());

	DocumentTypeApi.GetDocumentTypePrimaries().then((result) => {
		dispatch(getDocumentTypePrimariesCompleted(result));
	});
};

const getDocumentTypePrimariesCompleted = (documentTypeNames: DocumentType[]) => ({
	type: actionTypes.GET_DOCUMENT_TYPE_PRIMARIES,
	payload: documentTypeNames,
	meta: {
		httpEnd: true,
	},
});
