import { SiteProps } from '../../model/Config';
import * as fetch from 'isomorphic-fetch';
import { AuthorizationFilter, ActiveAuthorizationFilter } from '../../model/filters';
import { AuthorizationAccountCollection, Person } from '../../model';
import { ApiUrls } from '../../common/const/api';
import * as utils from '../../utils/extensions';
import { DecideSortingOrder } from '../../utils/extensions';
import * as msal from '../../common/security/msal';

const FilterAsync = async (filter: AuthorizationFilter, pageNumber: number, itemsPage: number): Promise<AuthorizationAccountCollection> => {
	let baseUrl = `${SiteProps.SiteURL}/api/${ApiUrls.AuthorizationAccount}/Get`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = GetQueryString(filter);
	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) => response.json());
};

function GetQueryString(filter: AuthorizationFilter): string {
	return GetOrderByQueryString(filter) + GetFilterQueryString(filter);
}

function GetOrderByQueryString(filter: AuthorizationFilter): string {
	let queryString: string = '';

	switch (filter.CurrentFilter) {
		case ActiveAuthorizationFilter.Account:
			queryString += '&AccountOrder=' + DecideSortingOrder(filter.AccountOrder);
			break;
		case ActiveAuthorizationFilter.AuthorizedName:
			queryString += '&AuthorizedOrder=' + DecideSortingOrder(filter.AuthorizedOrder);
			break;
		case ActiveAuthorizationFilter.AuthorizedNif:
			queryString += '&NifAutorizrdOrder=' + DecideSortingOrder(filter.NifAutorizrdOrder);
			break;
		case ActiveAuthorizationFilter.Company:
			queryString += '&CompanyOrder=' + DecideSortingOrder(filter.CompanyOrder);
			break;
		case ActiveAuthorizationFilter.CreationDate:
			queryString += '&CreationDateOrder=' + DecideSortingOrder(filter.CreationDateOrder);
			break;
		case ActiveAuthorizationFilter.RevocationDate:
			queryString += '&RevocationDateOrder=' + DecideSortingOrder(filter.RevocationDateOrder);
			break;
		case ActiveAuthorizationFilter.Status:
			queryString += '&StatusOrder=' + DecideSortingOrder(filter.StatusOrder);
			break;
		default:
			queryString += '&AccountOrder=' + DecideSortingOrder(filter.AccountOrder);
			break;
	}

	return queryString;
}

function GetFilterQueryString(filter: AuthorizationFilter): string {
	let queryString: string = '';

	if (filter.CompaniesId.length > 0) {
		queryString += '&CompaniesId=' + filter.CompaniesId.toString() + '';
	}

	if (filter.Business != null) {
		queryString += '&TradeId=' + filter.Business;
	}

	if (filter.Cif != null) {
		queryString += '&Cif=' + filter.Cif;
	}

	if (filter.UniqueCode != null) {
		queryString += '&CodigoUnico=' + filter.UniqueCode;
	}

	if (filter.Account != null) {
		queryString += '&Account=' + filter.Account;
	}

	if (filter.AuthorizedId != null) {
		queryString += '&AuthorizedId=' + filter.AuthorizedId;
	}

	if (filter.AuthorizedNif != null) {
		queryString += '&AuthorizedNif=' + filter.AuthorizedNif;
	}

	if (filter.Status != null) {
		queryString += '&StateId=' + filter.Status;
	}

	if (filter.CreationDateFrom != null) {
		queryString += '&CreationDateFrom=' + filter.CreationDateFrom.toDateString();
	}

	if (filter.CreationDateTo != null) {
		queryString += '&CreationDateTo=' + filter.CreationDateTo.toDateString();
	}

	if (filter.RevocationDateFrom != null) {
		queryString += '&RevocationDateFrom=' + filter.RevocationDateFrom.toDateString();
	}

	if (filter.RevocationDateTo != null) {
		queryString += '&RevocationDateTo=' + filter.RevocationDateTo.toDateString();
	}

	return queryString;
}

function ExportToExcelQuery(filter: AuthorizationFilter): string {
	let queryString: string = '';

	if (filter.CompaniesId.length > 0) {
		queryString += '&CompaniesId=' + filter.CompaniesId.toString() + '';
	}

	if (filter.Business != null) {
		queryString += '&TradeId=' + filter.Business;
	}

	if (filter.Cif != null) {
		queryString += '&Cif=' + filter.Cif;
	}

	if (filter.Account != null) {
		queryString += '&Account=' + filter.Account;
	}

	if (filter.AuthorizedId != null) {
		queryString += '&AuthorizedId=' + filter.AuthorizedId;
	}

	if (filter.AuthorizedNif != null) {
		queryString += '&AuthorizedNif=' + filter.AuthorizedNif;
	}

	if (filter.Status != null) {
		queryString += '&StateId=' + filter.Status;
	}

	if (filter.CreationDateFrom != null) {
		queryString += '&CreationDateFrom=' + filter.CreationDateFrom;
	}

	if (filter.CreationDateTo != null) {
		queryString += '&CreationDateTo=' + filter.CreationDateTo;
	}

	if (filter.RevocationDateFrom != null) {
		queryString += '&RevocationDateFrom=' + filter.RevocationDateFrom;
	}

	if (filter.RevocationDateTo != null) {
		queryString += '&RevocationDateTo=' + filter.RevocationDateTo;
	}

	return queryString;
}

async function ExportToExcel(filter: AuthorizationFilter): Promise<any> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.AuthorizationAccount}/ExportToExcel`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = ExportToExcelQuery(filter);

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('authorizations')),
	);
}

async function GetAuthorizedUsersAsync(filter: string): Promise<Person[]> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.AuthorizationAccount}/AuthorizedUsers?filter=${filter}`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	return fetch(baseURL, obj).then((response) => response.json());
}

export const AuthorizationApi = {
	FilterAsync,
	ExportToExcel,
	GetAuthorizedUsersAsync,
};
