import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { BaseType } from '../../../../model/BaseType';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { GenericApi } from '../../../../api/generic';

export const getPowerTypeNamesAction = () => (dispatch) => {
    dispatch(httpCallStartAction());

    GenericApi.GetAsync<BaseType>(ApiUrls.PowerTypes).then((result) => {
        dispatch(getPowerTypeNamesActionCompleted(result));
    });
};

const getPowerTypeNamesActionCompleted = (powerTypeNames: BaseType[]) => ({
    type: actionTypes.GET_POWER_TYPE_NAMES,
    payload: powerTypeNames,
    meta: {
        httpEnd: true,
    }
});
