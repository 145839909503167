import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DocumentationApi } from '../../../api/documentation';
import { DocumentDetail} from '../../../model';

export const getDocumentAction = (id: number) => (dispatch) => {
    dispatch(httpCallStartAction());
    DocumentationApi.GetByIdAsync(id).then((result) => {
        dispatch(getDocumentCompleted(result));
    });
};

const getDocumentCompleted = (result: DocumentDetail) => ({
    type: actionTypes.GET_DOCUMENT,
    payload: result,
    meta: {
        httpEnd: true,
    }
});