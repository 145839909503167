import { BaseType, Person } from './';

export interface PositionGrid {
	id: number;
	name: string;
	companyName: string;
	positionTypeSpanish: string;
	positionTypeEnglish: string;
	dni: string;
}

export interface PositionCollection {
	list: PositionGrid[];
	totalNumberOfRows: number;
}

export interface Position {
	id: number;
	personId: number;
	name: string;
	Dni: string;
	companyName: string;
	positionTypeSpanish: string;
	positionTypeEnglish: string;
	appointmentDate: string;
	terminationDate: string;
}

export interface PositionDetail {
	name: string;
	dni: string;
	address: string;
	nationality: string;
	totalNumberOfRows: number;
	companies: PositionCompany[];
}

export interface PositionCompany {
	name: string;
	companyId: number;
	positionTypeEnglish: string;
	positionTypeSpanish: string;
	appointmentDate: Date;
	finalDate: Date;
	terminationDate: Date;
	undefined: boolean;
	proposedAppoiment: string;
}

export interface PositionRecordCompany extends PositionCompany {
	representative: Person;
	positionType: BaseType;
}

export const createEmptyPositions = (): PositionCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};

export const createEmptyPosition = (): PositionDetail => {
	let result: PositionDetail = {
		totalNumberOfRows: 0,
		address: '',
		companies: [],
		dni: '',
		name: '',
		nationality: '',
	};

	return result;
};
