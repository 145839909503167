import { actionTypes } from '../common/const/actionTypes';
import { createEmptyRepresentativeDetailFilter, createEmptyRepresentativeFilter, RepresentativeDetailFilter, RepresentativeFilter } from '../model/filters/RepresentativeFilter';
import { createEmptyRepresentativeCollection, createEmptyRepresentativeDetailCollection, RepresentativeCollection, RepresentativeDetailCollection } from '../model/Representative';

export const representativeListReducer = (state = createEmptyRepresentativeCollection(), action) => {
	switch (action.type) {
		case actionTypes.GET_REPRESENTATIVES:
			return handleGetCompaniesCompleted(state, action.payload);
	}

	return state;
};

export const representativeDetailReducer = (state = createEmptyRepresentativeDetailCollection(), action) => {
	switch (action.type) {
		case actionTypes.GET_REPRESENTATIVE:
			return handleGetCompanyCompleted(state, action.payload);
	}

	return state;
};


export const representativeFormReducer = (state, action) => {
	switch (action.type) {
		case actionTypes.SAVE_REPRESENTATIVE_COMPLETED:
			return handleSaveRepresentativeCompleted(state, action.payload);
		case actionTypes.DELETE_REPRESENTATIVE:
			return handleDeleteRepresentativeCompleted(state, action.payload);
	}

	return state;
};



const handleSaveRepresentativeCompleted = (state: number, payload: number) => {
	return payload;
};

const handleDeleteRepresentativeCompleted = (state: number, payload: number) => {
	return payload;
};

export const representativeDetailFilterReducer = (state = createEmptyRepresentativeDetailFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_REPRESENTATIVE_DETAIL_FILTER:
			return handleSetRepresentativeDetailFilterCompleted(state, action.payload);
	}

	return state;
};
export const representativeFilterReducer = (state = createEmptyRepresentativeFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_REPRESENTATIVE_FILTER:
			return handleSetRepresentativeFilterCompleted(state, action.payload);
	}

	return state;
};

const handleGetCompaniesCompleted = (state: RepresentativeCollection, payload: RepresentativeCollection) => {
	return payload;
};

const handleGetCompanyCompleted = (state: RepresentativeDetailCollection, payload: RepresentativeDetailCollection) => {
	return payload;
};

const handleSetRepresentativeDetailFilterCompleted = (state: RepresentativeDetailFilter, payload: RepresentativeDetailFilter) => {
	return payload;
};
const handleSetRepresentativeFilterCompleted = (state: RepresentativeFilter, payload: RepresentativeFilter) => {
	return payload;
};
