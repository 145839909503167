import { actionTypes } from '../../../../common/const/actionTypes';
import { DeedFilter } from '../../../../model/filters';

export const setDeedFilterAction = (filter: DeedFilter) => (dispatch) => {
    dispatch(setDeedFilterActionCompleted(filter));
};
const setDeedFilterActionCompleted = (deedFilter
    : DeedFilter) => ({
    type: actionTypes.SET_DEED_FILTER,
    payload: deedFilter
});
