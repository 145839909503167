import * as React from 'react';
import '../../styles/components.scss';
interface Props {
	showSpinner: boolean;
	message: string;
}

export const SpinnerComponent: React.StatelessComponent<Props> = (props) => {
	return props.showSpinner ? (
		<div>
			<div className='spinnerOverlay'>
				<div className='spinner'>
					<span>{props.message}</span>
				</div>
			</div>
		</div>
	) : null;
};
