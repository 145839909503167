export interface Stockholder {
	id: number;
	numberStock: number;
	startNumbering?: number;
	numberingStock: string;
	percentageStock: string;
	operationType?: OperationType;
	companyStockholderId?: number;
	deedId?:number;
}

export enum OperationType {
	Compra = 1,
	Venta = 2,
}

export interface StockholderRecordCompany {
	id: number;
	name: string;
	numberStock: number;
	startNumbering: number;
	numberingStock: string;
	percentageStock: string;
	operationType: string;
	totalNumberStock: number;
}

export const createEmptyStockholder = (): Stockholder => {
	return {
		id: 0,
		numberStock: 0,
		startNumbering: null,
		numberingStock: null,
		percentageStock: null,
		operationType: null,
		companyStockholderId: null,
	};
};
