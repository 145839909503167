import * as React from 'react';
import PropTypes from 'prop-types';
import { Language, PositionCompany, PositionDetail } from '../../../model';
import { languages } from '../../../common/const/resources';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import '../../../styles/pages/representatives.scss';
import { formatNameCapitalize } from '../../../utils/extensions';

export interface IProps {
	positionInfo: PositionDetail;
	language: Language;
}

export class PositionDetailGrid extends React.Component<IProps, {}> {
	static contextTypes = {
		router: PropTypes.object,
	};

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<div className='ms-Grid-row main-container__table'>
				<table className='detailTable'>
					<thead>
						<tr className='first_row'>
							<th>{language['positions']['detail']['fields']['table']['columns']['society']}</th>
							<th>{language['positions']['detail']['fields']['table']['columns']['position']}</th>
							<th>{language['positions']['detail']['fields']['table']['columns']['appointmentDate']}</th>
							<th>{language['positions']['detail']['fields']['table']['columns']['finalDate']}</th>
							<th>{language['positions']['detail']['fields']['table']['columns']['appointment']}</th>
							<th>{language['positions']['detail']['fields']['table']['columns']['terminationDate']}</th>
							<th>{language['positions']['detail']['fields']['table']['columns']['proposedAppointment']}</th>
						</tr>
					</thead>
					<tbody>
						{this.props.positionInfo.companies?.map((item) => {
							return this.getCompaniesRow(item);
						})}
					</tbody>
				</table>
			</div>
		);
	}

	private getCompaniesRow(entry: PositionCompany) {
		let positionTypeName: string = entry.positionTypeSpanish;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			positionTypeName = entry.positionTypeEnglish;
		}
		let state = entry.undefined ? 'Indefinido' : 'Temporal';
		let stateColor = '';
		if (entry.undefined) {
			stateColor = 'status-green';
		} else {
			stateColor = 'status-red';
		}

		return (
			<tr>
				{entry.name ? <td> {formatNameCapitalize(entry.name)}</td> : <td className='align-center'>-</td>}
				{positionTypeName ? <td> {formatNameCapitalize(positionTypeName)}</td> : <td className='align-center'>-</td>}
				{this.parseDate(entry.appointmentDate) ? (
					<td className='align-right'> {this.parseDate(entry.appointmentDate)}</td>
				) : (
					<td className='align-center'>-</td>
				)}
				{this.parseDate(entry.finalDate) ? (
					<td className='align-right'> {this.parseDate(entry.finalDate)}</td>
				) : (
					<td className='align-center'>-</td>
				)}
				<td className='status-cell'>
					{state === 'Indefinido' ? (
						<span>
							<i className={'ms-Icon ms-Icon--CompletedSolid ' + stateColor} aria-hidden='true'></i>
							{state}
						</span>
					) : (
						<span>
							<i className={'ms-Icon ms-Icon--StatusErrorFull ' + stateColor} aria-hidden='true'></i>
							{state}
						</span>
					)}
				</td>
				{this.parseDate(entry.terminationDate) ? (
					<td className='align-right'> {this.parseDate(entry.terminationDate)}</td>
				) : (
					<td className='align-center'>-</td>
				)}
				{entry.proposedAppoiment ? <td> {entry.proposedAppoiment}</td> : <td className='align-center'>-</td>}
			</tr>
		);
	}

	private parseDate(date: Date) {
		if (date) {
			return new Date(date).toLocaleDateString();
		}

		return null;
	}
}
