import { NotaryPeopleApi } from '../../../../api/notaryPeople';
import { actionTypes } from '../../../../common/const/actionTypes';
import { NotaryPeople } from '../../../../model';

export const getNotaryPeopleNamesAction = (filter: string) => (dispatch) => {
	NotaryPeopleApi.GetNotaryPeopleNamesFilteredAsync(filter).then((content) => {
		dispatch(getNotaryPeopleCompleted(content));
	});
};

const getNotaryPeopleCompleted = (notaryPeople: NotaryPeople[]) => ({
	type: actionTypes.GET_NOTARYPEOPLE_NAMES,
	payload: notaryPeople,
});
