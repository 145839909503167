import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { PositionDetailPage } from './page';
import { getPositionAction } from './actions/getPosition';
import { PositionDetailFilter } from '../../../model/filters/';
import { exportToPdfAction } from './actions/exportToPdf';

const mapStateToProps = (state: StateReducer) => ({
	position: state.position,
	positionDetailFilter: state.positionDetailFilter,
	language: state.language,
	pdfDownloadLink: state.csvDownloadLink,
});

const mapDispatchToProps = (dispatch: any) => ({
	getPosition: (filter: PositionDetailFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getPositionAction(filter, pageNumber, itemsPage)),
	exportToPdf: (personId: number) => dispatch(exportToPdfAction(personId)),
});

export const PositionDetailContainer = connect(mapStateToProps, mapDispatchToProps)(PositionDetailPage);
