import { DocumentationApi } from '../../../api/documentation';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import useHistory from '../../../history';
import { DocumentDetail, Language } from '../../../model';
import { getResourcesFile, showError, showSuccess } from '../../../utils/extensions';
const browserHistory = useHistory;

export const saveDocumentAction = (document: DocumentDetail, currentLanguage: Language) => (dispatch) => {
	dispatch(httpCallStartAction());
	let language = getResourcesFile(currentLanguage);
	if (document.id > 0) {
		handleUpdateDocument(document, language, dispatch);
	} else {
		handleCreateDocument(document, language, dispatch);
	}
};

const handleUpdateDocument = (document: DocumentDetail, language: any, dispatch) => {
	DocumentationApi.UpdateAsync(ApiUrls.Documentation, document).then((result: any) => {
		if (result.error) {
			showError(language['documentation']['messages']['error-update']);
			dispatch(saveDocumentCompleted(result));
		} else {
			showSuccess(language['documentation']['messages']['success-update']);
			browserHistory.goBack();
			dispatch(saveDocumentCompleted(result));
		}
	});
};

const handleCreateDocument = (document: DocumentDetail, language: any, dispatch) => {
	DocumentationApi.AddAsync(ApiUrls.Documentation, document).then((result: any) => {
		if (result > 0) {
			showSuccess(language['documentation']['messages']['success']);
			browserHistory.goBack();
			dispatch(saveDocumentCompleted(result));
		} else {
			showError(language['documentation']['messages']['error']);
			dispatch(saveDocumentCompleted(result));
		}
	});
};

const saveDocumentCompleted = (result: number) => ({
	type: actionTypes.SAVE_DOCUMENTATION_COMPLETED,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
