import { connect } from 'react-redux';
import { Deed, FileSp, Language } from '../../../model';
import { Modules } from '../../../model/enums';
import { StateReducer } from '../../../reducers';
import { getPowerTypeNamesAction } from '../../deeds/form/actions/getPowerTypeNames';
import { getPowersAction } from '../../representatives/list/actions/getPowers';
import { getBusinessNamesAction } from '../form/actions/getBusiness';
import { getDeedTypePrimariesAction } from '../form/actions/getDeedTypePrimaries';
import { getDeedTypeSubTypeAction } from '../form/actions/getDeedTypeSubTypes';
import { deleteFileSpAction } from './actions/deleteSpFile';
import { getBodyTypeNamesAction } from './actions/getBodyTypeNames';
import { getTradeCompaniesAction } from './actions/getCompanies';
import { getDeedAction } from './actions/getDeed';
import { getPeopleNamesAction } from './actions/getPeopleNames';
import { getPositionTypeNamesAction } from './actions/getPositionTypeNames';
import { getOriginalsAction } from './actions/getOriginals';
import { saveDeedAction } from './actions/saveDeed';
import { getNotaryPeopleNamesAction } from './actions/getNotaryNames';
import { DeedsForm } from './page';

const moduleId = Modules.Deeds;

const mapStateToProps = (state: StateReducer) => ({
	deed: state.deed,
	companies: state.tradeCompanies,
	deedTypeSubTypes: state.deedTypeSubTypes,
	deedTypePrimaries: state.deedTypePrimaries,
	bodyTypes: state.bodyTypeNames,
	positionTypes: state.positionTypeNames,
	peopleNames: state.peopleNames,
	notaryPeopleNames: state.notaryPeopleNames,
	powers: state.powerNames,
	deedFilter: state.deedFilter,
	powerTypeNames: state.powerTypeNames,
	language: state.language,
	user: state.user,
	business: state.businessNames,
	loading: state.http?.inProgress,
	currentUser: state.users,
	originals: state.originals
});

const mapDispatchToProps = (dispatch: any) => ({
	getDeed: (id: number) => dispatch(getDeedAction(id)),
	getCompanies: (tradeId?: number) => dispatch(getTradeCompaniesAction(moduleId, tradeId)),
	getDeedTypePrimaries: () => dispatch(getDeedTypePrimariesAction()),
	getDeedTypeSubTypes: () => dispatch(getDeedTypeSubTypeAction()),
	getBodyTypeNames: () => dispatch(getBodyTypeNamesAction()),
	getPositionTypes: () => dispatch(getPositionTypeNamesAction()),
	getPeopleNames: (filter: string) => dispatch(getPeopleNamesAction(filter)),
	getNotaryPeopleNames: (filter: string) => dispatch(getNotaryPeopleNamesAction(filter)),
	getPowers: () => dispatch(getPowersAction()),
	getPowerTypes: () => dispatch(getPowerTypeNamesAction()),
	saveDeed: (deed: Deed, language: Language) => dispatch(saveDeedAction(deed, language)),
	deleteFileSp: (fileSp: FileSp) => dispatch(deleteFileSpAction(fileSp)),
	getBusiness: () => dispatch(getBusinessNamesAction()),
	getOriginals: () => dispatch(getOriginalsAction()),
});

export const DeedsFormContainer = connect(mapStateToProps, mapDispatchToProps)(DeedsForm);
