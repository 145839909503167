import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { DeedApi } from '../../../../api/deed';
import { FileSp } from '../../../../model';

export const deleteFileSpAction = (fileSp: FileSp) => (dispatch) => {
    DeedApi.DeleteFileSpAsync(ApiUrls.Deed, fileSp).then((result) => {
        dispatch(deleteFileSpCompleted(result));
    });

};
const deleteFileSpCompleted = (result: boolean) => ({
    type: actionTypes.DELETE_SP_FILE,
    payload: result
});