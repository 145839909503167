import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { languages } from '../../../common/const/resources';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import { DeedType, DeedTypeCollection, Language, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { DeedTypeFilter, createEmptyDeepTypeFilter } from '../../../model/filters';

export interface IProps {
	getDeedTypeCollection: (filter: DeedTypeFilter, pageNumbers: number, itemsPage: number) => Promise<DeedTypeCollection>;
	getDeedTypePrimaries: () => DeedType[];
	deedTypePrimaries: DeedType[];
	onchangeFilter: any;
	deedTypeCollection: DeedTypeCollection;
	filter: DeedTypeFilter;
	collapsed: boolean;
	user: UserState;
	language: Language;
	itemsPage: number;
}

export interface IState {
	actualFilter: DeedTypeFilter;
	collapsed: boolean;
	isBusinessDisabled: boolean;
}

export class DeedTypeFilterComponent extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			collapsed: false,
			actualFilter: createEmptyDeepTypeFilter(),
			isBusinessDisabled: false,
		};
	}

	componentDidMount() {
		this.setState({ actualFilter: this.props.filter });
		this.props.getDeedTypePrimaries();
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		let deedTypePrimariesCombo: IComboBoxOption[] = [];
				this.props.deedTypePrimaries.forEach((item: DeedType) => {
			deedTypePrimariesCombo.push({
				key: item.id,
				text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish,
			});
		});
		deedTypePrimariesCombo.sort((a, b) => a.text.localeCompare(b.text));
		deedTypePrimariesCombo.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		deedTypePrimariesCombo.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });

		let affectsCapitalCombo: IComboBoxOption[] = [
			{ key: -1, text: language['generic']['both'] },
			{ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
			{ key: 1, text: language['generic']['yes'] },
			{ key: 0, text: language['generic']['no'] }
		];
		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div className={this.state.collapsed ? 'ms-Grid-row filter_header-collapse' : 'ms-Grid-row filter_header'}>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p> {language['deedType']['filter']['options']['descriptionSpanish']['title']} </p>
								<TextField
									type='text'
									value={this.state.actualFilter.DescriptionSpanish}
									onChange={this.handleDescriptionSpanishChange}
									onBlur={this.handleDescriptionSpanishBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deedType']['filter']['options']['descriptionEnglish']['title']} </p>
								<TextField
									type='text'
									value={this.state.actualFilter.DescriptionEnglish}
									onChange={this.handleDescriptionEnglishChange}
									onBlur={this.handleDescriptionEnglishBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deedType']['fields']['type']['title']} </p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={deedTypePrimariesCombo}
									placeholder={language['deedType']['filter']['options']['type']['placeholder']}
									onChange={this.handleTypeChange}
									selectedKey={this.state.actualFilter.FatherId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deedType']['fields']['capital']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={affectsCapitalCombo}
									placeholder={language['deedType']['fields']['capital']['title']}
									onChange={this.handleAffectsCapitalChange}
									selectedKey={this.state.actualFilter.AffectsCapital}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterDeedType}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterDeedType}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.actualFilter.DescriptionSpanish !== '' ? '' : 'none' }}>
								<span className='key'>{language['deedType']['filter']['options']['descriptionSpanish']['title']}</span>
								<span className='value'>{this.state.actualFilter.DescriptionSpanish}</span>
							</p>
							<p style={{ display: this.state.actualFilter.DescriptionEnglish !== '' ? '' : 'none' }}>
								<span className='key'>{language['deedType']['filter']['options']['descriptionEnglish']['title']}</span>
								<span className='value'>{this.state.actualFilter.DescriptionEnglish}</span>
							</p>
							<p style={{ display: this.state.actualFilter.FatherId > 0 ? '' : 'none' }}>
								<span className='key'>{language['deedType']['filter']['options']['type']['title']}</span>
								<span className='value'>{this.state.actualFilter.FatherName}</span>
							</p>
							<p>
								<span className='key'>{language['deedType']['filter']['options']['capital']['title']}</span>
								{this.state.actualFilter.AffectsCapital === -1 ?
									<span className='value'>
										{this.state.actualFilter.AffectsCapital === -1 ? language['generic']['both'] : ''}
									</span> :
									<span className='value'>
										{this.state.actualFilter.AffectsCapital === 1 ? language['generic']['yes'] : language['generic']['no']}
									</span>
								}
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}
	private readonly handleAffectsCapitalChange = (event, option): void => {
		let filter = { ...this.state.actualFilter };
		filter.AffectsCapital = option?.key;
		this.setState({ actualFilter: filter });
	};

	private readonly handleFiltersCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	private readonly handleDescriptionSpanishChange = (value) => {
		let state = { ...this.state };
		state.actualFilter.DescriptionSpanish = value.target.value;
		this.setState(state);
	};
	private readonly handleDescriptionSpanishBlur = (value) => {
		let state = { ...this.state };
		state.actualFilter.DescriptionSpanish = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleDescriptionEnglishChange = (value) => {
		let state = { ...this.state };
		state.actualFilter.DescriptionEnglish = value.target.value;
		this.setState(state);
	};
	private readonly handleDescriptionEnglishBlur = (value) => {
		let state = { ...this.state };
		state.actualFilter.DescriptionEnglish = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleTypeChange = (event, option): void => {
		let state = { ...this.state };
		state.actualFilter.FatherId = option?.key;
		state.actualFilter.FatherName = option?.text;
		this.setState(state);
	};

	private readonly cleanFilterDeedType = () => {
		let resetFilter = createEmptyDeepTypeFilter();
		this.setState({ actualFilter: resetFilter });
		this.props.onchangeFilter(resetFilter);
		this.props.getDeedTypeCollection(resetFilter, 1, this.props.itemsPage);
	};
	private readonly filterDeedType = () => {
		let filter = { ...this.state.actualFilter };
		this.setState({ collapsed: true });
		this.props.onchangeFilter(filter);
		this.props.getDeedTypeCollection(filter, 1, this.props.itemsPage);
	};
}
