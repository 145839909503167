import * as React from 'react';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/index';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { ComboBox, IComboBoxOption } from 'office-ui-fabric-react/lib/ComboBox';
import { languages } from '../../../common/const/resources';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import { DocumentType, DocumentTypeCollection, Language, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { createEmptyDocumentTypeFilter, DocumentTypeFilter } from '../../../model/filters';

export interface IProps {
    getDocumentTypeCollection: (filter: DocumentTypeFilter, pageNumbers: number, itemsPage: number) => Promise<DocumentTypeCollection>;
    getDocumentTypePrimaries: () => DocumentType[];
    documentTypePrimaries: DocumentType[];
    onchangeFilter: any;
    documentTypeCollection: DocumentTypeCollection;
    filter: DocumentTypeFilter;
    collapsed: boolean;
    user: UserState;
    language: Language;
    itemsPage: number;
}

export interface IState {
    actualFilter: DocumentTypeFilter;
    collapsed: boolean;
    isBusinessDisabled: boolean;
}

export class DocumentTypeFilterComponent extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            collapsed: false,
            actualFilter: createEmptyDocumentTypeFilter(),
            isBusinessDisabled: false
        };
    }

    componentDidMount() {
        this.setState({ actualFilter: this.props.filter });
        this.props.getDocumentTypePrimaries();
    }

    public render() {
        let language = languages.languageES;
        if (this.props.language.name.includes(ActiveLanguage.English)) {
            language = languages.languageEN;
        }
        let documentTypePrimariesCombo: IComboBoxOption[] = [];
        
        this.props.documentTypePrimaries.forEach((item: DocumentType) => {
            documentTypePrimariesCombo.push({ key: item.id, text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish });
        });
        documentTypePrimariesCombo.sort((a, b) => a.text.localeCompare(b.text));
        documentTypePrimariesCombo.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
        documentTypePrimariesCombo.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });

        return (
            <>
                <div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
                    <div
                        className={
                            this.state.collapsed
                                ? 'ms-Grid-row filter_header-collapse'
                                : 'ms-Grid-row filter_header'
                        }
                    >
                        <div className='ms-Grid-col ms-xl6'>
                            {this.state.collapsed
                                ? language['generic']['list']['filter']['collapsedTitle']
                                : language['generic']['list']['filter']['btnTitle']}
                        </div>
                        <div className='ms-Grid-col ms-xl6'>
                            <CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
                        </div>
                    </div>
                    <div style={{ display: this.state.collapsed ? 'none' : '' }}>
                        <div className='ms-Grid-row flex-option'>
                            <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
                                <p> {language['documentType']['filter']['options']['descriptionSpanish']['title']} </p>
                                <TextField
                                    type='text'
                                    value={this.state.actualFilter.DescriptionSpanish}
                                    onChange={this.handleDescriptionSpanishChange}
                                    onBlur={this.handleDescriptionSpanishBlur}
                                />
                            </div>
                            <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
                                <p>{language['documentType']['filter']['options']['descriptionEnglish']['title']} </p>
                                <TextField
                                    type='text'
                                    value={this.state.actualFilter.DescriptionEnglish}
                                    onChange={this.handleDescriptionEnglishChange}
                                    onBlur={this.handleDescriptionEnglishBlur}
                                />
                            </div>
                            <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
                                <p>{language['documentType']['fields']['type']['title']} </p>
                                <ComboBox
                                    allowFreeform
                                    autoComplete='on'
                                    options={documentTypePrimariesCombo}
                                    placeholder={language['documentType']['filter']['options']['type']['placeholder']}
                                    onChange={this.handleTypeChange}
                                    selectedKey={this.state.actualFilter.FatherId}
                                    useComboBoxAsMenuWidth
                                />
                            </div>
                        </div>
                        <hr />
                        <div className='ms-Grid-row flex-option'>
                            <div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
                                <button type='button' className='filter_button-clean' onClick={this.cleanFilterDocumentType}>
                                    {language['generic']['list']['filter']['btnClean']}
                                </button>
                                <button type='button' className='filter_button-apply' onClick={this.filterDocumentType}>
                                    {language['generic']['list']['filter']['btnApply']}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: this.state.collapsed ? '' : 'none' }}>
                        <div className='filter_collapse'>
                            <p style={{ display: this.state.actualFilter.DescriptionSpanish !== '' ? '' : 'none' }}>
                                <span className='key'>{language['documentType']['filter']['options']['descriptionSpanish']['title']}</span>
                                <span className='value'>{this.state.actualFilter.DescriptionSpanish}</span>
                            </p>
                            <p style={{ display: this.state.actualFilter.DescriptionEnglish !== '' ? '' : 'none' }}>
                                <span className='key'>{language['documentType']['filter']['options']['descriptionEnglish']['title']}</span>
                                <span className='value'>{this.state.actualFilter.DescriptionEnglish}</span>
                            </p>
                            <p style={{ display: this.state.actualFilter.FatherId > 0 ? '' : 'none' }}>
                                <span className='key'>{language['documentType']['filter']['options']['type']['title']}</span>
                                <span className='value'>{this.state.actualFilter.FatherName}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private readonly handleFiltersCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    private readonly handleDescriptionSpanishChange = (value) => {
        let state = { ...this.state };
        state.actualFilter.DescriptionSpanish = value.target.value;
        this.setState(state);
    };
    private readonly handleDescriptionSpanishBlur = (value) => {
        let state = { ...this.state };
        state.actualFilter.DescriptionSpanish = value.target.value.toUpperCase();
        this.setState(state);
    };

    private readonly handleDescriptionEnglishChange = (value) => {
        let state = { ...this.state };
        state.actualFilter.DescriptionEnglish = value.target.value;
        this.setState(state);
    };
    private readonly handleDescriptionEnglishBlur = (value) => {
        let state = { ...this.state };
        state.actualFilter.DescriptionEnglish = value.target.value.toUpperCase();
        this.setState(state);
    };

    private readonly handleTypeChange = (event, option): void => {
        let state = { ...this.state };
        state.actualFilter.FatherId = option?.key;
        state.actualFilter.FatherName = option?.text;
        this.setState(state);
    };

    private readonly cleanFilterDocumentType = () => {
        let actualFilter = createEmptyDocumentTypeFilter()
        this.setState({ actualFilter });
        this.props.onchangeFilter(actualFilter);
        this.props.getDocumentTypeCollection(actualFilter, 1, this.props.itemsPage);

    };
    private readonly filterDocumentType = () => {
        let filter = { ...this.state.actualFilter };
        this.setState({ collapsed: true });
        this.props.onchangeFilter(filter);
        this.props.getDocumentTypeCollection(filter, 1, this.props.itemsPage);
    };

}