import { CompanyApi } from '../../../../api/companies/companiesApi';
import { actionTypes } from '../../../../common/const/actionTypes';
import { CompanySearch } from '../../../../model';

export const getCompaniesSearchAction = (filter: string) => (dispatch) => {
	CompanyApi.GetCompaniesSearchActionAsync(filter).then((content) => {
		dispatch(getCompaniesCompleted(content));
	});
};

const getCompaniesCompleted = (companySearch: CompanySearch[]) => ({
	type: actionTypes.GET_COMPANY_SEARCH,
	payload: companySearch,
});
