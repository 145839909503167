import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { languages } from '../../../common/const/resources';
import { onlyCompaniesModule } from '../../../common/const/rolesAssignments';
import { routeUrls } from '../../../common/const/routes';
import { NoResultsComponent } from '../../../common/noResults/noResults';
import { SpinnerContainer } from '../../../common/spinner';
import { UnauthorizedView } from '../../../common/unauthorizedView/unauthorizedView';
import { BaseName, BaseType, Language, User, UserState } from '../../../model/';
import { CompanyCollection } from '../../../model/Company';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { CompanyFilter } from '../../../model/filters/CompanyFilter';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';
import { CompaniesDataGrid } from './grid';
import { CompanySearchComponent } from './search';

export interface IProps {
	companies: CompanyCollection;
	companyNames: BaseName[];
	tradeNames: BaseType[];
	countryNames: BaseName[];
	user: User;
	language: Language;
	currentUser: UserState;
	filter: CompanyFilter;

	getCompanies: (filter: CompanyFilter, pageNumbers: number, itemsPage: number) => Promise<CompanyCollection>;
	getCompanyNames: () => Promise<BaseName[]>;
	getTradeNames: () => Promise<BaseType[]>;
	getCountryNames: () => Promise<BaseName[]>;
	setCompanyFilter: (filter: CompanyFilter) => CompanyFilter;
	exportToExcel: (filter: CompanyFilter) => Promise<any>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	isAdmin: boolean;
	collapsed: boolean;
	filter: CompanyFilter;
	orderBySociety: string;
	orderByTrade: string;
	orderByCif: string;
	orderByCountry: string;
}

export class CompaniesPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isAuthorized: false,
			isLoading: true,
			isAdmin: false,
			collapsed: false,
			orderBySociety: 'ms-Icon--ChevronUpSmall',
			orderByTrade: 'ms-Icon--ChevronUpSmall',
			orderByCif: 'ms-Icon--ChevronUpSmall',
			orderByCountry: 'ms-Icon--ChevronUpSmall',
			filter: this.props.filter,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyCompaniesModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		this.setState({ isAdmin: isAdmin });

		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}

		let filter = { ...this.state.filter };
		if (this.props.currentUser?.currentUser?.roles.length > 0) {
			this.props.getCompanies(filter, page, this.props.filter.ItemsPage);
		} else {
			this.props.getCompanies(this.props.filter, page, this.props.filter.ItemsPage);
		}

		this.props.getCompanyNames();
		this.props.getTradeNames();
		this.props.getCountryNames();
	}

	public componentDidUpdate(prevProps) {
		let currentUserOld = prevProps.currentUser;
		if (currentUserOld.currentUser === null) {
			let isAuth = utils.isInRoles(onlyCompaniesModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}
		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if(this.props.countries !== prevProps.countries){
			if (this.props.countries.length === 1) {
				let netherlands = this.props.countries.find(x => x.descriptionEnglish === "Netherlands")?.id;
				if(netherlands !== undefined){
					let filter = { ...this.state.filter };
					filter.CountryId = netherlands;
				}
			}
		}

		if (newPage !== oldPage) {
			this.props.getCompanies(this.props.filter, newPage, this.props.filter.ItemsPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['societies']['list']['title']}</h1>
						</div>
						<div className='ms-Grid'>
							<CompanySearchComponent
								filter={this.state.filter}
								companyNames={this.props.companyNames}								
								getTradeCompanies={this.props.getTradeCompanies}
								tradeCompanies={this.props.tradeCompanies}
								tradeNames={this.props.tradeNames}
								countryNames={this.props.countryNames}
								getCompanies={this.props.getCompanies}
								onchangeFilter={this.handleFilterChange}
								language={this.props.language}
								collapsed={this.state.collapsed}
								itemsPage={this.state.filter.ItemsPage}
								user={this.props.currentUser}
								page={page}
							/>
							{this.props.companies.totalNumberOfRows > 0 ? (
								<CompaniesDataGrid
									companies={this.props.companies}
									user={this.props.user}
									language={this.props.language}
									filter={this.state.filter}
									setCompanyFilter={this.props.setCompanyFilter}
									page={page}
									onchangeFilter={this.handleFilterChange}
									exportToExcel={this.props.exportToExcel}
									getCompanies={this.props.getCompanies}
								/>
							) : (
								<NoResultsComponent language={this.props.language} list={language['societies']['list']['title']} />
							)}
						</div>
					</>
				)}
			</>
		);
	}

	public handleFilterChange = (newfilter: CompanyFilter): void => {
		this.setState({ filter: newfilter });
		this.props.history.push(routeUrls.companiesPagination + 1);
	};
}
