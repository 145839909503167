import { actionTypes } from '../../../../common/const/actionTypes';
import { NotaryDataApi } from '../../../../api/notaryDocument';
import { UntranslatedBaseType } from '../../../../model/BaseType';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getNotaryDocumentsAction = () => (dispatch) => {
	dispatch(httpCallStartAction());

	NotaryDataApi.GetAsync().then((content) => {
		dispatch(getNotaryDocumentsCompleted(content));
	});
};

const getNotaryDocumentsCompleted = (notaryDocuments: UntranslatedBaseType[]) => ({
	type: actionTypes.GET_NOTARY_DOCUMENTS,
	payload: notaryDocuments,
	meta: {
		httpEnd: true,
	},
});
