import { GenericApi } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DeedType } from '../../../model';

export const getDeedTypeAction = (id: number) => (dispatch) => {
    dispatch(httpCallStartAction());
    GenericApi.GetByIdAsync<DeedType>(ApiUrls.DeedTypes, id).then((result) => {
        dispatch(getDeedTypeCompleted(result));
    });
};

const getDeedTypeCompleted = (result: DeedType) => ({
    type: actionTypes.GET_DEED_TYPE,
    payload: result,
    meta: {
        httpEnd: true,
    }
});