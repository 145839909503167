import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { BodyPosition, BaseType, Language, Person, TradeCompany, createEmptyBodyPosition } from '../../../../../model';
import { ActiveLanguage } from '../../../../../model/enums/activeLanguage';
import { getParsedDate, formatDate } from '../../../../../utils/extensions';
import { languages } from '../../../../../common/const/resources';
import { DialogComponent } from '../../../../../common/dialog/dialogComponent';

export interface IProps {
	bodyMembers: BodyPosition[];
	peopleNames: Person[];
	positionTypes: BaseType[];
	language: Language;
	isReadOnly: boolean;
	companies: TradeCompany[];
	index: number;
	editPosition: (position: BodyPosition, index: number, isEdit: boolean) => void;
	deleteBodyMember: (bodyMember: BodyPosition) => void;
	cancelEditPosition: (isEdit: boolean) => void;
	setFinishedEdit: (finishedEdit: boolean) => void;
}

interface IState {
	position: BodyPosition;
	showModal: boolean;
}

export class AdminBodyGrid extends React.Component<IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props) {
		super(props);
		this.state = {
			position: createEmptyBodyPosition(),
			showModal: false,
		};
	}
	public render() {
		let bodyMembers = this.props.bodyMembers ? this.props.bodyMembers : [];
		return (
			<>
				{bodyMembers.map((bodyMember, index) => {
					let company = this.props.companies.find((item) => item.id === bodyMember.companyId);
					let positionType = this.props.positionTypes.find((item) => item.id === bodyMember.positionTypeId);
					let positionValue = positionType ? positionType.descriptionSpanish : '';
					let language = languages.languageES;
					if (this.props.language.name.includes(ActiveLanguage.English)) {
						language = languages.languageEN;
					}
					if (this.props.language.name.includes(ActiveLanguage.English)) {
						positionValue = positionType ? positionType.descriptionEnglish : '';
					}

					return (
						<tr>
							<td>{bodyMember?.representative != null ? bodyMember.representative?.name : ''}</td>
							<td>{company ? company.name : '-'}</td>
							<td>{positionValue}</td>
							{bodyMember.appointmentDate !== null ? (
								<td>{formatDate(getParsedDate(bodyMember.appointmentDate), this.props.language)}</td>
							) : (
								<td></td>
							)}
							{bodyMember.dateAppointmentFinal !== null ? (
								<td>{formatDate(getParsedDate(bodyMember.dateAppointmentFinal), this.props.language)}</td>
							) : (
								<td></td>
							)}
							{bodyMember.terminationDate !== null ? (
								<td>{formatDate(getParsedDate(bodyMember.terminationDate), this.props.language)}</td>
							) : (
								<td></td>
							)}
							<td style={{ visibility: this.props.isReadOnly ? 'hidden' : 'visible' }}>
							<div style={{ display: this.props.index > -1 ? 'none' : '' }}>
									<IconButton iconProps={{ iconName: 'EditContact' }} onClick={() => this.Edit(bodyMember, index)} />
									<IconButton iconProps={{ iconName: 'UserRemove' }} onClick={() => this.ShowModal(bodyMember)} />
								</div>
								<div style={{ display: this.props.index !== index ? 'none' : '' }}>
									<IconButton
										iconProps={{ iconName: 'FollowUser' }}
										onClick={() => this.props.cancelEditPosition(false)}
									/>
								</div>
								<DialogComponent
									showModal={this.state.showModal}
									TitleDialog={language['generic']['dialog']['title']}
									QuestionDialog={language['generic']['dialog']['question']}
									onSuccess={() => {
										this.Delete();
									}}
									onFail={() => {
										this.Fail();
									}}
									CancelTitle={language['generic']['dialog']['cancel']}
									SuccessTitle={language['generic']['dialog']['confirm']}
								/>
							</td>
						</tr>
					);
				})}
			</>
		);
	}
	private readonly Edit = (position: BodyPosition, index: number) => {
		this.props.editPosition(position, index, true);
		this.props.setFinishedEdit(false);
	};

	private readonly Delete = () => {
		this.props.deleteBodyMember(this.state.position);
		this.Fail();
	};

	private Fail() {
		this.setState({ showModal: false, position: createEmptyBodyPosition() });
	}

	private ShowModal(position: BodyPosition) {
		this.setState({ showModal: true, position: position });
	}
}
