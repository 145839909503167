import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { languages } from '../../../common/const/resources';
import { routeUrls } from '../../../common/const/routes';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { Language, OrderBy, Person, PersonCollection, User } from '../../../model/';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { ActivePersonOrderFilter, PersonFilter } from '../../../model/filters';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
	peopleCollection: PersonCollection;
	user: User;
	language: Language;
	page: number;
	onchangeFilter: any;
	filter: PersonFilter;

	getPeopleCollection: (filter: PersonFilter, pageNumbers: number, itemsPage: number) => Promise<PersonCollection>;
	deletePerson: (filter: PersonFilter, language: Language, id: number) => Promise<boolean>;
	exportToExcel: (filter: PersonFilter) => Promise<any>;
}

export interface IState {
	showModal: boolean;
	id: number;
	collapsed: boolean;
	filter: PersonFilter;
	orderByName: string;
	orderByDni: string;
	orderByPassport: string;
	orderByNacionality: string;
	orderByEmail: string;
	orderByTrade: string;
}

class PeopleDataGridComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			id: 0,
			collapsed: false,
			filter: this.props.filter,
			orderByName: 'ms-Icon--ChevronUpSmall',
			orderByDni: 'ms-Icon--ChevronUpSmall',
			orderByPassport: 'ms-Icon--ChevronUpSmall',
			orderByNacionality: 'ms-Icon--ChevronUpSmall',
			orderByEmail: 'ms-Icon--ChevronUpSmall',
			orderByTrade: 'ms-Icon--ChevronUpSmall',
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			let filter: PersonFilter = {...this.props.filter}
			filter.Name = "";
			filter.PersonId = 0;
			this.setState({ filter: this.props.filter });
		}
	}

	public render() {
		const { filter } = this.state;
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let optionText: string = language['people']['list']['table']['options']['view'];
		if (isAdmin) {
			optionText = language['people']['list']['table']['options']['edit'];
		}

		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.peopleCollection.totalNumberOfRows > 0 ? (
								<span>
									{this.props.peopleCollection.totalNumberOfRows} {language['generic']['list']['table']['results']}
								</span>
							) : this.props.people.totalNumberOfRows === 1 ? (
								<span>
									{this.props.peopleCollection.totalNumberOfRows} {language['generic']['list']['table']['result']}
								</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={this.props.peopleCollection.totalNumberOfRows > 0 ? 'button-download' : 'button-download isDisabled'}
							onClick={this.handleExportClick}
						>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th>
									<div className='table-header'>
										<span>{language['people']['list']['table']['columns']['name']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActivePersonOrderFilter.Name === filter.CurrentFilter &&
													filter.NameOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByName}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActivePersonOrderFilter.Name === filter.CurrentFilter &&
													filter.NameOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByName}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['people']['list']['table']['columns']['dni']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActivePersonOrderFilter.Dni === filter.CurrentFilter && filter.DniOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDni}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActivePersonOrderFilter.Dni === filter.CurrentFilter && filter.DniOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDni}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['people']['list']['table']['columns']['passport']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActivePersonOrderFilter.Passport === filter.CurrentFilter &&
													filter.PassportOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByPassport}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActivePersonOrderFilter.Passport === filter.CurrentFilter &&
													filter.PassportOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByPassport}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['people']['list']['table']['columns']['email']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActivePersonOrderFilter.Email === filter.CurrentFilter &&
													filter.EmailOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByEmail}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActivePersonOrderFilter.Email === filter.CurrentFilter &&
													filter.EmailOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByEmail}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['people']['list']['table']['columns']['nacionality']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActivePersonOrderFilter.Nacionality === filter.CurrentFilter &&
													filter.NacionalityOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByNacionality}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActivePersonOrderFilter.Nacionality === filter.CurrentFilter &&
													filter.NacionalityOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByNacionality}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['people']['list']['table']['columns']['trade']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${(ActivePersonOrderFilter.TradeSpanish === filter.CurrentFilter ||
													ActivePersonOrderFilter.TradeEnglish === filter.CurrentFilter) &&
													filter.TradeOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByTrade}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${(ActivePersonOrderFilter.TradeSpanish === filter.CurrentFilter ||
													ActivePersonOrderFilter.TradeEnglish === filter.CurrentFilter) &&
													filter.TradeOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByTrade}
											></i>
										</div>
									</div>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								this.props.peopleCollection?.list.map((item: Person) => {
									let trade = item.tradeSpanish;

									if (this.props.language.name.includes(ActiveLanguage.English)) {
										trade = item.tradeEnglish;
									}

									let personName = item.name?.length > 35 ? item.name.substr(0, 32) + "..." : item.name;
									let personEmail = item.email?.length > 25 ? item.email.substr(0, 22) + "..." : item.email;

									return (
										<tr>
											<td title={item.name?.length > 35 ? item.name : ''} className='mainColumn'>{personName}</td>
											<td>{item.dni}</td>
											<td>{item.passport}</td>
											<td title={item.email?.length > 25 ? item.email : ''}>{personEmail}</td>
											<td>{item.nationality}</td>
											<td>{trade}</td>
											<td className='deleteItem'>
												<div>
													<DefaultButton
														text='. . .'
														disabled={!isAdmin}
														menuProps={{
															shouldFocusOnMount: true,
															items: [
																{
																	key: 'Edit',
																	onClick: () => {
																		this.props.history.push('/people/edit/' + item.id);
																	},
																	iconProps: {
																		iconName: 'Edit',
																		className: 'context-menu__icon',
																	},
																	name: optionText,
																},
																{
																	key: 'delete',
																	iconProps: {
																		iconName: 'Delete',
																		className: 'context-menu__icon',
																	},
																	onClick: () => {
																		this.ShowModal(item.id);
																	},
																	name: language['people']['list']['table']['options']['delete'],
																},
															],
														}}
														className='tdNoBorder'
													/>
													<DialogComponent
														showModal={this.state.showModal}
														TitleDialog={language['generic']['dialog']['title']}
														QuestionDialog={language['generic']['dialog']['question']}
														onSuccess={() => {
															this.Delete();
														}}
														onFail={() => {
															this.Fail();
														}}
														CancelTitle={language['generic']['dialog']['cancel']}
														SuccessTitle={language['generic']['dialog']['confirm']}
													/>
												</div>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
				{
					<AdvancedPagination
						currentPage={this.props.page}
						items={this.props.peopleCollection.totalNumberOfRows}
						link={routeUrls.peoplePagination}
						updateMaxPageItems={this.handleItemsPerPageChange}
						actualPageItems={this.state.filter.ItemsPage}
						language={this.props.language}
					/>
				}
			</>
		);
	}
	private Fail() {
		this.setState({ showModal: false, id: 0 });
	}
	private ShowModal(id: number) {
		this.setState({ showModal: true, id: id });
	}
	public readonly Delete = (): Promise<Boolean> => {
		let result = this.props.deletePerson(this.state.filter, this.props.language, this.state.id);
		return result;
	};

	public readonly handleOrderByName = (event) => {
		let filter: PersonFilter = this.props.filter;
		filter.CurrentFilter = ActivePersonOrderFilter.Name;
		let orderBy: string = '';

		if (filter.NameOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.NameOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.NameOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByName: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPeople(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDni = () => {
		let filter: PersonFilter = this.props.filter;
		filter.CurrentFilter = ActivePersonOrderFilter.Dni;
		let orderBy: string = '';

		if (filter.DniOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DniOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.DniOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByDni: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPeople(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByPassport = () => {
		let filter: PersonFilter = this.props.filter;
		filter.CurrentFilter = ActivePersonOrderFilter.Passport;
		let orderBy: string = '';

		if (filter.PassportOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.PassportOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.PassportOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByPassport: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPeople(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByNacionality = () => {
		let filter: PersonFilter = this.props.filter;
		filter.CurrentFilter = ActivePersonOrderFilter.Nacionality;
		let orderBy: string = '';

		if (filter.NacionalityOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.NacionalityOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.NacionalityOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByNacionality: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPeople(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByEmail = () => {
		let filter: PersonFilter = this.props.filter;
		filter.CurrentFilter = ActivePersonOrderFilter.Email;
		let orderBy: string = '';

		if (filter.EmailOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.EmailOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.EmailOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByEmail: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPeople(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByTrade = () => {
		let filter: PersonFilter = this.props.filter;
		filter.CurrentFilter = ActivePersonOrderFilter.TradeSpanish;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActivePersonOrderFilter.TradeEnglish;
		}
		let orderBy: string = '';

		if (filter.TradeOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.TradeOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.TradeOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByTrade: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPeople(filter, 1, filter.ItemsPage);
	};

	public handleExportClick = () => {
		let filter: PersonFilter = { ...this.state.filter };
		this.props.exportToExcel(filter);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: PersonFilter = { ...this.state.filter };
		filter.ItemsPage = itemsPage;
		this.setState({ filter });
		this.props.getPeople(filter, 1, itemsPage);
	};
}
export const PeopleDataGrid = withRouter(PeopleDataGridComponent);
