import { showSuccess, showError } from '../../../utils/extensions';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { GenericApi } from '../../../api/generic';
import { getResourcesFile } from '../../../utils/extensions';
import { PositionTypeFilter } from '../../../model/filters';
import { Language } from '../../../model';
import {getPositionTypesCollectionAction} from './getPositionTypeCollection';
import { Constants } from '../../../common/const/constants';

export const deletePositionTypeAction = (filter: PositionTypeFilter, currentLanguage: Language, id: number) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	GenericApi.DeleteAsync(ApiUrls.PositionTypes, id).then((result: any) => {
		if (!result.error) {
			showSuccess(language['position-types']['messages']['success-delete']);
		} else {
			showError(language['position-types']['messages']['error-delete'] + result.error);
		}

		dispatch(getPositionTypesCollectionAction(filter, 1, Constants.ItemsPerPage));
		dispatch(deletePositionTypeCompleted(result));
	});
};
const deletePositionTypeCompleted = (result: boolean) => ({
	type: actionTypes.DELETE_POSITION_TYPE,
	payload: result,
});
