import * as fetch from 'isomorphic-fetch';
import { SiteProps } from '../../model/Config';
import { ApiUrls } from '../../common/const/api';
import { OrderBy } from '../../model/filters/OrderBy';
import * as utils from '../../utils/extensions';
import * as msal from '../../common/security/msal';
import { ActivePositionTypeOrderFilter, PositionTypeFilter } from '../../model/filters';
import { BaseType, PositionTypeCollection } from '../../model';

// let pageRender: number = 0;		Clean Warning Code 

const FilterAsync = async (filter: PositionTypeFilter, pageNumber: number, itemsPage: number): Promise<PositionTypeCollection> => {
	let baseUrl = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.PositionTypes}/GetByFilter`;

	const token = await msal.authProvider.getIdToken();

	// pageRender = pageNumber;		Clean Warning Code 

	let obj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActivePositionTypeOrderFilter.PositionTypeNameSpanish:
			queryString += '&PositionTypeNameSpanishOrder=';
			if (filter.PositionTypeNameSpanishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActivePositionTypeOrderFilter.PositionTypeNameEnglish:
			queryString += '&PositionTypeNameEnglishOrder=';
			if (filter.PositionTypeNameEnglishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		default:
			queryString += '&DepartmentOrder=';
			if (filter.PositionTypeNameSpanishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
	}

	if (!utils.isNullOrEmpty(filter.PositionTypeNameSpanish)) {
		queryString += '&PositionTypeNameSpanish=' + encodeURIComponent(filter.PositionTypeNameSpanish) + '';
	}

	if (!utils.isNullOrEmpty(filter.PositionTypeNameEnglish)) {
		queryString += '&PositionTypeNameEnglish=' + encodeURIComponent(filter.PositionTypeNameEnglish) + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response: any) => response.json());
};

async function GetListByTradeAsync(tradeId: number): Promise<BaseType[]> {
	let baseURL = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.PositionTypes}/SearchByTrade`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	let randomGuid: string = utils.generateRandomGuid(true);
	let url = `${baseURL}/${tradeId}`;
	return fetch(url + randomGuid, obj).then((response) => response.json());
}

async function ExportToExcel(filter: PositionTypeFilter): Promise<any> {
	let baseURL = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.PositionTypes}/ExportToExcel`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = ExportToExcelQuery(filter);

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('position-types')),
	);
}

function ExportToExcelQuery(filter: PositionTypeFilter): string {
	let queryString: string = '';

	if (filter.PositionTypeNameSpanish != null) {
		queryString += '&PositionTypeNameSpanish=' + filter.PositionTypeNameSpanish;
	}

	if (filter.PositionTypeNameEnglish != null) {
		queryString += '&PositionTypeNameEnglish=' + filter.PositionTypeNameEnglish;
	}

	return queryString;
}

export const PositionTypesApi = {
	FilterAsync,
	ExportToExcel,
	GetListByTradeAsync,
};
