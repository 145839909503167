import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MasterManagementComponent } from './list/page';
import { User, Language } from '../../model';

export interface IProps {
	user: User;
	language: Language;
}

export class MasterManagementPage extends React.Component<RouteComponentProps<{}> & IProps, {}> {
	/* constructor(props) {
		super(props);
	}		Clean Warning Code */

	public render() {
		return <MasterManagementComponent language={this.props.language} />;
	}
}
