import { actionTypes } from '../common/const/actionTypes';
import { Language } from '../model/';

const createEmptyLanguage = (): Language => ({
	name: '',
});

export const languageReducer = (state = createEmptyLanguage(), action) => {
	switch (action.type) {
		case actionTypes.GET_LANGUAGE:
			return handleLanguageCompleted(state, action.payload);
	}
	return state;
};

const handleLanguageCompleted = (state: Language, payload: Language) => {
	return payload;
};
