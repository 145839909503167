import { SiteProps } from '../../model/Config';
import * as fetch from 'isomorphic-fetch';
import { BodyTypesFilter } from '../../model/filters/BodyTypesFilter';
import { ActiveBodyTypesFilter } from '../../model/filters/ActiveBodyTypesFilter';
import { BodyTypesCollection } from '../../model';
import { ApiUrls } from '../../common/const/api';
import * as utils from '../../utils/extensions';
import { DecideSortingOrder } from '../../utils/extensions';
import * as msal from '../../common/security/msal';

async function FilterAsync(filter: BodyTypesFilter, pageNumber: number, itemsPage: number): Promise<BodyTypesCollection> {
	let baseUrl = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.BodyTypes}/GetByFilter`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = GetQueryString(filter);
	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) => response.json());
}

async function ExportToExcel(filter: BodyTypesFilter): Promise<any> {
	let baseURL = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.BodyTypes}/ExportToExcel`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = ExportToExcelQuery(filter);

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('body-types')),
	);
}

function GetQueryString(filter: BodyTypesFilter): string {
	return GetOrderByQueryString(filter) + GetFilterQueryString(filter);
}

function GetOrderByQueryString(filter: BodyTypesFilter): string {
	let queryString: string = '';

	switch (filter.CurrentFilter) {
		case ActiveBodyTypesFilter.DescriptionSpanish:
			queryString += '&DescriptionSpanishOrder=' + DecideSortingOrder(filter.DescriptionSpanishOrder);
			break;
		case ActiveBodyTypesFilter.DescriptionEnglish:
			queryString += '&DescriptionEnglishOrder=' + DecideSortingOrder(filter.DescriptionEnglishOrder);
			break;
		default:
			queryString += '&DescriptionSpanishOrder=' + DecideSortingOrder(filter.DescriptionSpanishOrder);
			break;
	}

	return queryString;
}

function GetFilterQueryString(filter: BodyTypesFilter): string {
	let queryString: string = '';

	if (filter.DescriptionSpanish != null) {
		queryString += '&DescriptionSpanish=' + filter.DescriptionSpanish;
	}

	if (filter.DescriptionEnglish != null) {
		queryString += '&DescriptionEnglish=' + filter.DescriptionEnglish;
	}

	return queryString;
}

function ExportToExcelQuery(filter: BodyTypesFilter): string {
	let queryString: string = '';

	if (filter.DescriptionSpanish != null) {
		queryString += '&DescriptionSpanish=' + filter.DescriptionSpanish;
	}

	if (filter.DescriptionEnglish != null) {
		queryString += '&DescriptionEnglish=' + filter.DescriptionEnglish;
	}

	return queryString;
}

export const BodyApi = {
	FilterAsync,
	ExportToExcel,
};
