export const actionTypes = {
	GET_PEOPLE: 'GET_PEOPLE',
	GET_NOTARYPEOPLE: 'GET_NOTARYPEOPLE',
	GET_PERSON: 'GET_PERSON',
	GET_NOTARYPERSON: 'GET_NOTARYPERSON',
	DELETE_PERSON: 'DELETE_PERSON',
	DELETE_NOTARYPERSON: 'DELETE_NOTARYPERSON',
	SAVE_PERSON_COMPLETED: 'SAVE_PERSON_COMPLETED',
	SAVE_NOTARYPERSON_COMPLETED: 'SAVE_NOTARYPERSON_COMPLETED',
	GET_POSITION_TYPES: 'GET_POSITION_TYPES',
	GET_POSITION_TYPES_COLLECTION: 'GET_POSITION_TYPES_COLLECTION',
	GET_POSITION_TYPE: 'GET_POSITION_TYPE',
	DELETE_POSITION_TYPE: 'DELETE_POSITION_TYPE',
	SAVE_POSITION_TYPE_COMPLETED: 'SAVE_POSITION_TYPE_COMPLETED',
	SET_POSITION_TYPES_FILTER: 'SET_POSITION_TYPES_FILTER',
	GET_BODY_TYPES: 'GET_BODY_TYPES',
	GET_BODY_TYPE: 'GET_BODY_TYPE',
	DELETE_BODY_TYPE: 'DELETE_BODY_TYPE',
	SAVE_BODY_TYPE_COMPLETED: 'SAVE_BODY_TYPE_COMPLETED',
	SET_BODY_TYPE_FILTER: 'SET_BODY_TYPE_FILTER',
	GET_POWER_TYPES: 'GET_POWER_TYPES',
	GET_POWER_NAMES: 'GET_POWER_NAMES',
	GET_POWER_TYPE: 'GET_POWER_TYPE',
	DELETE_POWER_TYPE: 'DELETE_POWER_TYPE',
	SAVE_POWER_TYPE_COMPLETED: 'SAVE_POWER_TYPE_COMPLETED',
	GET_POWERS: 'GET_POWERS',
	GET_POWER: 'GET_POWER',
	DELETE_POWER: 'DELETE_POWER',
	SAVE_POWERTYPE_COMPLETED: 'SAVE_POWERTYPE_COMPLETED',
	GET_POWER_SUBJECTS: 'GET_POWERS_FOR_REVOKE',
	GET_COMPANIES: 'GET_COMPANIES',
	GET_COMPANY: 'GET_COMPANY',
	GET_COMPANIES_WITH_TRADE: 'GET_COMPANIES_WITH_TRADE',
	SET_COMPANIES_FILTER: 'SET_COMPANIES_FILTER',
	GET_COMPANIES_BY_PERSON: 'GET_COMPANIES_BY_PERSON',
	GET_LEGAL_FORMS: 'GET_LEGAL_FORMS',
	GET_CURRENCIES: 'GET_CURRENCIES',
	GET_COUNTRIES: 'GET_COUNTRIES',
	GET_COMPANY_NAMES: 'GET_COMPANY_NAMES',
	SET_REPRESENTATIVE_DETAIL_FILTER: 'SET_REPRESENTATIVE_DETAIL_FILTER',
	SET_REPRESENTATIVE_FILTER: 'SET_REPRESENTATIVE_FILTER',
	DELETE_COMPANY: 'DELETE_COMPANY',
	SAVE_COMPANY_COMPLETED: 'SAVE_COMPANY_COMPLETED',
	GET_DEED_TYPES: 'GET_DEED_TYPES',
	GET_RECORD_TYPES: 'GET_RECORD_TYPES',
	GET_RECORD_TYPE: 'GET_RECORD_TYPE',
	GET_REPRESENTATIVES: 'GET_REPRESENTATIVES',
	GET_REPRESENTATIVE: 'GET_REPRESENTATIVE',
	GET_POWER_DETAIL: 'GET_POWER_DETAIL',
	SAVE_RECORD_TYPE_COMPLETED: 'SAVE_RECORD_TYPE_COMPLETED',
	DELETE_RECORD_TYPE: 'DELETE_RECORD_TYPE',
	SET_CSV_DOWNLOAD_LINK: 'SET_CSV_DOWNLOAD_LINK',
	HTTP_CALL_START: 'HTTP_CALL_START',
	HTTP_CALL_END: 'HTTP_CALL_END',
	SAVE_RECORD_COMPLETED: 'SAVE_RECORD_COMPLETED',
	GET_DEEDS: 'GET_DEEDS',
	GET_RECORD: 'GET_RECORD',
	GET_RECORDS: 'GET_RECORDS',
	SET_RECORD_FILTER: 'SET_RECORD_FILTER',
	DELETE_RECORD: 'DELETE_RECORD',
	GET_NOTARY_DOCUMENTS: 'GET_NOTARY_DOCUMENTS',
	GET_PEOPLE_NAMES: 'GET_PEOPLE_NAMES',
	GET_NOTARYPEOPLE_NAMES: 'GET_NOTARYPEOPLE_NAMES',
	GET_COMPANY_SEARCH: 'GET_COMPANY_SEARCH',
	GET_BODY_TYPE_NAMES: 'GET_BODY_TYPE_NAMES',
	GET_POSITION_TYPE_NAMES: 'GET_POSITION_TYPE_NAMES',
	GET_POWER_TYPE_NAMES: 'GET_POWER_TYPE_NAMES',
	GET_POWER_EXERCISE_NAMES: 'GET_POWER_EXERCISE_NAMES',
	SAVE_DEED_COMPLETED: 'SAVE_DEED_COMPLETED',
	SET_DEED_FILTER: 'SET_DEED_FILTER',
	DELETE_DEED: 'DELETE_DEED',
	GET_DEED: 'GET_DEED',
	GET_USER: 'GET_USER',
	GET_LANGUAGE: 'GET_LANGUAGE',
	GET_POSITIONS: 'GET_POSITIONS',
	GET_POSITION: 'GET_POSITION',
	SET_POSITION_FILTER: 'SET_POSITION_FILTER',
	SET_POSITION_DETAIL_FILTER: 'SET_POSITION_DETAIL_FILTER',
	GET_RECORD_COMPANIES: 'GET_RECORD_COMPANIES',
	SET_RECORD_COMPANY_DETAIL_FILTER: 'SET_RECORD_COMPANY_DETAIL_FILTER',
	GET_RECORD_COMPANY: 'GET_RECORD_COMPANY',
	GET_RECORD_COMPANY_POWERS_REVOKE: 'GET_RECORD_COMPANY_POWERS_REVOKE',
	DELETE_SP_FILE: 'DELETE_SP_FILE',
	FETCH_CURRENT_USER_COMPLETED: 'FETCH_CURRENT_USER_COMPLETED',
	REVOKE_AUTHORIZATION: 'REVOKE_AUTHORIZATION',
	GET_AUTHORIZATIONS: 'GET_AUTHORIZATIONS',
	SET_AUTHORIZATION_FILTER: 'SET_AUTHORIZATION_FILTER',
	SAVE_AUTHORIZATION_COMPLETED: 'SAVE_AUTHORIZATION_COMPLETED',
	DELETE_AUTHORIZATION: 'DELETE_AUTHORIZATION',
	GET_BUSINESS: 'GET_BUSINESS',
	GET_AUTHORIZATION_STATUS: 'GET_AUTHORIZATION_STATUS',
	GET_AUTHORIZATION_STATUS_NAMES: 'GET_AUTHORIZATION_STATUS_NAMES',
	GET_BUSINESS_NAMES: 'GET_BUSINESS_NAMES',
	GET_PERSONS_AUTHORIZED: 'GET_PERSONS_AUTHORIZED',
	GET_ORIGINALS: 'GET_ORIGINALS',

	GET_DEPARTMENTS: 'GET_DEPARTMENTS',
	GET_DEPARTMENT_COLLECTION: 'GET_DEPARTMENT_COLLECTION',
	GET_DEPARTMENT: 'GET_DEPARTMENT',
	SET_DEPARTMENT_FILTER: 'SET_DEPARTMENT_FILTER',
	GET_DEPARTMENTS_TRADE: 'GET_DEPARTMENTS_TRADE',
	GET_DEPARTMENTS_BY_TRADE: 'GET_DEPARTMENTS_BY_TRADE',
	DELETE_DEPARTMENT: 'DELETE_DEPARTMENT',
	SAVE_DEPARTMENT_COMPLETED: 'SAVE_DEPARTMENT_COMPLETED',
	UPDATE_DEPARTMENT_COMPLETED: 'UPDATE_DEPARTMENT_COMPLETED',
	SET_DEPARTMENTS_FILTER: 'SET_DEPARTMENTS_FILTER',

	SAVE_REPRESENTATIVE_COMPLETED: 'SAVE_REPRESENTATIVE_COMPLETED',
	DELETE_REPRESENTATIVE: 'DELETE_REPRESENTATIVE',
	GET_STATE_REPRESENTATIVES: 'GET_STATE_REPRESENTATIVES',
	SAVE_POWER_COMPLETED: 'SAVE_POWER_COMPLETED',

	GET_DEED_TYPE: 'GET_DEED_TYPE',
	DELETE_DEED_TYPE: 'DELETE_DEED_TYPE',
	SET_DEED_TYPE_FILTER: 'SET_DEED_TYPE_FILTER',
	GET_DEED_TYPE_COLLECTION: 'GET_DEED_TYPE_COLLECTION',
	SAVE_DEED_TYPE_COMPLETED: 'SAVE_DEED_TYPE_COMPLETED',
	GET_DEED_TYPE_PRIMARIES: 'GET_DEED_TYPE_PRIMARIES',
	GET_DEED_TYPE_SUBTYPES: 'GET_DEED_TYPE_SUBTYPES',

	SET_DOCUMENT_TYPE_FILTER: 'SET_DOCUMENT_TYPE_FILTER',
	SAVE_DOCUMENT_TYPE_COMPLETED: 'SAVE_DOCUMENT_TYPE_COMPLETED',
	GET_DOCUMENT_TYPE_SUBTYPES: 'GET_DOCUMENT_TYPE_SUBTYPES',
	GET_DOCUMENT_TYPE_PRIMARIES: 'GET_DOCUMENT_TYPE_PRIMARIES',
	GET_DOCUMENT_TYPE_COLLECTION: 'GET_DOCUMENT_TYPE_COLLECTION',
	GET_DOCUMENT_TYPE: 'GET_DOCUMENT_TYPE',
	DELETE_DOCUMENT_TYPE: 'DELETE_DOCUMENT_TYPE',
	GET_DOCUMENTATION: 'GET_DOCUMENTATION',
	DELETE_DOCUMENTATION: 'DELETE_DOCUMENTATION',
	SAVE_DOCUMENTATION_COMPLETED: 'SAVE_DOCUMENTATION_COMPLETED',
	GET_DOCUMENT: 'GET_DOCUMENT',
	
	GET_NAME_AD: 'GET_NAME_AD',
	SET_PERSON_FILTER: 'SET_PERSON_FILTER',
	SET_NOTARYPERSON_FILTER: 'SET_NOTARYPERSON_FILTER',
	SET_DOCUMENTATION_FILTER: 'SET_DOCUMENTATION_FILTER',

	GET_LAST_BOX: 'GET_LAST_BOX'
};
