import { PowerRepresentativeDetail, SiteProps } from "../../model";
import * as msal from '../../common/security/msal';
import moment from 'moment';
import { ApiUrls } from '../../common/const/api';

async function AddAsync<T>(baseUrl: string, item: T): Promise<number> {
    let baseURL = `${SiteProps.SiteURL}/api/${baseUrl}/Add`;

    const token = await msal.authProvider.getIdToken();

    Date.prototype.toJSON = function () {
        return moment(this).format();
    };
    let obj = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.idToken.rawIdToken}`,
        },
        body: JSON.stringify(item),
    };
    return fetch(baseURL, obj).then((response: any) => RespuestaAdd(response));
}

function RespuestaAdd(response: any) {
    if (response.status === 404) {
        return -404;
    } else {
        return response.json();
    }
}

async function GetByIdAsync(id: number): Promise<PowerRepresentativeDetail> {
	let baseURL = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.Powers}/GetById`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}/${id}`;
	return fetch(url, obj).then((response: any) => response.json());
}

async function GetLastBox(): Promise<string> {
	let baseURL = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.Powers}/GetLastBox`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}`;
	return fetch(url, obj).then((response: any) => response.json());
}

export const PowerApi = {
    AddAsync,
    GetByIdAsync,
    GetLastBox,
};
