import { GenericApi } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import useHistory from '../../../../history';
import { getResourcesFile, showError, showSuccess } from '../../../../utils/extensions';
import { Language } from './../../../../model/Language';
import { Power } from './../../../../model/Powers/Power';
const browserHistory = useHistory;

export const savePowerAction = (power: Power, currentLanguage: Language) => (dispatch) => {
	dispatch(httpCallStartAction());
	let language = getResourcesFile(currentLanguage);
	handleCreatePower(power, language, dispatch);
};

const handleCreatePower = (power: Power, language: any, dispatch) => {

	if (power.id != null && power.id > 0) {
		GenericApi.UpdateAsync(ApiUrls.Powers, power).then((result) => {
			if (result > 0) {
				showSuccess(language['representatives']['form']['messages']['success-update']);
				dispatch(savePowerCompleted(result));
				browserHistory.goBack();
			} else {
				showError(language['representatives']['form']['messages']['error-update']);
			}
		});
	} else {
		GenericApi.AddAsync(ApiUrls.Powers, power).then((result) => {
			if (result > 0) {
				showSuccess(language['representatives']['form']['messages']['success']);
				dispatch(savePowerCompleted(result));
				browserHistory.goBack();
			} else {
				showError(language['representatives']['form']['messages']['error']);
			}
		});
	}
};

const savePowerCompleted = (result: number) => ({
	type: actionTypes.SAVE_POWER_COMPLETED,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
