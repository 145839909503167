import { connect } from 'react-redux';
import { Modules } from '../../../model/enums';
import { CompanyFilter } from '../../../model/filters/';
import { StateReducer } from '../../../reducers';
import { exportToExcelAction } from './actions/exportToExcel';
import { getCompaniesAction, getTradeCompaniesAction } from './actions/getCompanies';
import { getCompaniesNamesAction } from './actions/getCompaniesNameAction';
import { getCountryNamesAction } from './actions/getCountryNames';
import { getTradeNamesAction } from './actions/getTradeNames';
import { setCompanyFilterAction } from './actions/setCompaniesFilter';
import { RecordCompaniesListPage } from './page';

const moduleId = Modules.RecordCompanies;

const mapStateToProps = (state: StateReducer) => ({
	companies: state.companies,
	companyNames: state.companyNames,
	tradeCompanies: state.tradeCompanies,
	tradeNames: state.businessNames,
	countryNames: state.countries,
	csvDownloadLink: state.csvDownloadLink,
	user: state.user,
	currentUser: state.users,
	language: state.language,
	filter: state.companyFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCompanies: (filter: CompanyFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getCompaniesAction(filter, pageNumber, itemsPage, moduleId)),
	getCompanyNames: () => dispatch(getCompaniesNamesAction(moduleId)),
	getTradeCompanies: (tradeId?: number) => dispatch(getTradeCompaniesAction(moduleId, tradeId)),
	getTradeNames: () => dispatch(getTradeNamesAction()),
	getCountryNames: () => dispatch(getCountryNamesAction()),
	setCompanyFilter: (filter: CompanyFilter) => dispatch(setCompanyFilterAction(filter)),
	exportToExcel: (filter: CompanyFilter) => dispatch(exportToExcelAction(filter)),
});

export const RecordCompaniesPageContainer = connect(mapStateToProps, mapDispatchToProps)(RecordCompaniesListPage);
