import * as fetch from 'isomorphic-fetch';
import { SiteProps } from '../../model/Config';
import { ApiUrls } from '../../common/const/api';
import { OrderBy } from '../../model/filters/OrderBy';
import * as utils from '../../utils/extensions';
import * as msal from '../../common/security/msal';
import { ActiveDepartmentOrderFilter, DepartmentFilter } from '../../model/filters';
import { BaseType, DepartmentCollection } from '../../model';

// let pageRender: number = 0;		Clean Warning Code 

const FilterAsync = async (filter: DepartmentFilter, pageNumber: number, itemsPage: number): Promise<DepartmentCollection> => {
	let baseUrl = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.Department}/GetByFilter`;

	const token = await msal.authProvider.getIdToken();

	// pageRender = pageNumber;		Clean Warning Code 

	let obj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActiveDepartmentOrderFilter.DepartmentNameSpanish:
			queryString += '&DepartmentNameSpanishOrder=';
			if (filter.DepartmentNameSpanishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActiveDepartmentOrderFilter.DepartmentNameEnglish:
			queryString += '&DepartmentNameEnglishOrder=';
			if (filter.DepartmentNameEnglishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		default:
			queryString += '&DepartmentNameSpanishOrder=';
			if (filter.DepartmentNameSpanishOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
	}

	if (!utils.isNullOrEmpty(filter.DepartmentNameSpanish)) {
		queryString += '&DepartmentNameSpanish=' + encodeURIComponent(filter.DepartmentNameSpanish) + '';
	}

	if (!utils.isNullOrEmpty(filter.DepartmentNameEnglish)) {
		queryString += '&DepartmentNameEnglish=' + encodeURIComponent(filter.DepartmentNameEnglish) + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response: any) => response.json());
};

async function GetListByTradeAsync(tradeId: number): Promise<BaseType[]> {
	let baseURL = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.Department}/SearchByTrade`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	let randomGuid: string = utils.generateRandomGuid(true);
	let url = `${baseURL}/${tradeId}`;
	return fetch(url + randomGuid, obj).then((response) => response.json());
}

async function ExportToExcel(filter: DepartmentFilter): Promise<any> {
	let baseURL = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.Department}/ExportToExcel`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = ExportToExcelQuery(filter);

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('departments')),
	);
}

function ExportToExcelQuery(filter: DepartmentFilter): string {
	let queryString: string = '';

	if (filter.DepartmentNameSpanish != null) {
		queryString += '&DepartmentNameSpanish=' + filter.DepartmentNameSpanish;
	}

	if (filter.DepartmentNameEnglish != null) {
		queryString += '&DepartmentNameEnglish=' + filter.DepartmentNameEnglish;
	}

	return queryString;
}

export const DepartmentsApi = {
	FilterAsync,
	ExportToExcel,
	GetListByTradeAsync,
};
