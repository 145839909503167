import { DocumentFile, Stockholder } from '../model';
import { AdminBody, BodyPosition } from './AdminBody';

export interface DeedGrid {
	id: number;
	companyName: string;
	deedTypeNameSpanish: string;
	deedTypeNameEnglish: string;
	subDeedTypeNameSpanish: string;
	subDeedTypeNameEnglish: string;
	documentDate: Date;
	notaryName: string;
	recordDataInscription: string;
	recordDataInscriptionDate: Date;
	tradeId: number;
	notaryProtocolNumber: string;
}

export interface DeedCollection {
	list: DeedGrid[];
	totalNumberOfRows: number;
}

export interface Deed {
	id: number;
	tradeId: number;
	companyId: number;
	deedTypeId: number;
	subDeedTypeId?: number;
	documentDate: Date;
	subject: string;
	documentsSp: DocumentFile[];
	deleteSPFiles: string[];
	socialAddress: string;
	notaryData?: NotaryData;
	recordData?: RecordData;
	socialCapital?: SocialCapital;
	audityId?: number;
	stockholders?: Stockholder[];
	body?: AdminBody,
	members?: BodyPosition[];
	bodyId?: number;
	documentSetLink:string;
	disbursementType:string;
	companyName:string;
	deedTypeName:string;
	subDeedTypeName:string;
	boxId?:number;
	createdOn?:Date;
	createdBy:string; 
	modifiedOn:Date; 
	modifiedBy:string;
	originalTypeId?: number;
	originalTypeName:string,
	copy: string;
	simple: string;	
	locationDeed: string;
}

export interface NotaryData {
	id: number;
	notary: string;
	notaryDate?: Date;
	protocolNumber: string;
	city: string;
	minutes?: string;
	notaryPeopleId?: number;
	notaryPeopleName: string;
}

export interface RecordData {
	id: number;
	merchandRecord: string;
	volume: string;
	section: string;
	folio: string;
	sheet: string;
	inscription: string;
	inscriptionDate?: Date;
}

export interface SocialCapital {
	id: number;
	nominal: string;
	disbursement: string;
	initialSocialCapital: string;
	securities: string;
	totalNumberStock: number;
	lastNumbering: string;
	amortizedStock: string;
	issuedStock: string;
	extendedAmountType: string;
}

// export enum ExtendedAmountType {
// 	Ampliado = 1,
// 	Reducido = 2,
// }

// export interface Audity {
// 	id: number;
// 	namedExercises: string;
// 	companyId?: number;
// }

export const createEmptyDeedList = (): DeedCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};

export const createEmptyDeed = (): Deed => {
	return {
		id: 0,
		audityId: null,
		notaryData: { id: 0, notary: '', protocolNumber: '', city: '', notaryDate: null, notaryPeopleId: null, notaryPeopleName: '' },
		recordData: { id: 0, folio: '', inscription: '', merchandRecord: '', sheet: '', section: '', volume: '', inscriptionDate: null },
		body : null,
		socialAddress: null,
		socialCapital: {
			id: 0,
			amortizedStock: null,
			disbursement: null,
			extendedAmountType:null,
			initialSocialCapital: null,
			issuedStock: null,
			lastNumbering: null,
			nominal: null,
			securities: '',
			totalNumberStock: 0,
		},
		members: [],
		stockholders: [],
		subject: '',
		companyId: null,
		deedTypeId: null,
		documentsSp: [],
		deleteSPFiles: [],
		documentDate: null,
		tradeId: 0,
		bodyId: null,
		companyName:'',
		createdBy:'',
		deedTypeName:null,
		disbursementType:null,
		documentSetLink:null,
		modifiedBy:null,
		modifiedOn:null,
		subDeedTypeName:null,
		boxId:null,
		createdOn:null,
		subDeedTypeId:null,
		simple:'',
		copy:'',
		originalTypeId: null,
		originalTypeName: null,
		locationDeed:''
	};
};
