export enum ActiveAuthorizationFilter {
	Company = 1,
	Business = 2,
	Cif = 3,
	Account = 4,
	AuthorizedName = 5,
	AuthorizedNif = 6,
	Status = 7,
	CreationDate = 8,
	RevocationDate = 9,
}
