import { PositionApi } from '../../../../api/positions';
import { actionTypes } from '../../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { PositionDetail } from '../../../../model';
import { PositionDetailFilter } from '../../../../model/filters/PositionFilter';

export const getPositionAction = (filter: PositionDetailFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());

	PositionApi.FilterDetailAsync(filter, pageNumber, itemsPage).then((content) => {
		dispatch(getPositionCompleted(content));
	});
};
const getPositionCompleted = (position: PositionDetail) => ({
	type: actionTypes.GET_POSITION,
	payload: position,
	meta: {
		httpEnd: true,
	},
});
