import { GenericApi } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { Constants } from '../../../../common/const/constants';
import { Language } from '../../../../model';
import { PersonFilter } from '../../../../model/filters/PersonFilter';
import { getResourcesFile, showError, showSuccess } from '../../../../utils/extensions';
import { getPeopleAction } from '../../list/actions/getPeople';

export const deletePersonAction = (filter: PersonFilter, currentLanguage: Language, id: number) => (dispatch) => {
    let language = getResourcesFile(currentLanguage);
    GenericApi.DeleteAsync(ApiUrls.People, id).then((result: any) => {
        if (!result.error) {
            showSuccess(language['people']['form']['messages']['success-delete']);
        } else {
            showError(language['people']['form']['messages']['error-delete'] + language['generic']['error'][result.error]);
        }

        dispatch(getPeopleAction(filter, 1, Constants.ItemsPerPage));
        dispatch(deletePersonCompleted(result));
    });

};

const deletePersonCompleted = (result: boolean) => ({
    type: actionTypes.DELETE_PERSON,
    payload: result
});