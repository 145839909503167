import { OrderBy } from '../filters/OrderBy';
import { ActiveNotaryPeopleOrderFilter } from '../filters/ActiveNotaryPeopleOrderFilter';

export interface NotaryPeopleFilter {
	NameOrder: OrderBy;
	EmailOrder: OrderBy;
	Name: string;
	Email: string;
	NotaryPeopleId: number;

	CurrentFilter: ActiveNotaryPeopleOrderFilter;

	ItemsPage: number;
}
export const createEmptyNotaryPeopleFilter = (): NotaryPeopleFilter => {
	return {
		NameOrder: OrderBy.Asc,
		EmailOrder: OrderBy.Asc,
		Name: '',
		Email: '',
		NotaryPeopleId: 0,
		CurrentFilter: ActiveNotaryPeopleOrderFilter.Name,
		ItemsPage: 10,
	};
};
