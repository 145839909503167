import { ComboBox, IComboBoxOption, TextField, Toggle } from 'office-ui-fabric-react';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { languages } from '../../../common/const/resources';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { createEmptyDeedType, DeedType, Language } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
    saveDeedType: (deedType: DeedType, language: Language) => Promise<number>;
    getDeedType: (id: number) => DeedType;
    getDeedTypePrimaries: () => DeedType[];
    deedType: DeedType;
    deedTypePrimaries: DeedType[];
    deedTypeResponse: number | boolean;
    language: Language;
    loading: boolean;
}
export interface IState {
    errorMessages: string[];
    newDeedType: DeedType;
}
export class DeedTypeComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMessages: [],
            newDeedType: createEmptyDeedType(),
        };
    }
    public componentDidMount() {
        if (this.props.match.params['id'] != null) {
            this.props.getDeedType(this.props.match.params['id']);
        }
        this.props.getDeedTypePrimaries();
    }

    public componentWillReceiveProps(props) {
        if (props.deedType !== this.props.deedType) {
            this.setState({ newDeedType: { ...props.deedType } });
        }
    }

    public render() {
        let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
        let language = languages.languageES;
        if (this.props.language.name.includes(ActiveLanguage.English)) {
            language = languages.languageEN;
        }
        let validationErrors = this.state.errorMessages?.map((item: string) => {
            return (
                <MessageBarComponent
                    Message={item}
                    Type={MessageBarType.error}
                    MessageVisible={true}
                    close={null} />);
        });

        let deedTypePrimaries: IComboBoxOption[] = [];
        this.props.deedTypePrimaries.forEach((item: DeedType) => {
            deedTypePrimaries.push({ key: item.id, text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish });
        });
        return (
            <>
                <div className='ms-Grid-row'>
                    <h1>{this.props.match.params['id'] == null
                        ? language['deedType']['new']
                        : language['deedType']['edit']}
                    </h1>
                </div>
                <div className='ms-Grid-col ms-sm12 ms-lg9 ms-xl10'>
                    <div className='ms-Grid-row'>
                        <div className='ms-Grid-col ms-sm12 ms-xl12'>
                            <div className='main-container__content_edit ms-Grid-col ms-sm12 ms-xl3'>
                                <p>{language['deedType']['fields']['descriptionSpanish']['title']}</p>
                                <TextField
                                    type='text'
                                    value={this.state.newDeedType.descriptionSpanish}
                                    onChange={this.handleDescriptionSpanishChange}
                                    onBlur={this.handleDescriptionSpanishBlur}
                                    maxLength={100}
                                />
                                <p>{language['deedType']['fields']['descriptionEnglish']['title']}</p>
                                <TextField
                                    type='text'
                                    value={this.state.newDeedType.descriptionEnglish}
                                    onChange={this.handleDescriptionEnglishChange}
                                    onBlur={this.handleDescriptionEnglishBlur}
                                    maxLength={100}
                                />
                                <p>{language['deedType']['fields']['type']['title']} </p>
                                <ComboBox
                                    allowFreeform
                                    autoComplete='on'
                                    options={deedTypePrimaries.sort((a, b) => a.text.localeCompare(b.text))}
                                    onChange={this.handleTypeChange}
                                    selectedKey={this.state.newDeedType.fatherId}
                                    defaultSelectedKey={this.state.newDeedType.fatherId}
                                    placeholder={language['deedType']['fields']['type']['placeholder']}
                                />
                                <p>{language['deedType']['fields']['capital']['title']}</p>
                                <Toggle
                                    checked={this.state.newDeedType.affectsCapital}
                                    onText={language['generic']['yes']}
                                    offText={language['generic']['no']}
                                    onChanged={this.handleAffectsCapitalChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ms-Grid-row'>
                    <hr />
                    {validationErrors}
                    {isAdmin &&
                        <button type='button' className={`button-primary ${!this.props.loading ? '' : 'isDisabled'} `} name='saveDeedType' onClick={this.saveDeedType}>
                            {language['deedType']['save-button']}
                        </button>
                    }
                    <button onClick={utils.navigateBack} type='button' name='back' className='button-cancel'>
                        {language['deedType']['cancel-button']}
                    </button>
                </div>
            </>
        );
    }
    private readonly handleAffectsCapitalChange = (value: boolean) => {
        let state = { ...this.state };
        state.newDeedType.affectsCapital = value;
        this.setState(state);
    };
    private readonly handleTypeChange = (event, option): void => {
        let state = { ...this.state };
        state.newDeedType.fatherId = parseInt(option?.key);
        this.setState(state);
    };
    private readonly handleDescriptionSpanishChange = (value) => {
        let state = { ...this.state };
        state.newDeedType.descriptionSpanish = value.target.value;
        this.setState(state);
    };
    private readonly handleDescriptionSpanishBlur = (value) => {
        let state = { ...this.state };
        state.newDeedType.descriptionSpanish = value.target.value.toUpperCase();
        this.setState(state);
    };

    private readonly handleDescriptionEnglishChange = (value) => {
        let state = { ...this.state };
        state.newDeedType.descriptionEnglish = value.target.value;
        this.setState(state);
    };
    private readonly handleDescriptionEnglishBlur = (value) => {
        let state = { ...this.state };
        state.newDeedType.descriptionEnglish = value.target.value.toUpperCase();
        this.setState(state);
    };

    private readonly saveDeedType = (): number => {
        let deedType = { ...this.state.newDeedType };
        if (this.validateDeedType(deedType)) {
            return this.props.saveDeedType(deedType, this.props.language);
        }
        return 0;
    };
    private readonly validateDeedType = (deedType: DeedType) => {
        let errorMessages: string[] = [];
        let language = languages.languageES;
        if (this.props.language.name.includes(ActiveLanguage.English)) {
            language = languages.languageEN;
        }
        let hasDescriptionSpanish = !utils.isNullOrEmpty(deedType.descriptionSpanish);
        let hasDescriptionEnglish = !utils.isNullOrEmpty(deedType.descriptionEnglish);
        let isDeedTypeValid = hasDescriptionSpanish && hasDescriptionEnglish;
        if (!hasDescriptionSpanish) {
            errorMessages.push(language['generic']['messages']['required-spanish-description']);
        }
        if (!hasDescriptionEnglish) {
            errorMessages.push(language['generic']['messages']['required-english-description']);
        }

        if (!isDeedTypeValid) {
            this.setState({ errorMessages });
        }

        return isDeedTypeValid;
    }
}
