import { combineReducers } from 'redux';
import {
	AuthorizationAccount,
	AuthorizationAccountCollection,
	BaseCode,
	BaseName,
	BaseNameList,
	BaseType,
	BaseTypeCollection,
	BodyTypes,
	BodyTypesCollection,
	BusinessCollection,
	Country,
	Deed,
	DeedCollection,
	DeedType,
	DeedTypeCollection,
	DeedTypeSubType,
	Department,
	DepartmentCollection,
	DocumentDetail,
	DocumentsCollection,
	DocumentType,
	DocumentTypeCollection,
	DocumentTypeSubType,
	IError,
	Language,
	Person,
	PersonCollection,
	PositionCollection,
	NotaryPeople,
	NotaryPeopleCollection,
	PositionDetail,
	PositionType,
	PositionTypeCollection,
	PowerRepresentativeDetail,
	PowerSubject,
	RepresentativeCollection,
	RepresentativeDetailCollection,
	UntranslatedBaseType,
	User,
} from '../model';
import { CompanyBase, CompanyCollection, CompanySearch, RecordCompanyCustom, TradeCompany } from '../model/Company';
import { DeedTypeFilter, DocumentationFilter, DocumentTypeFilter, PersonFilter, NotaryPeopleFilter } from '../model/filters';
import { DepartmentFilter, PositionTypeFilter } from '../model/filters';
import { AuthorizationFilter } from '../model/filters/AuthorizationFilter';
import { CompanyFilter } from '../model/filters/CompanyFilter';
import { DeedFilter } from '../model/filters/DeedFilter';
import { PositionDetailFilter, PositionFilter } from '../model/filters/PositionFilter';
import { RecordCompanyDetailFilter } from '../model/filters/RecordCompanyFilter';
import { RepresentativeDetailFilter, RepresentativeFilter } from '../model/filters/RepresentativeFilter';
import { baseCodeListReducer } from '../reducers/baseCode';
import { baseNameListReducer, companiesByPersonReducer, currencyReducer } from '../reducers/baseName';
import {
	authorizationStatusesNamesReducer,
	baseTypeArrayReducer,
	baseTypeFormReducer,
	baseTypeListReducer,
	baseTypeReducer,
	bodyTypeNamesReducer,
	businessNameListReducer,
	departmentsReducer,
	notaryDocumentsReducer,
	originalsReducer,
	positionTypeNamesReducer,
	powerExerciseNamesReducer,
	powerTypeNamesReducer,
} from '../reducers/baseType';
import { bodyTypeFilterReducer, bodyTypeFormReducer, bodyTypeReducer, bodyTypesListReducer } from '../reducers/bodyTypesReducer';
import { companyFormReducer, companyListReducer, companyReducer, companySearchReducer, tradeCompanyListReducer } from '../reducers/companies';
import { csvDownloadReducer } from '../reducers/csvDownload';
import { deedFilterReducer, deedListReducer, deedReducer } from '../reducers/deed';
import {
	deedTypeCollectionReducer,
	deedTypeFilterReducer,
	deedTypePrimariesReducer,
	deedTypeReducer,
	deedTypeSubTypesReducer,
	deepTypeCrudReducer,
} from '../reducers/deedTypeReducer';
import {
	departmentCollectionReducer,
	departmentCrudReducer,
	departmentFilterReducer,
	departmentReducer,
} from '../reducers/departmentReducer';
import { languageReducer } from '../reducers/language';
import {
	nameAzureAdReducer,
	peopleListReducer,
	personAuthorizedNamesReducer,
	personFilterReducer,
	personFormReducer,
	personNamesReducer,
	personReducer,
} from '../reducers/people';
import {
	notaryPeopleListReducer,
	notaryPeopleFormReducer,
	notaryPeopleNamesReducer,
	notaryPeopleFilterReducer,
	notaryPeopleReducer
} from '../reducers/notaryPeople';
import { positionDetailFilterReducer, positionDetailReducer, positionFilterReducer, positionListReducer } from '../reducers/positions';
import { recordCompaniesListReducer, recordCompanyDetailFilterReducer, recordCompanyDetailReducer } from '../reducers/recordCompanies';
import {
	representativeDetailFilterReducer,
	representativeDetailReducer,
	representativeFilterReducer,
	representativeListReducer,
} from '../reducers/representatives';
import { userReducer } from '../reducers/user';
import { authReducer, AuthState } from './auth';
import { authorizationFilterReducer, authorizationListReducer, authorizationReducer } from './authorization';
import { businessListReducer } from './business';
import { companyFilterReducer } from './companies';
import { errorReducer } from './error';
import { homeReducer, HomeState } from './home';
import { httpReducer, HttpState } from './http';
import { getLastBoxReducer, powerReducer, powerSubjectListReducer } from './power';
import { usersReducer, UserState } from './users';
import {
	positionTypeCollectionReducer,
	positionTypeCrudReducer,
	positionTypeFilterReducer,
	positionTypeReducer,
} from '../reducers/positionTypeReducer';
import {
	documentTypeCollectionReducer,
	documentTypeFilterReducer,
	documentTypePrimariesReducer,
	documentTypeReducer,
	documentTypeSubTypesReducer,
	documentTypeCrudReducer,
} from '../reducers/documentTypeReducer';
import { countryReducer } from './countryReducer';
import { documentationFilterReducer, documentationFormReducer, documentationReducer, documentReducer } from './documentationReducer';
import { BodyTypesFilter } from '../model/filters/BodyTypesFilter';

export interface StateReducer {
	auth: AuthState;
	users: UserState;
	http: HttpState;
	home: HomeState;
	error: IError;
	peopleCollection: PersonCollection;
	person: Person;
	personSave: number | boolean;
	notaryPeople: NotaryPeople;
	notaryPeopleCollection: NotaryPeopleCollection;
	notaryPeopleSave: number | boolean;
	bodyTypes: BodyTypesCollection;
	bodyType: BodyTypes;
	powerTypes: BaseTypeCollection;
	powerType: BaseType;
	powers: BaseTypeCollection;
	powerNames: BaseType[];
	departments: BaseType[];
	power: BaseType;
	companies: CompanyCollection;
	company: CompanyBase;
	companyFilter: CompanyFilter;
	companiesSearch :CompanySearch[];
	deeds: DeedCollection;
	deed: Deed;
	deedFilter: DeedFilter;
	powerTypeSave: number | boolean;
	powerSave: number | boolean;
	bodyTypeSave: number | boolean;
	companySave: number | boolean;
	legalForms: BaseCode[];
	currencies: BaseName[];
	countries: Country[];
	companyNames: BaseName[];
	companiesByPersonId: BaseNameList;
	representatives: RepresentativeCollection;
	representative: RepresentativeDetailCollection;
	representativeFilter: RepresentativeFilter;
	representativeDetailFilter: RepresentativeDetailFilter;
	csvDownloadLink: string;
	notaryDocuments: UntranslatedBaseType[];
	peopleNames: Person[];
	notaryPeopleNames: NotaryPeople[];
	bodyTypeNames: BaseType[];
	positionTypeNames: BaseType[];
	powerTypeNames: BaseType[];
	powerExerciseNames: BaseType[];
	user: User;
	language: Language;
	positions: PositionCollection;
	position: PositionDetail;
	positionFilter: PositionFilter;
	positionDetailFilter: PositionDetailFilter;
	recordCompanies: CompanyCollection;
	recordCompany: RecordCompanyCustom;
	recordCompanyDetailFilter: RecordCompanyDetailFilter;
	authorizations: AuthorizationAccountCollection;
	authorization: AuthorizationAccount;
	authorizationFilter: AuthorizationFilter;
	business: BusinessCollection;
	authorizationStatuses: BaseTypeCollection;
	authorizationStatusesNames: BaseType[];
	businessNames: BaseType[];
	authorizationsUsers: Person[];
	department: Department;
	departmentCollection: DepartmentCollection;
	departmentFilter: DepartmentFilter;
	departmentResponse: number | boolean;
	tradeCompanies: TradeCompany[];
	powerRepresentatives: PowerRepresentativeDetail;
	deedType: DeedType;
	deedTypePrimaries: DeedType[];
	deedTypeSubTypes: DeedTypeSubType[];
	deedTypeCollection: DeedTypeCollection;
	deedTypeFilter: DeedTypeFilter;
	deedTypeResponse: number | boolean;
	positionType: PositionType;
	positionTypeCollection: PositionTypeCollection;
	positionTypeFilter: PositionTypeFilter;
	positionTypeResponse: number | boolean;
	documentType: DocumentType;
	documentTypePrimaries: DocumentType[];
	documentTypeSubTypes: DocumentTypeSubType[];
	documentTypeCollection: DocumentTypeCollection;
	documentTypeFilter: DocumentTypeFilter;
	documentTypeResponse: number | boolean;
	originals: BaseType[];
	documentsCollection: DocumentsCollection;
	documentationSave: number | boolean;
	document: DocumentDetail;
	nameAzureAd: string;
	powerSubjects: PowerSubject[];
	personFilter: PersonFilter;
	notaryPeopleFilter: NotaryPeopleFilter;
	bodyTypeFilter: BodyTypesFilter;
	documentationFilter: DocumentationFilter;
	lastBox: string;
}

export const state = combineReducers<StateReducer>({
	auth: authReducer,
	users: usersReducer,
	peopleCollection: peopleListReducer,
	notaryPeopleCollection: notaryPeopleListReducer,	
	person: personReducer,
	notaryPeople: notaryPeopleReducer,
	notaryPeopleSave: notaryPeopleFormReducer,
	http: httpReducer,
	home: homeReducer,
	error: errorReducer,
	personSave: personFormReducer,
	bodyTypes: bodyTypesListReducer,
	bodyType: bodyTypeReducer,
	bodyTypeSave: bodyTypeFormReducer,
	powerTypes: baseTypeListReducer,
	powerType: baseTypeReducer,
	powerTypeSave: baseTypeFormReducer,
	powers: baseTypeListReducer,
	power: baseTypeReducer,
	powerNames: baseTypeArrayReducer,
	departments: departmentsReducer,
	powerSave: baseTypeFormReducer,
	companies: companyListReducer,
	companiesByPersonId: companiesByPersonReducer,
	company: companyReducer,
	companySave: companyFormReducer,
	companyFilter: companyFilterReducer,
	companiesSearch:companySearchReducer,
	deeds: deedListReducer,
	deed: deedReducer,
	deedFilter: deedFilterReducer,
	legalForms: baseCodeListReducer,
	currencies: currencyReducer,
	countries: countryReducer,
	companyNames: baseNameListReducer,
	representatives: representativeListReducer,
	representative: representativeDetailReducer,
	representativeFilter: representativeFilterReducer,
	representativeDetailFilter: representativeDetailFilterReducer,
	csvDownloadLink: csvDownloadReducer,
	peopleNames: personNamesReducer,
	notaryPeopleNames: notaryPeopleNamesReducer,
	notaryDocuments: notaryDocumentsReducer,
	bodyTypeNames: bodyTypeNamesReducer,
	positionTypeNames: positionTypeNamesReducer,
	powerTypeNames: powerTypeNamesReducer,
	powerExerciseNames: powerExerciseNamesReducer,
	user: userReducer,
	language: languageReducer,
	positions: positionListReducer,
	position: positionDetailReducer,
	positionFilter: positionFilterReducer,
	positionDetailFilter: positionDetailFilterReducer,
	recordCompanies: recordCompaniesListReducer,
	recordCompany: recordCompanyDetailReducer,
	recordCompanyDetailFilter: recordCompanyDetailFilterReducer,
	authorizations: authorizationListReducer,
	authorization: authorizationReducer,
	authorizationFilter: authorizationFilterReducer,
	business: businessListReducer,
	authorizationStatuses: baseTypeListReducer,
	authorizationStatusesNames: authorizationStatusesNamesReducer,
	businessNames: businessNameListReducer,
	authorizationsUsers: personAuthorizedNamesReducer,
	department: departmentReducer,
	departmentCollection: departmentCollectionReducer,
	departmentFilter: departmentFilterReducer,
	departmentResponse: departmentCrudReducer,
	tradeCompanies: tradeCompanyListReducer,
	powerRepresentatives: powerReducer,
	positionType: positionTypeReducer,
	positionTypeCollection: positionTypeCollectionReducer,
	positionTypeFilter: positionTypeFilterReducer,
	positionTypeResponse: positionTypeCrudReducer,
	deedType: deedTypeReducer,
	deedTypeCollection: deedTypeCollectionReducer,
	deedTypeFilter: deedTypeFilterReducer,
	deedTypeResponse: deepTypeCrudReducer,
	deedTypePrimaries: deedTypePrimariesReducer,
	deedTypeSubTypes: deedTypeSubTypesReducer,
	documentType: documentTypeReducer,
	documentTypePrimaries: documentTypePrimariesReducer,
	documentTypeSubTypes: documentTypeSubTypesReducer,
	documentTypeCollection: documentTypeCollectionReducer,
	documentTypeFilter: documentTypeFilterReducer,
	documentTypeResponse: documentTypeCrudReducer,
	originals: originalsReducer,
	documentsCollection: documentationReducer,
	documentationSave: documentationFormReducer,
	document: documentReducer,
	nameAzureAd: nameAzureAdReducer,
	powerSubjects: powerSubjectListReducer,
	personFilter: personFilterReducer,
	notaryPeopleFilter: notaryPeopleFilterReducer,
	bodyTypeFilter: bodyTypeFilterReducer,
	documentationFilter: documentationFilterReducer,
	lastBox: getLastBoxReducer,
});
