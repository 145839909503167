import { GenericApi } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import useHistory from '../../../history';
import { PositionType, Language } from '../../../model';
import { getResourcesFile, showError, showSuccess } from '../../../utils/extensions';
const browserHistory = useHistory;

export const savePositionTypeAction = (positionType: PositionType, currentLanguage: Language) => (dispatch) => {
    dispatch(httpCallStartAction());
    let language = getResourcesFile(currentLanguage);
    if (positionType.id > 0) {
        handleUpdatePositionType(positionType, language, dispatch);
    } else {
        handleCreatePositionType(positionType, language, dispatch);
    }
};


const handleUpdatePositionType = (positionType: PositionType, language: any, dispatch) => {
    GenericApi.UpdateAsync(ApiUrls.PositionTypes, positionType).then((result: any) => {
        if (result.error) {
            showError(language['position-types']['messages']['error-update'] + " " + language['generic']['error'][result.error]);
            dispatch(savePositionTypeCompleted(result));
        }
        else {
            showSuccess(language['position-types']['messages']['success-update']);
            browserHistory.goBack();
            dispatch(savePositionTypeCompleted(result));
        }
    });
}

const handleCreatePositionType = (positionType: PositionType, language: any, dispatch) => {
    GenericApi.AddAsync(ApiUrls.PositionTypes, positionType).then((result: any) => {
        if (result > 0) {
            showSuccess(language['position-types']['messages']['success']);
            browserHistory.goBack();
            dispatch(savePositionTypeCompleted(result));
        } else {
            showError(language['position-types']['messages']['error'] + " " + language['generic']['error'][result.error]);
            dispatch(savePositionTypeCompleted(result));
        }
    });
}

const savePositionTypeCompleted = (result: number) => ({
    type: actionTypes.SAVE_POSITION_TYPE_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});