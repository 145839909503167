import * as React from 'react';
import PropTypes from 'prop-types';
import { Language, RecordCompanyCustom, StockholderCompany } from '../../../../model';
import { ActiveLanguage } from '../../../../model/enums/activeLanguage';
import { languages } from '../../../../common/const/resources';
import { RecordCompanyDetailFilter } from '../../../../model/filters';
import { HistoricalStockholdersPagination } from '../pagination/historicalStockholdersPagination';
import { DefaultButton } from 'office-ui-fabric-react/lib/components/Button';

export interface IProps {
	stockholders: StockholderCompany[];
	totalRows: number;
	language: Language;
	filter: RecordCompanyDetailFilter;
	getRecordCompany: (filter: RecordCompanyDetailFilter) => Promise<RecordCompanyCustom>;
	exportToExcel: (companyId: number[]) => Promise<any>;
}

export interface IState {}

export class HistoricallStockholdersGrid extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			itemsPage: 10,
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				<div className='ms-Grid-row subtable_record'>
					<div className='ms-Grid-col ms-xl6'>
						<span className='title_subtable_record'>
							{language['corporate']['detail']['grids']['stockholders-grid']['title-historical']}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton className='button-download' onClick={this.handleExportClick}>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th>
									<div className='table-header'>
										<span>
											{language['corporate']['detail']['grids']['stockholders-grid']['fields']['stock-holders']}
										</span>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['corporate']['detail']['grids']['stockholders-grid']['fields']['stock']}</span>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>%</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.stockholders.map((item) => {
								return this.getRow(item);
							})}
							<tr className='totalRow'>
								<td className='mainColumn'>
									{language['corporate']['detail']['grids']['stockholders-grid']['fields']['total']}
								</td>
								<td>{this.getTotalStock(this.props.stockholders)}</td>
								<td>{this.getTotalPercentStock(this.props.stockholders)}%</td>
							</tr>
						</tbody>
					</table>
				</div>
				<HistoricalStockholdersPagination
					filter={this.props.filter}
					getRecordCompany={this.props.getRecordCompany}
					totalRows={this.props.totalRows}
				/>
			</>
		);
	}

	public handleExportClick = () => {
		this.props.exportToExcel(this.props.filter.CompaniesId);
	};

	private getRow(stockholder: StockholderCompany) {
		return (
			<tr>
				<td className='mainColumn'>{stockholder.name}</td>
				<td>{stockholder.total}</td>
				<td>{stockholder.percentage}%</td>
			</tr>
		);
	}

	private getTotalStock(stockholders: StockholderCompany[]) {
		let sum = 0;
		if (stockholders) {
			this.props.stockholders.map((item) => {
				return sum += item.total;
			});
			if (!isNaN(sum)) {
				return sum;
			}
			return '-';
		}
		return null;
	}

	private getTotalPercentStock(stockholders: StockholderCompany[]) {
		let sum = 0;
		if (stockholders) {
			this.props.stockholders.map((item) => {
				return sum += item.percentage;
			});
			if (!isNaN(sum)) {
				return sum;
			}
			return '-';
		}
		return null;
	}
}
