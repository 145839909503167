import { actionTypes } from '../common/const/actionTypes';
import { DeedCollection, Deed, createEmptyDeed, createEmptyDeedList } from '../model';
import { createEmptyDeedFilter, DeedFilter } from '../model/filters/DeedFilter';

export const deedListReducer = (state = createEmptyDeedList(), action) => {
	switch (action.type) {
		case actionTypes.GET_DEEDS:
			return handleGetDeedListCompleted(state, action.payload);
	}

	return state;
};

export const deedReducer = (state = createEmptyDeed(), action) => {
	switch (action.type) {
		case actionTypes.GET_DEED:
			return handleGetDeedCompleted(state, action.payload);
	}

	return state;
};
export const deedFilterReducer = (state = createEmptyDeedFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_DEED_FILTER:
			return handleSetDeedFilterCompleted(state, action.payload);
	}

	return state;
};
export const deedFormReducer = (state, action) => {
	switch (action.type) {
		case actionTypes.SAVE_DEED_COMPLETED:
			return handleSaveDeedCompleted(state, action.payload);
		case actionTypes.DELETE_DEED:
			return handleDeleteDeedCompleted(state, action.payload);
	}

	return state;
};

const handleSaveDeedCompleted = (state: number, payload: number) => {
	return payload;
};

const handleDeleteDeedCompleted = (state: number, payload: number) => {
	return payload;
};

const handleGetDeedListCompleted = (state: DeedCollection, payload: DeedCollection) => {
	return payload;
};

const handleGetDeedCompleted = (state: Deed, payload: Deed) => {
	return payload;
};
const handleSetDeedFilterCompleted = (state: DeedFilter, payload: DeedFilter) => {
	return payload;
};
