import * as React from 'react';
import * as utils from '../../../utils/extensions';
import PropTypes from 'prop-types';
import { RouteComponentProps, withRouter } from 'react-router';
import { CompanyBase, CompanyCollection, Language, User } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { ActiveCompanyOrderFilter, CompanyFilter, OrderBy } from '../../../model/filters/';
import { languages } from '../../../common/const/resources';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { DefaultButton } from 'office-ui-fabric-react/lib/components/Button';
import { routeUrls } from '../../../common/const/routes';

export interface IProps {
	companies: CompanyCollection;
	user: User;
	language: Language;
	filter: CompanyFilter;
	page: number;
	getCompanies: (filter: CompanyFilter, pageNumbers: number, itemsPage: number) => Promise<CompanyCollection>;
	setCompanyFilter: (filter: CompanyFilter) => CompanyFilter;
	exportToExcel: (filter: CompanyFilter) => Promise<any>;
}

export interface IState {
	showModal: boolean;
	id: number;
	actualFilter: CompanyFilter;
	orderBySociety: string;
	orderByTrade: string;
	orderByCif: string;
	orderByCountry: string;
	orderByConstitutionDate: string;
}

class RecordCompanyDataGridComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			id: 0,
			actualFilter: this.props.filter,
			orderBySociety: 'ms-Icon--ChevronUpSmall',
			orderByTrade: 'ms-Icon--ChevronUpSmall',
			orderByCif: 'ms-Icon--ChevronUpSmall',
			orderByCountry: 'ms-Icon--ChevronUpSmall',
			orderByConstitutionDate: 'ms-Icon--ChevronUpSmall',
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ actualFilter: this.props.filter });
		}
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.companies.totalNumberOfRows > 1 ? (
								<span>
									{this.props.companies.totalNumberOfRows} {language['generic']['list']['table']['results']}
								</span>
							) : this.props.companies.totalNumberOfRows === 1 ? (
								<span>
									{this.props.companies.totalNumberOfRows} {language['generic']['list']['table']['result']}
								</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={this.props.companies.totalNumberOfRows > 0 ? 'button-download' : 'button-download isDisabled'}
							onClick={this.handleExportClick}
						>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th>
									<div className='table-header'>
										<span>{language['corporate']['list']['table']['columns']['society']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActiveCompanyOrderFilter.Society === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.SocietyOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderBySociety}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActiveCompanyOrderFilter.Society === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.SocietyOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderBySociety}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['corporate']['list']['table']['columns']['cif']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActiveCompanyOrderFilter.Cif === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.CifOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByCif}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActiveCompanyOrderFilter.Cif === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.CifOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByCif}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['corporate']['list']['table']['columns']['country']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													(ActiveCompanyOrderFilter.CountrySpanish === this.state.actualFilter.CurrentFilter ||
														ActiveCompanyOrderFilter.CountryEnglish ===
															this.state.actualFilter.CurrentFilter) &&
													this.state.actualFilter.CountryOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByCountry}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													(ActiveCompanyOrderFilter.CountrySpanish === this.state.actualFilter.CurrentFilter ||
														ActiveCompanyOrderFilter.CountryEnglish ===
															this.state.actualFilter.CurrentFilter) &&
													this.state.actualFilter.CountryOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByCountry}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['corporate']['list']['table']['columns']['constitution-date']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActiveCompanyOrderFilter.ConstitutionDate === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.ConstitutionDateOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByConsitutionDate}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActiveCompanyOrderFilter.ConstitutionDate === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.ConstitutionDateOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByConsitutionDate}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['corporate']['list']['table']['columns']['trade']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													(ActiveCompanyOrderFilter.TradeSpanish === this.state.actualFilter.CurrentFilter ||
														ActiveCompanyOrderFilter.TradeEnglish === this.state.actualFilter.CurrentFilter) &&
													this.state.actualFilter.TradeOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByTrade}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													(ActiveCompanyOrderFilter.TradeSpanish === this.state.actualFilter.CurrentFilter ||
														ActiveCompanyOrderFilter.TradeEnglish === this.state.actualFilter.CurrentFilter) &&
													this.state.actualFilter.TradeOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByTrade}
											></i>
										</div>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.companies?.list?.map((item: CompanyBase) => {
								let trade = item.tradeSpanish;
								let country = item.countrySpanish;
								if (this.props.language.name.includes(ActiveLanguage.English)) {
									trade = item.tradeEnglish;
									country = item.countryEnglish;
								}
								return (
									<tr>
										<td
											className='mainColumn clickableCell'
											onClick={() => {
												this.handleRowClick(item.id);
											}}
										>
											{item.name}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.id);
											}}
										>
											{item.cif}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.id);
											}}
										>
											{country}
										</td>
										{item.constitutionDate !== null ? (
											<td
												className='clickableCell'
												onClick={() => {
													this.handleRowClick(item.id);
												}}
											>
												{utils.getParsedDate(item.constitutionDate).toLocaleDateString('es-ES')}
											</td>
										) : (
											<td></td>
										)}
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.id);
											}}
										>
											{trade}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{
					<AdvancedPagination
						currentPage={this.props.page}
						items={this.props.companies.totalNumberOfRows}
						link={routeUrls.recordCompaniesPagination}
						updateMaxPageItems={this.handleItemsPerPageChange}
						actualPageItems={this.state.actualFilter.ItemsPage}
						language={this.props.language}
					/>
				}
			</>
		);
	}

	public handleRowClick(companyId: number) {

		let actualpage = this.props.history.location.pathname;

		this.props.history.push(`/recordCompanies/detail/` + companyId + `?previousPage=${actualpage}`);
	}

	public handleExportClick = () => {
		let filter: CompanyFilter = { ...this.state.actualFilter };
		this.props.exportToExcel(filter);
	};

	public handleOrderBySociety = () => {
		let filter: CompanyFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveCompanyOrderFilter.Society;
		let orderBy: string = '';

		if (filter.SocietyOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.SocietyOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.SocietyOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderBySociety: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getCompanies(filter, 1, this.state.actualFilter.ItemsPage);
	};

	public handleOrderByTrade = () => {
		let filter: CompanyFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveCompanyOrderFilter.TradeSpanish;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActiveCompanyOrderFilter.TradeEnglish;
		}

		let orderBy: string = '';

		if (filter.TradeOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.TradeOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.TradeOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByTrade: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getCompanies(filter, 1, this.state.actualFilter.ItemsPage);
	};

	public handleOrderByCif = () => {
		let filter: CompanyFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveCompanyOrderFilter.Cif;
		let orderBy: string = '';

		if (filter.CifOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CifOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CifOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByCif: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getCompanies(filter, 1, this.state.actualFilter.ItemsPage);
	};

	public handleOrderByCountry = () => {
		let filter: CompanyFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveCompanyOrderFilter.CountrySpanish;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActiveCompanyOrderFilter.CountryEnglish;
		}

		let orderBy: string = '';

		if (filter.CountryOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CountryOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CountryOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByCountry: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getCompanies(filter, 1, this.state.actualFilter.ItemsPage);
	};

	public handleOrderByConsitutionDate = () => {
		let filter: CompanyFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveCompanyOrderFilter.ConstitutionDate;
		let orderBy: string = '';

		if (filter.ConstitutionDateOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.ConstitutionDateOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.ConstitutionDateOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByConstitutionDate: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getCompanies(filter, 1, this.state.actualFilter.ItemsPage);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: CompanyFilter = { ...this.state.actualFilter };
		filter.ItemsPage = itemsPage;
		this.setState({ actualFilter: filter });
		this.props.getCompanies(filter, 1, itemsPage);
	};
}

export const RecordCompanyDataGrid = withRouter(RecordCompanyDataGridComponent);
