import { actionTypes } from '../common/const/actionTypes';
import {
	createEmptyDocumentType,
	createEmptyDocumentTypeCollection,
	createEmptyDocumentTypeSubType,
	DocumentType,
	DocumentTypeCollection,
	DocumentTypeSubType,
} from '../model/DocumentType';
import { createEmptyDocumentTypeFilter, DocumentTypeFilter } from '../model/filters';

export const documentTypeFilterReducer = (state = createEmptyDocumentTypeFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_DOCUMENT_TYPE_FILTER:
			return handleSetDocumentTypeFilterCompleted(state, action.payload);
	}

	return state;
};
export const documentTypeCollectionReducer = (state = createEmptyDocumentTypeCollection(), action) => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENT_TYPE_COLLECTION:
			return handleGetDocumentTypeCollectionCompleted(state, action.payload);
	}

	return state;
};

export const documentTypeReducer = (state = createEmptyDocumentType(), action) => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENT_TYPE:
			return handleGetDocumentTypeCompleted(state, action.payload);
	}

	return state;
};
export const documentTypePrimariesReducer = (state = [createEmptyDocumentType()], action) => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENT_TYPE_PRIMARIES:
			return handleGetDocumentTypePrimariesCompleted(state, action.payload);
	}

	return state;
};
export const documentTypeSubTypesReducer = (state = [createEmptyDocumentTypeSubType()], action) => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENT_TYPE_SUBTYPES:
			return handleGetDocumentTypeSubTypesCompleted(state, action.payload);
	}

	return state;
};

export const documentTypeCrudReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.SAVE_DOCUMENT_TYPE_COMPLETED:
			return handleSaveDocumentTypeCompleted(state, action.payload);
		case actionTypes.DELETE_DOCUMENT_TYPE:
			return handleDeleteDocumentTypeCompleted(state, action.payload);
	}

	return state;
};

const handleGetDocumentTypeCollectionCompleted = (state: DocumentTypeCollection, payload: DocumentTypeCollection) => {
	return payload;
};

const handleGetDocumentTypeCompleted = (state: DocumentType, payload: DocumentType) => {
	return payload;
};
const handleGetDocumentTypePrimariesCompleted = (state: DocumentType[], payload: DocumentType[]) => {
	return payload;
};
const handleGetDocumentTypeSubTypesCompleted = (state: DocumentTypeSubType[], payload: DocumentTypeSubType[]) => {
	return payload;
};

const handleDeleteDocumentTypeCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleSaveDocumentTypeCompleted = (state: number, payload: number) => {
	return payload;
};
const handleSetDocumentTypeFilterCompleted = (state: DocumentTypeFilter, payload: DocumentTypeFilter) => {
	return payload;
};
