import { OrderBy } from './OrderBy';
import { ActiveAuthorizationFilter } from './ActiveAuthorizationFilter';
import { ISelectOption } from '../../common/customSelect/customSelect';

export interface AuthorizationFilter {
	AuthorizedOrder: OrderBy;
	NifAutorizrdOrder: OrderBy;
	CompanyOrder: OrderBy;
	AccountOrder: OrderBy;
	CreationDateOrder: OrderBy;
	RevocationDateOrder: OrderBy;
	StatusOrder: OrderBy;

	CompaniesId: number[];
	CompanyName?: string[];
	Business: number;
	BusinessName?: string;
	Cif: string;
	UniqueCode?: string;
	Account: string;
	AuthorizedId: number;
	AuthorizedName?: string;
	AuthorizedNif: string;
	Status: number;
	StatusName?: string;
	CreationDateFrom: Date;
	CreationDateTo: Date;
	RevocationDateFrom: Date;
	RevocationDateTo: Date;

	CurrentFilter: ActiveAuthorizationFilter;
	selectedCompanies: ISelectOption[];
}

export const createEmptyAuthorizationFilter = (): AuthorizationFilter => {
	return {
		AuthorizedOrder: OrderBy.Asc,
		NifAutorizrdOrder: OrderBy.Asc,
		CompanyOrder: OrderBy.Asc,
		AccountOrder: OrderBy.Asc,
		CreationDateOrder: OrderBy.Asc,
		RevocationDateOrder: OrderBy.Asc,
		StatusOrder: OrderBy.Asc,

		CompaniesId: [],
		CompanyName: [],
		Business: 0,
		Cif: '',
		UniqueCode: '',
		Account: '',
		AuthorizedId: 0,
		AuthorizedName: '',
		AuthorizedNif: '',
		Status: 0,
		CreationDateFrom: null,
		CreationDateTo: null,
		RevocationDateFrom: null,
		RevocationDateTo: null,

		CurrentFilter: ActiveAuthorizationFilter.AuthorizedName,
		selectedCompanies: []
	};
};
