import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { GenericApi } from '../../../../api/generic';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { Country } from '../../../../model';

export const getCountryNamesAction = () => (dispatch) => {
	dispatch(httpCallStartAction());
	GenericApi.GetAsync<Country>(ApiUrls.Countries).then((content) => {
		dispatch(getCountryNamesCompleted(content));
	});
};

const getCountryNamesCompleted = (countries: Country[]) => ({
	type: actionTypes.GET_COUNTRIES,
	payload: countries,
	meta: {
		httpEnd: true,
	},
});
