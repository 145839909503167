import * as React from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Language, PositionCollection, User, PositionGrid } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { PositionDetailFilter, PositionFilter } from '../../../model/filters/PositionFilter';
import { languages } from '../../../common/const/resources';
import { DefaultButton } from 'office-ui-fabric-react';
import { ActivePositionOrderFilter, OrderBy } from '../../../model/filters';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { routeUrls } from '../../../common/const/routes';

export interface IProps {
	page: number;
	positions: PositionGrid[];
	positionCollection: PositionCollection;
	user: User;
	filter: PositionFilter;
	language: Language;
	setPositionFilter: (filter: PositionFilter) => PositionFilter;
	setPositionDetailFilter: (filter: PositionDetailFilter) => PositionDetailFilter;
	getPositions: (filter: PositionFilter, pageNumbers: number, itemsPage: number) => Promise<PositionCollection>;
	exportToExcel: (filter: PositionFilter) => Promise<any>;
	onchangeFilter: any;
}

export interface IState {
	collapsed: boolean;
	filter: PositionFilter;
	orderByCompanyName: string;
	orderByName: string;
	orderByPositionName: string;
	orderByDni: string;
}

class PositionsDataGridComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			orderByCompanyName: 'ms-Icon--ChevronUpSmall',
			orderByName: 'ms-Icon--ChevronUpSmall',
			orderByPositionName: 'ms-Icon--ChevronUpSmall',
			orderByDni: 'ms-Icon--ChevronUpSmall',
			filter: this.props.filter,
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};
	
	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ filter: this.props.filter });
		}
	}

	public render() {
		const { filter } = this.state;
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.positionCollection.totalNumberOfRows > 1 ? (
								<span>
									{this.props.positionCollection?.totalNumberOfRows} {language['generic']['list']['table']['results']}
								</span>
							) : this.props.positionCollection.totalNumberOfRows === 1 ? (
								<span>
									{this.props.positionCollection.totalNumberOfRows} {language['generic']['list']['table']['result']}
								</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={
								this.props.positionCollection.totalNumberOfRows > 0 ? 'button-download' : 'button-download isDisabled'
							}
							onClick={this.handleExportClick}
						>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th>
									<div className='table-header'>
										<span>{language['positions']['list']['table']['columns']['name']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActivePositionOrderFilter.NameOrder === filter.CurrentFilter &&
													filter.NameOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByName}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActivePositionOrderFilter.NameOrder === filter.CurrentFilter &&
													filter.NameOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByName}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['positions']['list']['table']['columns']['society']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActivePositionOrderFilter.CompanyName === filter.CurrentFilter &&
													filter.CompanyNameOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompanyName}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActivePositionOrderFilter.CompanyName === filter.CurrentFilter &&
													filter.CompanyNameOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompanyName}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['positions']['list']['table']['columns']['position']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													(ActivePositionOrderFilter.PositionTypeSpanish === filter.CurrentFilter ||
														ActivePositionOrderFilter.PositionTypeEnglish === filter.CurrentFilter) &&
													filter.PositionTypeNameOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByPositionName}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													(ActivePositionOrderFilter.PositionTypeSpanish === filter.CurrentFilter ||
														ActivePositionOrderFilter.PositionTypeEnglish === filter.CurrentFilter) &&
													filter.PositionTypeNameOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByPositionName}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['positions']['list']['table']['columns']['dni']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${
													ActivePositionOrderFilter.Dni === filter.CurrentFilter &&
													filter.DniOrder === OrderBy.Asc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByDni}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${
													ActivePositionOrderFilter.Dni === filter.CurrentFilter &&
													filter.DniOrder === OrderBy.Desc
														? 'selected'
														: ''
												}`}
												aria-hidden='true'
												onClick={this.handleOrderByDni}
											></i>
										</div>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.positions.map((item) => {
								let positionTypeName = item.positionTypeSpanish;

								if (this.props.language.name.includes(ActiveLanguage.English)) {
									positionTypeName = item.positionTypeEnglish;
								}
								return (
									<tr>
										<td
											className='mainColumn clickableCell'
											onClick={() => {
												this.handleRowClick(item.personId);
											}}
										>
											{item.name}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.personId);
											}}
										>
											{item.companyName}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.personId);
											}}
										>
											{positionTypeName}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.personId);
											}}
										>
											{item.dni}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{
					<AdvancedPagination
						currentPage={this.props.page}
						items={this.props.positionCollection.totalNumberOfRows}
						link={routeUrls.positionsPagination}
						updateMaxPageItems={this.handleItemsPerPageChange}
						actualPageItems={this.state.filter.ItemsPage}
						language={this.props.language}
					/>
				}
			</>
		);
	}

	public handleExportClick = () => {
		let filter: PositionFilter = { ...this.state.filter };
		this.props.exportToExcel(filter);
	};

	public readonly handleOrderByName = (event) => {
		let filter: PositionFilter = { ...this.state.filter };
		filter.CurrentFilter = ActivePositionOrderFilter.NameOrder;
		let orderBy: string = '';

		if (filter.NameOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.NameOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.NameOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByName: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPositions(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByCompanyName = (event) => {
		let filter: PositionFilter = { ...this.state.filter };
		filter.CurrentFilter = ActivePositionOrderFilter.CompanyName;
		let orderBy: string = '';

		if (filter.CompanyNameOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CompanyNameOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CompanyNameOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByCompanyName: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPositions(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByPositionName = (event) => {
		let filter: PositionFilter = { ...this.state.filter };
		filter.CurrentFilter = ActivePositionOrderFilter.PositionTypeSpanish;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActivePositionOrderFilter.PositionTypeEnglish;
		}

		let orderBy: string = '';

		if (filter.PositionTypeNameOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.PositionTypeNameOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.PositionTypeNameOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByPositionName: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPositions(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDni = (event) => {
		let filter: PositionFilter = { ...this.state.filter };
		filter.CurrentFilter = ActivePositionOrderFilter.Dni;

		if (this.props.language.name.includes(ActiveLanguage.English)) {
			filter.CurrentFilter = ActivePositionOrderFilter.Dni;
		}

		let orderBy: string = '';

		if (filter.DniOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DniOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DniOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByDni: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPositions(filter, 1, filter.ItemsPage);
	};

	public handleRowClick(positionId: number) {

		let actualpage = this.props.history.location.pathname;

		this.props.setPositionDetailFilter({ PersonId: positionId });
		this.props.setPositionFilter(this.props.filter);
		this.props.history.push(`/positions/detail/` + positionId + `/1?previousPage=${actualpage}`);
	}

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: PositionFilter = { ...this.state.filter };
		filter.ItemsPage = itemsPage
		this.setState({ filter: filter });
		this.props.getPositions(filter, 1, itemsPage);
	};
}

export const PositionsDataGrid = withRouter(PositionsDataGridComponent);
