import { connect } from 'react-redux';
import { StateReducer } from '../reducers';
import { Layout } from './layout';
import { fetchIsUserAuthorized } from '../actions/usersActions';
import { Account } from 'msal';

const mapStateToProps = (state: StateReducer) => ({
	user: state.user,
	language: state.language,
	isUserAuthenticathed: state.auth.initialized,
	currentUser: state.users.currentUser,
	account: state.auth.account,
	token: state.auth.accessToken,
});

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchUserAuthorize: (account: Account, token: string) => dispatch(fetchIsUserAuthorized(account, token)),
	};
};

export const LayoutContainer = connect(mapStateToProps, mapDispatchToProps)(Layout);
