import { DeedTypeApi } from '../../../api/deedType/deedTypeApi';
import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DeedTypeSubType } from '../../../model';

export const getDeedTypeSubTypeAction = () => (dispatch) => {
    dispatch(httpCallStartAction());

    DeedTypeApi.GetDeedTypeSubType().then((result) => {
        dispatch(getDeedTypeSubTypesCompleted(result));
    });
};

const getDeedTypeSubTypesCompleted = (deedTypeSubtypes: DeedTypeSubType[]) => ({
    type: actionTypes.GET_DEED_TYPE_SUBTYPES,
    payload: deedTypeSubtypes,
    meta: {
        httpEnd: true,
    }
});