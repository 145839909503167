import * as React from 'react';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/index';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { ComboBox, IComboBoxOption } from 'office-ui-fabric-react/lib/ComboBox';
import { languages } from '../../../common/const/resources';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import { SearchPeople } from '../../../common/search/searchPeople';
import { BaseType, Language, Person, PersonCollection, SearchSelectModel, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { createEmptyPersonFilter, PersonFilter } from '../../../model/filters';

export interface IProps {
	getPeople: (filter: PersonFilter, pageNumbers: number, itemsPage: number) => Promise<PersonCollection>;
	filter: PersonFilter;
	onchangeFilter: any;
	language: Language;
	business: BaseType[];
	user: UserState;
	peopleNames: Person[];
	itemsPage: number;
	page: number;
	getPeopleNames: (filter: string) => Promise<Person[]>;
}

export interface IState {
	filter: PersonFilter;
	selectedPerson: any;
	collapsed: boolean;
}

export class PeopleSearchComponent extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			filter: createEmptyPersonFilter(),
			selectedPerson: null,
		};
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let users: SearchSelectModel[] = [];
		if (this.props.peopleNames && this.props.peopleNames.length > 0) {
			this.props.peopleNames.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		let business: IComboBoxOption[] = [];
		if (this.props.business) {			
			this.props.business.forEach((item) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			business.sort((a, b) => a.text.localeCompare(b.text))
			business.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			business.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}

		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div className={this.state.collapsed ? 'ms-Grid-row filter_header-collapse' : 'ms-Grid-row filter_header'}>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<SearchPeople
									title={language['people']['list']['filter']['options']['name']['title']}
									placeholder={language['people']['list']['filter']['options']['name']['placeholder']}
									language={this.props.language}
									selectedOption={this.state.selectedPerson}
									loadOptions={this.loadOptions}
									handleNameChange={this.handlePersonNameChange}
									getUsers={this.props.getPeopleNames}
									onClearValue={this.onClearSearchPeople}
									showDeleteButton={true}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['people']['list']['filter']['options']['dni']['title']}</p>
								<TextField
									type='text'
									placeholder={language['people']['list']['filter']['options']['dni']['placeholder']}
									value={this.state.filter.DNI}
									onChange={this.handleDniChange}
									onBlur={this.handleDniBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['people']['list']['filter']['options']['email']['title']}</p>
								<TextField
									type='text'
									placeholder={language['people']['list']['filter']['options']['email']['placeholder']}
									value={this.state.filter.Email}
									onChange={this.handleEmailChange}
									onBlur={this.handleEmailBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['trade']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={business}
									placeholder={language['authorizations']['list']['filter']['options']['trade']['placeholder']}
									onChange={this.handleBusinessChange}
									selectedKey={this.state.filter.TradeId}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterPeople}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterPeople}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.filter.PersonId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['people']['list']['filter']['options']['name']['title']}</span>
								<span className='value'>{this.state.filter.Name}</span>
							</p>
							<p style={{ display: this.state.filter.DNI !== '' ? '' : 'none' }}>
								<span className='key'>{language['people']['list']['filter']['options']['dni']['title']}</span>
								<span className='value'>{this.state.filter.DNI}</span>
							</p>
							<p style={{ display: this.state.filter.Email !== '' ? '' : 'none' }}>
								<span className='key'>{language['people']['list']['filter']['options']['email']['title']}</span>
								<span className='value'>{this.state.filter.Email}</span>
							</p>
							<p style={{ display: this.state.filter.TradeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['authorizations']['list']['filter']['options']['trade']['title']}</span>
								<span className='value'>{this.state.filter.TradeName}</span>
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	componentDidMount() {
		let selectedPerson = null;
		let filter = {...this.props.filter}

		if(filter.PersonId >0 && (filter.Name!==null && filter.Name!=="")){
			selectedPerson = {value:filter.PersonId, label: filter.Name};
		}

		this.setState({ filter: filter, selectedPerson: selectedPerson });
	}

	private readonly handleFiltersCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	private readonly onClearSearchPeople = (): void => {
		let filter = { ...this.state.filter };
		let selectedPerson = { ...this.state.selectedPerson };
		filter.PersonId = 0;
		filter.Name = "";
		selectedPerson.value=null;
		selectedPerson.label=null;
		this.setState({ filter: filter, selectedPerson: selectedPerson });
	};

	private readonly handlePersonNameChange = (selectedPerson) => {
		let filter = { ...this.state.filter };
		filter.PersonId = parseInt(selectedPerson?.value);
		filter.Name = selectedPerson?.label;
		this.setState({ filter: filter, selectedPerson: selectedPerson });
	};

	private readonly handleDniChange = (value) => {
		let filter = { ...this.state.filter };
		filter.DNI = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleDniBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.DNI = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly handleEmailChange = (value) => {
		let filter = { ...this.state.filter };
		filter.Email = value.target.value
		this.setState({ filter: filter });
	};
	private readonly handleEmailBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.Email = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly handleBusinessChange = (event, option): void => {
		let filter = { ...this.state.filter };
		filter.TradeId = parseInt(option?.key);
		filter.TradeName = option?.text;
		this.setState({ filter: filter });
	};

	private readonly filterPeople = () => {
		let filter = { ...this.state.filter };
		this.setState({ collapsed: true });
		this.props.onchangeFilter(filter);
		this.props.getPeople(filter, 1, this.props.itemsPage);
	};

	private readonly cleanFilterPeople = () => {
		let resetFilter = createEmptyPersonFilter();
		this.setState({ filter: resetFilter, selectedPerson: null });
		this.props.onchangeFilter(resetFilter);
		this.props.getPeople(resetFilter, 1, this.props.itemsPage);
	};

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filterColors(inputValue));
		}, 1000);
	};

	private readonly filterColors = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.peopleNames && this.props.peopleNames.length > 0) {
			this.props.peopleNames.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};
}
