import { SiteProps } from '../../model/Config';
import * as fetch from 'isomorphic-fetch';
import { DeedFilter, ActiveDeedFilter } from '../../model/filters';
import { DeedCollection, Deed } from '../../model';
import { ApiUrls } from '../../common/const/api';
import * as utils from '../../utils/extensions';
import { DecideSortingOrder } from '../../utils/extensions';
import moment from 'moment';
import * as msal from '../../common/security/msal';
import { onlyNetherlands } from '../../common/const/rolesAssignments';
import { Modules } from '../../model/enums';

// let pageRender: number = 0;		Clean Warning Code 

const moduleId = Modules.Deeds;

const FilterAsync = async (filter: DeedFilter, pageNumber: number, itemsPage: number): Promise<DeedCollection> => {
	let baseUrl = `${SiteProps.SiteURL}/api/${ApiUrls.Deed}/Get`;
	// pageRender = pageNumber;		Clean Warning Code 

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}&moduleId=${moduleId}`;
	let queryString = GetQueryString(filter);

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) => response.json());
};

async function AddAsync<T>(baseUrl: string, item: T): Promise<number> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Deed}/Add`;

	const token = await msal.authProvider.getIdToken();

	Date.prototype.toJSON = function () {
		return moment(this).format();
	};
	let obj = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
		body: JSON.stringify(item),
	};
	return fetch(baseURL, obj).then((response: any) => RespuestaAdd(response));
}

async function UpdateAsync<T>(baseUrl: string, item: T): Promise<number> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Deed}/Update`;

	const token = await msal.authProvider.getIdToken();

	Date.prototype.toJSON = function () {
		return moment(this).format();
	};
	let obj = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
		body: JSON.stringify(item),
	};

	return fetch(baseURL, obj).then((response: any) => RespuestaUpdate(response));
}
function RespuestaAdd(response: any) {
	if (response.status === 404) {
		return -404;
	} else {
		return response.json();
	}
}
function RespuestaUpdate(response: any) {
	if (response.status === 404) {
		return response.status;
	} else {
		return response.json();
	}
}

function GetQueryString(filter: DeedFilter): string {
	return GetOrderByQueryString(filter) + GetFilterQueryString(filter);
}

function GetOrderByQueryString(filter: DeedFilter): string {
	let queryString: string = '';

	switch (filter.CurrentFilter) {
		case ActiveDeedFilter.DocumentDate:
			queryString += '&DocumentdDateOrder=' + DecideSortingOrder(filter.DocumentDateOrder);
			break;
		case ActiveDeedFilter.Inscription:
			queryString += '&InscriptionOrder=' + DecideSortingOrder(filter.InscriptionOrder);
			break;
		case ActiveDeedFilter.protocol:
			queryString += '&ProtocolNumberOrder=' + DecideSortingOrder(filter.ProtocolOrder);
			break;
		case ActiveDeedFilter.Notary:
			queryString += '&NotaryNameOrder=' + DecideSortingOrder(filter.NotaryOrder);
			break;
		case ActiveDeedFilter.TypeSpanish:
			queryString += '&DeedTypeNameSpanishOrder=' + DecideSortingOrder(filter.DeedTypeOrder);
			break;
		case ActiveDeedFilter.TypeEnglish:
			queryString += '&DeedTypeNameEnglishOrder=' + DecideSortingOrder(filter.DeedTypeOrder);
			break;
		case ActiveDeedFilter.SubtypeSpanish:
			queryString += '&SubtypeDeedNameSpanishOrder=' + DecideSortingOrder(filter.SubtypeDeedNameOrder);
			break;
		case ActiveDeedFilter.SubtypeEnglish:
			queryString += '&SubtypeDeedNameEnglishOrder=' + DecideSortingOrder(filter.SubtypeDeedNameOrder);
			break;
		case ActiveDeedFilter.Company:
		default:
			queryString += '&CompanyNameOrder=' + DecideSortingOrder(filter.CompanyNameOrder);
			break;
	}

	return queryString;
}

function GetFilterQueryString(filter: DeedFilter): string {
	let queryString: string = '';
	if (filter.AffectsCapital != null) {
		queryString += '&AffectsCapital=' + filter.AffectsCapital;
	}

	if (filter.CompaniesId.length > 0) {
		queryString += '&CompaniesId=' + filter.CompaniesId.toString() + '';
	}

	if (filter.DeedSubtypeId) {
		queryString += '&SubtypeDeedId=' + filter.DeedSubtypeId;
	}

	if (filter.DeedTypeId) {
		queryString += '&DeedTypeId=' + filter.DeedTypeId;
	}

	if (filter.TradeId) {
		queryString += '&TradeId=' + filter.TradeId;
	}

	if (filter.DocumentFromDate) {
		queryString += '&FromDocumentDate=' + filter.DocumentFromDate.toDateString();
	}

	if (filter.DocumentToDate) {
		queryString += '&ToDocumentDate=' + filter.DocumentToDate.toDateString();
	}

	if (!utils.isNullOrEmpty(filter.Inscription)) {
		queryString += '&Inscription=' + encodeURIComponent(filter.Inscription);
	}

	if (filter.InscriptionFromDate) {
		queryString += '&FromInscriptionDate=' + filter.InscriptionFromDate.toDateString();
	}

	if (filter.InscriptionToDate) {
		queryString += '&ToInscriptionDate=' + filter.InscriptionToDate.toDateString();
	}

	if (!utils.isNullOrEmpty(filter.NotaryName)) {
		queryString += '&NotaryName=' + encodeURIComponent(filter.NotaryName);
	}

	if (!utils.isNullOrEmpty(filter.UniqueCode)) {
		queryString += '&CodigoUnico=' + encodeURIComponent(filter.UniqueCode);
	}

	if (filter.NotaryPeopleId > 0) {
		queryString += '&NotaryPeopleId=' + filter.NotaryPeopleId;
	}

	if (!utils.isNullOrEmpty(filter.ProtocolNumber)) {
		queryString += '&ProtocolNumber=' + encodeURIComponent(filter.ProtocolNumber);
	}

	if (!utils.isNullOrEmpty(filter.Subject)) {
		queryString += '&Subject=' + encodeURIComponent(filter.Subject);
	}

	if (utils.isNL(onlyNetherlands)) {
		queryString += '&CountryCode=NL';
	}

	if (filter.OriginalId > 0) {
		queryString += '&OriginalId=' + filter.OriginalId;
	}

	if (!utils.isNullOrEmpty(filter.Copy)) {
		queryString += '&Copy=' + encodeURIComponent(filter.Copy);
	}

	if (!utils.isNullOrEmpty(filter.Simple)) {
		queryString += '&Simple=' + encodeURIComponent(filter.Simple);
	}

	if (!utils.isNullOrEmpty(filter.LocationDeed)) {
		queryString += '&LocationDeed=' + encodeURIComponent(filter.LocationDeed);
	}

	return queryString;
}

async function DeleteAsync(baseUrl: string, id: number): Promise<boolean> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Deed}/Delete`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}/${id}`;
	return fetch(url, obj).then((response: any) => response.json());
}

async function DeleteFileSpAsync<T>(baseUrl: string, item: T): Promise<boolean> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Deed}/DeleteSPFile`;

	const token = await msal.authProvider.getIdToken();

	let obj = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
		body: JSON.stringify(item),
	};

	return fetch(baseURL, obj).then((response: any) => response.json());
}

async function GetByIdAsync(id: number): Promise<Deed> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Deed}/GetById`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}/${id}`;
	return fetch(url, obj).then((response: any) => response.json());
}

async function ExportToExcel(filter: DeedFilter): Promise<any> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Deed}/ExportToExcel`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = GetFilterQueryString(filter);

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) => utils.downloadBlob(response, utils.getExcelNames('deeds')));
}

export const DeedApi = {
	GetByIdAsync,
	AddAsync,
	UpdateAsync,
	FilterAsync,
	DeleteAsync,
	DeleteFileSpAsync,
	ExportToExcel,
};
