import { DatePicker, DayOfWeek, IDatePickerStrings, Icon, MessageBarType } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IComboBoxOption } from 'office-ui-fabric-react/lib/components/ComboBox';
import { ComboBox } from 'office-ui-fabric-react/lib/components/ComboBox/ComboBox';
import PropTypes from 'prop-types';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { RouteComponentProps } from 'react-router';
import { Constants } from '../../../common/const/constants';
import { languages } from '../../../common/const/resources';
import { CustomSelect, ISelectOption } from '../../../common/customSelect/customSelect';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { SearchPeople } from '../../../common/search/searchPeople';
import { SpinnerContainer } from '../../../common/spinner';
import {
	BaseName,
	BaseType,
	DocumentDetail,
	DocumentFile,
	DocumentTypeSubType,
	KeyValue,
	Language,
	SearchSelectModel,
	TradeCompany,
	User,
	createEmptyDocumentDetail,
} from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import * as utils from '../../../utils/extensions';

export interface IProps {
	user: User;
	language: Language;
	business: BaseType[];
	companies: TradeCompany[];
	documentTypes: DocumentType[];
	documentSubtypes: DocumentTypeSubType[];
	countries: BaseName[];
	originals: BaseType[];
	document: DocumentDetail;

	getCompanies: (tradeId?: number) => Promise<TradeCompany[]>;
	getBusiness: () => Promise<BaseType[]>;
	getDocumentTypePrimaries: () => Promise<DocumentType[]>;
	getDocumentTypeSubTypes: () => Promise<DocumentTypeSubType[]>;
	getCountries: () => Promise<BaseName[]>;
	getOriginals: () => Promise<BaseType[]>;
	saveDocument: (document: DocumentDetail, language: Language) => void;
	getDocument: (id: number) => void;
}

export interface IState {
	document: DocumentDetail;
	files: File[];
	initialFiles: DocumentFile[];
	errorMessages: string[];
	isCountryDisabled: boolean;
	uniqueCode: SearchSelectModel;
	uniqueCodes: KeyValue[];
	hasMaxError: boolean;
	isReadOnly: boolean;
	tradeId: number;
	showDissolved: boolean;
}

export class DocumentationForm extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props: any) {
		super(props);

		this.state = {
			document: createEmptyDocumentDetail(),
			files: [],
			initialFiles: [],
			errorMessages: [],
			uniqueCode: null,
			uniqueCodes: [],
			isCountryDisabled: false,
			hasMaxError: false,
			isReadOnly: false,
			tradeId: 0,
			showDissolved: false,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		if(window.location.href.includes("new")){
			this.setState(({showDissolved: false}))
		}
		if (this.props.match.params['id'] != null) {
			if (this.props.match.path.includes('view')) {
				this.setState({ isReadOnly: true });
			}
			this.props.getDocument(this.props.match.params['id']);
		}

		this.props.getCompanies();
		this.props.getDocumentTypePrimaries();
		this.props.getDocumentTypeSubTypes();
		this.props.getCountries();
		this.props.getOriginals();
		this.props.getBusiness();
	}

	public componentWillReceiveProps(props) {
		let uniqueCode = this.state.uniqueCode;
		if (props.document !== this.props.document) {
			let company: TradeCompany = this.props.companies.find((x) => x.id === parseInt(props.document.companyId));
			if (company) {
				uniqueCode = { label: company.interlocutor, value: company.id };
			}
			let document = props.document;
			document.amount = document.amount ? document.amount : '';
			this.setState({
				document: document,
				uniqueCode: uniqueCode,
				tradeId: company?.tradeId
			});
		}

		if (props.document && props.document !== this.props.document) {
			let initialDocuments = props.document.documentsSp ? props.document.documentsSp.slice() : [];
			this.setState({ document: props.document, initialFiles: initialDocuments });
		}
	}

	public componentDidUpdate(prevProps) {
		if (this.props.countries !== prevProps.countries) {
			if (this.props.countries.length === 1) {
				let netherlands = this.props.countries.find((x) => x.descriptionEnglish === 'Netherlands')?.id;
				if (netherlands !== undefined) {
					let document = { ...this.state.document };
					document.countryId = netherlands;
					this.setState({ document: document, isCountryDisabled: true });
				}
			}
		}

		if (this.props.companies && this.props.companies.length > 0 && prevProps.companies !== this.props.companies) {
			let uniqueCode = this.state.uniqueCode;
			let uniqueCodes: KeyValue[] = this.props.companies.map((company: TradeCompany) => {
				return { Key: company.id, Value: company.interlocutor };
			});

			if (this.props.document?.companyId && this.props.match.params['id'] && !uniqueCode) {
				let company: TradeCompany = this.props.companies.find((x) => x.id === parseInt(this.props.document.companyId));
				if (company) {
					uniqueCode = { label: company.interlocutor, value: company.id };
				}
			}

			this.setState({
				uniqueCodes,
				uniqueCode,
			});
		}
	}

	private readonly loadCodeOptions = (inputValue, callback) => {
		if (inputValue.length >= 2) {
			setTimeout(() => {
				callback(this.filterCodes(inputValue));
			}, 1000);
		}
	};

	private readonly filterCodes = (inputValue: string): SearchSelectModel[] => {
		let options = this.state.uniqueCodes.filter((i: KeyValue) => i.Value.toLowerCase().includes(inputValue.toLowerCase()));
		return options.map((option) => {
			return { value: option.Key, label: option.Value };
		});
	};

	private readonly getSelectedCompany = () : ISelectOption[] => {

		let selectedCompanies: ISelectOption[] = [];
		if (this.props.companies && this.props.companies.length > 0 && this.state.document.companyId){
			let company: TradeCompany = this.props.companies.find((x) => x.id === this.state.document.companyId);
			if(company){
				selectedCompanies.push( {value:company.id,label:company.name, disolutionDate: null, liquidationDate: null});
			}
		}

		return selectedCompanies;
	}	

	public render() {

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let calendarStrings: IDatePickerStrings = language['calendar'];

		// let companiesCombo: IComboBoxOption[] = [];
		// this.props.companies.forEach((item: TradeCompany) => {
		// 	companiesCombo.push({ key: item.id, text: item.name });
		// });

		let companiesCombo: IComboBoxOption[] = [];
		if(this.props.companies){
			this.props.companies.forEach((item) => {
				companiesCombo.push({ 
					key: item.id, 
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null, });
			});			
		}		

		let docTypes: IComboBoxOption[] = [];
		this.props.documentTypes.forEach((item) => {
			docTypes.push({
				key: item.id,
				text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
			});
		});

		let docSubtypes: IComboBoxOption[] = [];
		let docType: DocumentTypeSubType = this.props.documentSubtypes.find((i) => i.id === this.state.document.documentationTypeId);
		docType?.listSubTypes?.forEach((item) => {
			docSubtypes.push({
				key: item.id,
				text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
			});
		});

		let countries: IComboBoxOption[] = [];
		if (this.props.countries) {
			this.props.countries.forEach((item) => {
				countries.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}

		let originals: IComboBoxOption[] = [];
		if (this.props.originals) {
			this.props.originals.forEach((item) => {
				originals.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}

		let business: IComboBoxOption[] = [];
		if (this.props.business) {
			this.props.business.forEach((item) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}

		let documents: DocumentFile[] = this.state.initialFiles ? this.state.initialFiles : [];
		let isReadOnly = this.state.isReadOnly;
		let title = isReadOnly ? language['documentation']['form']['text-view'] : language['deeds']['form']['text-edit'];

		let validationErrors = [];
		this.state.errorMessages.forEach((item) => {
			validationErrors.push(<MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />);
		});

		// const onRenderOption = (option: any): JSX.Element => {
		// 	if (option.itemType == DropdownMenuItemType.Header) {
		// 		if(option.key == 0){
		// 			return (
		// 				<Toggle 
		// 				inlineLabel 
		// 				label={language['SocietiesCombo']['showDissolved']} 
		// 				checked = {this.state.showDissolved}
		// 				onChange={(event, checked)=>{this.setState({showDissolved: checked})}} />
		// 			);
		// 		}
		// 		else{	
		// 			return (
		// 				<table>
		// 					<tbody>
		// 						<tr>
		// 							<td style={{ width: 15, fontSize: 14, padding: 5 }}></td>
		// 							<td style={{ width: 200, fontSize: 14, padding: 0 }}>{option.key}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: 0 }}>{option.text}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: '0px 10px' }}>{option.title}</td>

		// 						</tr>
		// 					</tbody>
		// 				</table>
		// 			);
		// 		}
		// 	} else {
		// 		return (
		// 			<table>
		// 				<tbody>
		// 					<tr>
		// 						<td style={{ width: 200, fontSize: 14, padding: 5 }}>{option.text}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.title}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.id}</td>
		// 					</tr>
		// 				</tbody>
		// 			</table>
		// 		);
		// 	}
		// };

		let sortedCompanies = companiesCombo.sort((a, b) => a.text.localeCompare(b.text))
		// sortedCompanies.unshift({ key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'], itemType: DropdownMenuItemType.Header });
		// sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });		

		let filteredCompanies= (this.state.showDissolved) ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title === language['SocietiesCombo']['dissolutionDate'])
		let options: ISelectOption[] = filteredCompanies.map(company => {
			return {
				value:company.key.toString(),
				label:company.text, 
				disolutionDate:company.id, 
				liquidationDate: company.title
			};
		  });	
		  let selectedCompanies=this.getSelectedCompany();			

		return (
			<>
				<SpinnerContainer message='Cargando...' />
				<div className='main-container__pageTitle'>
					<h1>
						{this.props.match.params['id'] == null
							? language['documentation']['form']['text-add'] : title}
						{language['documentation']['form']['title']}
					</h1>
				</div>
				<div className='ms-Grid'>
					<div className='ms-Grid-row flex-option new_deed'>
						<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
							<p>{language['documentation']['form']['fields']['trade']['title']}</p>
							<ComboBox
								allowFreeform
								autoComplete='on'
								options={business.sort((a, b) => a.text.localeCompare(b.text))}
								placeholder={language['documentation']['list']['filter']['options']['trade']['placeholder']}
								onChange={this.handleTradeChange}
								selectedKey={this.state.tradeId}
								useComboBoxAsMenuWidth
								disabled={isReadOnly}
							/>
							<p>{language['documentation']['form']['fields']['society']['title']}</p>
							{/* <ComboBox
								autoComplete='on'
								allowFreeform={true}
								options={this.state.showDissolved ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title == language['SocietiesCombo']['dissolutionDate'])}
								placeholder={language['documentation']['form']['fields']['society']['placeholder']}
								onChange={this.handleCompanyChange}
								onRenderOption={onRenderOption}
								selectedKey={this.state.document.companyId}
								disabled={this.props.match.params['id'] != null && isReadOnly}
							/> */}
							<CustomSelect
								options={options}
								handleCustomSelectChange={this.handleReactSelectCompanyChange}
								showDissolved={this.state.showDissolved}
								handleShowDissolvedChange={(event, checked)=>{this.setState({showDissolved: checked})}}
								language={language}
								showDissolutionDate={false}
								selectedValues={selectedCompanies}
								isMultiSelection={false}
								isDisabled={this.props.match.params['id'] != null && isReadOnly}
								placeholder={language['documentation']['form']['fields']['society']['placeholder']}
							/>							
							<p>{language['documentation']['form']['fields']['uniqueCode']['title']}</p>
							<SearchPeople
								title={''}
								placeholder={language['representatives']['form']['fields']['uniqueCode']['placeholder']}
								language={this.props.language}
								selectedOption={this.state.uniqueCode}
								loadOptions={this.loadCodeOptions}
								handleNameChange={this.handleUniqueCodeChange}
								getUsers={this.props.getPeopleNames}
								disabled={this.props.match.params['id'] != null && isReadOnly}
								onClearValue={this.onClearSearchPeople}
							/>
							<p>{language['documentation']['form']['fields']['type']['title']}</p>
							<ComboBox
								allowFreeform
								autoComplete='on'
								options={docTypes.sort((a, b) => a.text.localeCompare(b.text))}
								placeholder={language['documentation']['form']['fields']['type']['placeholder']}
								onChange={this.handleDocumentTypeChange}
								selectedKey={this.state.document.documentationTypeId}
								useComboBoxAsMenuWidth
								disabled={isReadOnly}
							/>
							<p>{language['documentation']['form']['fields']['subType']['title']}</p>
							<ComboBox
								allowFreeform
								autoComplete='on'
								options={docSubtypes.sort((a, b) => a.text.localeCompare(b.text))}
								placeholder={language['documentation']['form']['fields']['subType']['placeholder']}
								onChange={this.handleSubDocumentTypeChange}
								selectedKey={this.state.document.documentationSubTypeId}
								useComboBoxAsMenuWidth
								disabled={isReadOnly}
							/>
						</div>
						<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
							<p>{language['documentation']['form']['fields']['date']['title']}</p>
							<DatePicker
								formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
								className={''}
								firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
								allowTextInput={true}
								parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
								placeholder={language['generic']['date']}
								strings={calendarStrings}
								value={utils.getParsedDate(this.state.document.date)}
								onSelectDate={this.handleDateChange}
								disabled={isReadOnly}
							/>
							<p>{language['documentation']['form']['fields']['project']['title']}</p>
							<TextField
								type='text'
								value={this.state.document.project}
								onChange={this.handleProjectChange}
								onBlur={this.handleProjectBlur}
								disabled={isReadOnly}
								maxLength={255}
							/>
							<p>{language['documentation']['form']['fields']['country']['title']}</p>
							<ComboBox
								allowFreeform
								autoComplete='on'
								options={countries.sort((a, b) => a.text.localeCompare(b.text))}
								placeholder={language['documentation']['form']['fields']['country']['placeholder']}
								onChange={this.handleCountryChange}
								selectedKey={this.state.document.countryId}
								useComboBoxAsMenuWidth
								disabled={this.state.isCountryDisabled || isReadOnly}
							/>
							<p>{language['documentation']['form']['fields']['remark']['title']}</p>
							<TextField
								type='text'
								value={this.state.document.remark}
								onChange={this.handleRemarkChange}
								onBlur={this.handleRemarkBlur}
								disabled={isReadOnly}
								maxLength={255}
							/>
							<p>{language['documentation']['form']['fields']['location']['title']}</p>
							<TextField
								type='text'
								value={this.state.document.location}
								onChange={this.handleLocationChange}
								onBlur={this.handleLocationBlur}
								disabled={isReadOnly}
								maxLength={255}
							/>
						</div>
						<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
						    <p>{language['documentation']['form']['fields']['original']['title']}</p>
							<ComboBox
								allowFreeform
								autoComplete='on'
								options={originals.sort((a, b) => a.text.localeCompare(b.text))}
								placeholder={language['documentation']['form']['fields']['original']['placeholder']}
								onChange={this.handleOriginalTypeChange}
								selectedKey={this.state.document.originalId}
								useComboBoxAsMenuWidth
								disabled={isReadOnly}
							/>
							<p>{language['documentation']['form']['fields']['copy']['title']}</p>
							<TextField
								type='text'
								value={this.state.document.copy}
								onChange={this.handleCopyChange}
								onBlur={this.handleCopyBlur}
								disabled={isReadOnly}
								maxLength={255}
							/>
							<p>{language['documentation']['form']['fields']['simple']['title']}</p>
							<TextField
								value={this.state.document.simple}
								onChange={this.handleSimpleChange}
								disabled={isReadOnly}
								maxLength={250}
							/>
							<p>{language['documentation']['form']['fields']['parties']['title']}</p>
							<TextField
								type='text'
								value={this.state.document.parties}
								onChange={this.handlePartiesChange}
								onBlur={this.handlePartiesBlur}
								disabled={isReadOnly}
								maxLength={255}
							/>
							<p>{language['documentation']['form']['fields']['amount']['title']}</p>
							<TextField
								type='text'
								value={this.state.document.amount}
								onChange={this.handleAmountChange}
								disabled={isReadOnly}
								maxLength={20}
							/>
							<p>{language['documentation']['form']['fields']['comments']['title']}</p>
							<TextField
								value={this.state.document.comments}
								onChange={this.handleCommentsChange}
								disabled={isReadOnly}
								maxLength={500}
								multiline 
							/>
						</div>
						<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
							<span style={{ display: isReadOnly ? 'none' : '' }}>
								<p className='subtitle'>{language['documentation']['form']['documentation']}</p>
								<p>{language['documentation']['form']['fields']['documents']['title']}</p>
								<Dropzone className='dropzone' onDrop={this.onDrop}>
									<i className='ms-Icon ms-Icon--CloudUpload' aria-hidden='true'></i>
									<p className='dropzone-title'>{language['generic']['form']['dropzone']['title']}</p>
									<p className='dropzone-maxsize'>{language['generic']['form']['dropzone']['max-size']}</p>
									<span className='custom-file-upload'>
										<input type='file' onChange={this.uploadFile} />
										<Icon iconName='CloudUpload'></Icon> {language['generic']['form']['uploadDocument']['title']}
									</span>
								</Dropzone>
							</span>
							<ul className='ms-Grid-col ul-documents'>
								{this.state.hasMaxError ? (
									<MessageBarComponent
										ClassName='fixMsgMaxError'
										Message={language['generic']['form']['dropzone']['max-size']}
										Type={MessageBarType.error}
										MessageVisible={true}
										close={null}
									/>
								) : (
									this.state.files.map((f) => (
										<li key={f.name}>
											{f.name}
											<span className='delete-files-icon' onClick={() => this.handleDeselect(f)}>
												&times;
											</span>
										</li>
									))
								)}
								{documents.map((item) => (
									<li key={item.fileName}>
										{/* <a
											href={process.env.REACT_APP_SHAREPOINT + item.documentLink}
											target='_blank'
											rel='noopener noreferrer'
										>
											{item.fileName}
										</a> */}
										<span
											/*onClick={() => window.open(process.env.REACT_APP_SHAREPOINT + item.documentLink, "_blank")}*/
											onClick={() => {
												const urltemp = process.env.REACT_APP_SHAREPOINT + item.documentLink;
												const url = urltemp.replace(/ /g, "%20");
												if (utils.isURLValid(url)){
													window.open(url, "_blank")}
													//else{console.log(url)}
															}
													}
											className='url-style'
										>
											{item.fileName}
										</span>
										<span
											className='delete-files-icon'
											onClick={() => this.handleDeleteFileSp(item)}
											style={{ display: isReadOnly ? 'none' : '' }}
										>
											&times;
										</span>
									</li>
								))}
							</ul>
						</div>
					</div>
					{validationErrors}
					<hr />
					<div className='ms-Grid-row'>
						<button
							type='button'
							name='button'
							className='button-primary'
							style={{ display: isReadOnly ? 'none' : '' }}
							onClick={this.handleSaveDocument}
						>
							{language['documentation']['form']['save-button']}
						</button>
						<button onClick={utils.navigateBack} type='button' name='back' className='button-cancel'>
							{language['documentation']['form']['cancel-button']}
						</button>
					</div>
				</div>
			</>
		);
	}

	private readonly onClearSearchPeople = (): void => {
		let uniqueCode = { ...this.state.uniqueCode };
		uniqueCode.value = null;
		uniqueCode.label = null;
		this.setState({ uniqueCode: uniqueCode });
	};

	// private readonly handleCompanyChange = (event, option): void => {
	// 	let document = { ...this.state.document };
	// 	document.companyId = option?.key as number;
	// 	let company = this.props.companies.find((x: TradeCompany) => x.id === (option?.key as number));
	// 	if(company !== undefined){
	// 		let tradeId = company.tradeId;
	// 		this.setState({
	// 			document: document,
	// 			uniqueCode: company ? { value: company.id, label: company.interlocutor } : null,
	// 			tradeId: tradeId
	// 		});
	// 	}	
	// };

	private readonly handleReactSelectCompanyChange = (selectedOption) => {

		let document = { ...this.state.document };
		let company: TradeCompany = null;

		if(selectedOption!==null){
			//TODO: confirmar por qué el operador estricto no consigo que funcione
			//		Comparar como está diseñado en "Nuevo Poder" - Función declarada en el propio fichero ""......\search.tsx"
			company = this.props.companies.find((x: TradeCompany) => x.id == (selectedOption?.value as number));
		}	

		document.companyId = company?.id;
		let tradeId = company?.tradeId;
		this.setState({
			document: document,
			uniqueCode: company ? { value: company.id, label: company.interlocutor } : null,
			tradeId: tradeId
		});
		
	}	

	public readonly handleTradeChange = (event, value): void => {
		let document = { ...this.state.document };
		document.companyId = 0
		let tradeId = value?.key;
		this.props.getCompanies(tradeId);
		this.setState({ tradeId: tradeId, document: document, uniqueCode: null })
	}

	private readonly handleUniqueCodeChange = (uniqueCode: SearchSelectModel) => {
		let document = { ...this.state.document };
		document.companyId = +uniqueCode.value;
		let company = this.props.companies.find((x: TradeCompany) => x.id === document.companyId);
		this.setState({ document: document, uniqueCode: uniqueCode,tradeId: company.tradeId });
	};

	private readonly handleDocumentTypeChange = (event, option): void => {
		let document = { ...this.state.document };
		document.documentationTypeId = parseInt(option?.key);
		document.documentationSubTypeId = document.documentationTypeId === 0 ? 0 : null;
		this.setState({ document: document });
	};

	private readonly handleSubDocumentTypeChange = (event, option): void => {
		let document = { ...this.state.document };
		document.documentationSubTypeId = parseInt(option?.key);
		this.setState({ document: document });
	};

	public readonly handleDateChange = (value): void => {
		let document = { ...this.state.document };
		document.date = value;
		this.setState({ document: document });
	};

	private readonly handleProjectChange = (value): void => {
		let document = { ...this.state.document };
		document.project = value.target.value;
		this.setState({ document: document });
	};
	private readonly handleProjectBlur = (value): void => {
		let document = { ...this.state.document };
		document.project = value.target.value.toUpperCase();
		this.setState({ document: document });
	};

	private readonly handleCountryChange = (event, option): void => {
		let document = { ...this.state.document };
		document.countryId = parseInt(option?.key);
		this.setState({ document: document });
	};

	private readonly handleRemarkChange = (value): void => {
		let document = { ...this.state.document };
		document.remark = value.target.value;
		this.setState({ document: document });
	};
	private readonly handleRemarkBlur = (value): void => {
		let document = { ...this.state.document };
		document.remark = value.target.value.toUpperCase();
		this.setState({ document: document });
	};

	private readonly handleOriginalTypeChange = (event, option): void => {
		let document = { ...this.state.document };
		document.originalId = parseInt(option?.key);
		this.setState({ document: document });
	};

	private readonly handleCopyChange = (value): void => {
		let document = { ...this.state.document };
		document.copy = value.target.value;
		this.setState({ document: document });
	};
	private readonly handleCopyBlur = (value): void => {
		let document = { ...this.state.document };
		document.copy = value.target.value.toUpperCase();
		this.setState({ document: document });
	};

	private readonly handleLocationChange = (value): void => {
		let document = { ...this.state.document };
		document.location = value.target.value;
		this.setState({ document: document });
	};
	private readonly handleLocationBlur = (value): void => {
		let document = { ...this.state.document };
		document.location = value.target.value.toUpperCase();
		this.setState({ document: document });
	};

	private readonly handlePartiesChange = (value): void => {
		let document = { ...this.state.document };
		document.parties = value.target.value;
		this.setState({ document: document });
	};
	private readonly handlePartiesBlur = (value): void => {
		let document = { ...this.state.document };
		document.parties = value.target.value.toUpperCase();
		this.setState({ document: document });
	};
	private readonly handleSimpleChange = (value): void => {
		let document = { ...this.state.document };
		document.simple = value.target.value;
		this.setState({ document: document });
	};
	private readonly handleCommentsChange = (value): void => {
		let document = { ...this.state.document };
		document.comments = value.target.value;
		this.setState({ document: document });
	};
	private readonly handleAmountChange = (value): void => {
		let document = { ...this.state.document };
		let amount = value.target.value;
		var regexAmount = /^[1-9]\d*(,)?(\d{1,2})?$/;
		if(amount != null && amount.length > 0){
			document.amount = regexAmount.test(amount) ? amount.toString() : document.amount;
		} else {
			document.amount = "";
		}

		this.setState({ document: document });
	};

	public readonly onDrop = (files: File[]) => {
		let documentsFiles: DocumentFile[] = [];
		let hasMaxError: boolean = false;

		files.forEach((file) => {
			if (file.size > Constants.MinSizeFile && file.size < Constants.MaxSizeFile) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				let document: DocumentFile = {
					fileName: file.name,
					contents: '',
					deedMetadata: null,
					recordMetadata: null,
					corporateLitigationMetadata: null,
					documentationMetadata: null,
					powerMetadata: null
				};
				reader.onloadend = function () {
					document.contents = (reader.result as string).split(',').pop();
				};
				documentsFiles.push(document);
			} else {
				hasMaxError = true;
			}
		});

		let state = { ...this.state };
		if (state.document.documentsSp !== null && !hasMaxError) {
			state.document.documentsSp = state.document.documentsSp.concat(documentsFiles);
		}
		if (state.files != null && !hasMaxError) {
			state.files = state.files.concat(files);
			this.setState(state);
		}
		this.setState({ hasMaxError });
	};

	public readonly uploadFile = (event: any) => {
		let hasMaxError: boolean = false;
		let documentsFiles: DocumentFile[] = [];
		let file = event.target.files[0];

		if (file != null && file.size > Constants.MinSizeFile && file.size < Constants.MaxSizeFile) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			let document: DocumentFile = {
				fileName: file.name,
				contents: '',
				deedMetadata: null,
				recordMetadata: null,
				corporateLitigationMetadata: null,
				documentationMetadata: null,
			};
			reader.onloadend = function () {
				document.contents = (reader.result as string).split(',').pop();
			};
			documentsFiles.push(document);
		} else {
			hasMaxError = true;
		}

		let state = { ...this.state };
		if (state.document.documentsSp !== null && !hasMaxError) {
			state.document.documentsSp = state.document.documentsSp.concat(documentsFiles);
		}
		if (state.files != null && !hasMaxError) {
			state.files = state.files.concat(file);
			this.setState(state);
		}
		this.setState({ hasMaxError });
	};

	public readonly handleDeselect = (file: File) => {
		let state = { ...this.state };
		let fileIndex = state.files.findIndex((item) => item === file);
		state.files.splice(fileIndex, 1);

		let documentIndex = state.document.documentsSp.findIndex((item) => item.fileName === file.name);
		state.document.documentsSp.splice(documentIndex, 1);
		this.setState(state);
	};

	public readonly handleDeleteFileSp = (file: DocumentFile): void => {
		let state = { ...this.state };
		let fileIndex = state.initialFiles.findIndex((item) => item === file);
		state.initialFiles.splice(fileIndex, 1);

		let fileIndex2 = state.document.documentsSp.findIndex((item) => item === file);
		state.document.documentsSp.splice(fileIndex2, 1);

		state.document.deleteSPFiles.push(file.documentLink);
		this.setState(state);
	};

	private readonly handleSaveDocument = (): void => {
		let document = { ...this.state.document };

		if (this.validateDocument(document)) {
			this.props.saveDocument(document, this.props.language);
		}
	};

	private readonly validateDocument = (document: DocumentDetail): boolean => {
		let validationMessages = []
		let language = utils.getResourcesFile(this.props.language);
		let isValidCompany = document.companyId !== null && document.companyId > 0;
		let isValidDocumentType = document.documentationTypeId !== null && document.documentationTypeId > 0;
		//let isValidDocumentSubtype = document.documentationSubTypeId !== null && document.documentationSubTypeId > 0;
		let isValidDocumentDate = document.date !== null;
		//let isValidDocuments = document.documentsSp?.length > 0 || this.state.files.length > 0;

		//let isDocumentationValid = isValidCompany && isValidDocumentType && isValidDocumentSubtype && isValidDocumentDate && isValidDocuments;
		let isDocumentationValid = isValidCompany && isValidDocumentType && isValidDocumentDate;

		if (!isValidCompany) {
			validationMessages.push(language['generic']['messages']['required-society']);
		}

		if (!isValidDocumentType) {
			validationMessages.push(language['generic']['messages']['required-documentType']);
		}

		/*if (!isValidDocumentSubtype) {
			validationMessages.push(language['generic']['messages']['required-documentSubtype']);
		}*/

		if (!isValidDocumentDate) {
			validationMessages.push(language['generic']['messages']['required-document-date']);
		}

		/*if (!isValidDocuments) {
			validationMessages.push(language['generic']['messages']['required-document']);
		}*/

		if (!isDocumentationValid) {
			this.setState({ errorMessages: validationMessages });
		}

		return isDocumentationValid;
	}
}
