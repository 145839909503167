import { Icon } from 'office-ui-fabric-react/lib/Icon';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../../../common/const/constants';
import { languages } from '../../../common/const/resources';
import { allMasterModule } from '../../../common/const/rolesAssignments';
import { Language } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import * as utils from '../../../utils/extensions';

export interface IProps {
	language: Language;
}

export class MasterManagementComponent extends React.Component<IProps, {}> {
	static contextTypes = {
		router: PropTypes.object,
	};

	/* constructor(props: any) {
		super(props);
	}		Clean Warning Code */

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let showMasters: boolean = utils.isInRoles(allMasterModule);

		return (
			<>
				{showMasters && (
					<div className='ms-Grid-row main-container__block'>
						<div>
							<div className='ms-Grid-row'>
								<div className='ms-Grid-col ms-lg10'>
									<h1 className='main-container__block-title'>{language['home']['masters']['title']}</h1>
								</div>
							</div>
							<div className='main-container__block-section'>
								<div className='main-container__block-section-content'>
									<Link to='/people/page/1' className='main-container__block-section-content-tile'>
										<div className='main-container__block-section-content-tile-icon'>
											<Icon iconName='People' className={classNames.homeIconPrimary} />
										</div>
										<div className='main-container__block-section-content-tile-text'>
											<span>{language['home']['masters']['options']['people']}</span>
										</div>
									</Link>
									<Link to='/companies/page/1' className='main-container__block-section-content-tile'>
										<div className='main-container__block-section-content-tile-icon'>
											<Icon iconName='Teamwork' className={classNames.homeIconPrimary} />
										</div>
										<div className='main-container__block-section-content-tile-text'>
											<span>{language['home']['masters']['options']['companies']}</span>
										</div>
									</Link>
									<Link to='/departments/page/1' className='main-container__block-section-content-tile'>
										<div className='main-container__block-section-content-tile-icon'>
											<Icon iconName='Work' className={classNames.homeIconPrimary} />
										</div>
										<div className='main-container__block-section-content-tile-text'>
											<span>{language['home']['masters']['options']['departments']}</span>
										</div>
									</Link>
									<Link to='/documentTypes/page/1' className='main-container__block-section-content-tile'>
										<div className='main-container__block-section-content-tile-icon'>
											<Icon iconName='TextDocument' className={classNames.homeIconPrimary} />
										</div>
										<div className='main-container__block-section-content-tile-text'>
											<span>{language['home']['masters']['options']['documents']}</span>
										</div>
									</Link>
									<Link to='/deedTypes/page/1' className='main-container__block-section-content-tile'>
										<div className='main-container__block-section-content-tile-icon'>
											<Icon iconName='DocumentSet' className={classNames.homeIconPrimary} />
										</div>
										<div className='main-container__block-section-content-tile-text'>
											<span>{language['home']['masters']['options']['deed-types']}</span>
										</div>
									</Link>
									<Link to='/positionTypes/page/1' className='main-container__block-section-content-tile'>
										<div className='main-container__block-section-content-tile-icon'>
											<Icon iconName='PartyLeader' className={classNames.homeIconPrimary} />
										</div>
										<div className='main-container__block-section-content-tile-text'>
											<span>{language['home']['masters']['options']['position-types']}</span>
										</div>
									</Link>
									<Link to='/bodyTypes/page/1' className='main-container__block-section-content-tile'>
										<div className='main-container__block-section-content-tile-icon'>
											<Icon iconName='PartyLeader' className={classNames.homeIconPrimary} />
										</div>
										<div className='main-container__block-section-content-tile-text'>
											<span>{language['home']['masters']['options']['body-types']}</span>
										</div>
									</Link>
									<Link to='/notaryPeople/page/1' className='main-container__block-section-content-tile'>
										<div className='main-container__block-section-content-tile-icon'>
											<Icon iconName='PartyLeader' className={classNames.homeIconPrimary} />
										</div>
										<div className='main-container__block-section-content-tile-text'>
											<span>{language['home']['masters']['options']['notaryPeople']}</span>
										</div>
									</Link>									
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}

	// private readonly getMenuButton = (language) => {
	// 	return (
	// 		<DefaultButton
	// 			text={language['home']['new-button']}
	// 			className='main-container_button ms-lg12'
	// 			menuProps={{
	// 				shouldFocusOnMount: true,
	// 				items: [
	// 					{
	// 						key: 'Persona',
	// 						onClick: () => {
	// 							this.context.router.history.push('/people/new');
	// 						},
	// 						iconProps: {
	// 							iconName: 'ReminderPerson',
	// 							className: 'context-menu__icon',
	// 						},
	// 						name: language['home']['masters']['new-section']['person'],
	// 					},
	// 					{
	// 						key: 'Tipo de Escritura',
	// 						onClick: () => {
	// 							this.context.router.history.push('/deedTypes/new');
	// 						},
	// 						iconProps: {
	// 							iconName: 'Folder',
	// 							className: 'context-menu__icon',
	// 						},
	// 						name: language['home']['masters']['new-section']['deed-type'],
	// 					},
	// 				],
	// 			}}
	// 		/>
	// 	);
	// };
}
