export interface DeedType {
	affectsCapital: boolean;
	id: number;
	descriptionSpanish: string;
	descriptionEnglish: string;
	fatherId?: number;
	fatherDescriptionSpanish: string;
	fatherDescriptionEnglish: string;	
}

export interface DeedTypeCollection {
	list: DeedType[];
	totalNumberOfRows: number;
}
export interface DeedTypeSubType { 
	id: number;
	descriptionSpanish: string;
	descriptionEnglish: string;
	listSubTypes: DeedType[];
}
export const createEmptyDeedType = (): DeedType => {
	let deedType: DeedType = {
		id: 0,
		affectsCapital: true,
		descriptionEnglish: '',
		descriptionSpanish: '',
		fatherId: null,
		fatherDescriptionEnglish: '',
		fatherDescriptionSpanish: '',		
	};

	return deedType;
};
export const createEmptyDeedTypeCollection = (): DeedTypeCollection => {
	return {
		list: [createEmptyDeedType()],
		totalNumberOfRows: 0,
	};
};
export const createEmptyDeedTypeSubType = (): DeedTypeSubType => {
	let deedTypeSubType: DeedTypeSubType = {
		id: 0,
		descriptionEnglish: '',
		descriptionSpanish: '',
		listSubTypes: [createEmptyDeedType()],
	};

	return deedTypeSubType;
};
