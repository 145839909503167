
import { DocumentFile } from './Document';

export interface Representative {
	id: number;
	representativeName: string;
	dni: string;
	companyName: string;
	companyId: number;
	representativeId: number;
	protocol: string;
	inscription: string;
	powerType: number;
	powerTypeNameSpanish: string;
	powerTypeNameEnglish: string;
	type: string;
	state: string;
	documentUrl: string;
	powerId?: number;
	tradeId: number;
	representativeTradeId: number;
	subject: string;
	peopleId: number;
	legalCompanyId?:number;
	legalCompanyName:string;
	documentDate:string;
	protocolRevokeAll: string;
}

export interface RepresentativeDetail {
	id: number;
	name: string;
	dni: string;
	nationality: string;
	companyId: number;
	companyName: string;
	inscription: string;
	descriptionEnglish: string;
	descriptionSpanish: string;
	notary: string;
	notaryDate: Date;
	assignned: Date;
	revoked: Date;
	notaryId: number;
	personId?: number;
	legalCompanyId?:number;
	protocol: string;
	state: string;
	powerExcerciseSpanish: string;
	powerExcerciseEnglish: string;
	documentUrl: string;
	type: string;
	powerId: number;
	revokedPower: RepresentativeDetail;
	passport: string;
}

export const createEmptyRepresentativeDetail = (): RepresentativeDetail => {
	let result: RepresentativeDetail = {
		id: 0,
		name: '',
		dni: '',
		companyName: '',
		companyId: 0,
		protocol: '',
		inscription: '',
		state: '',
		assignned: null,
		revoked: null,
		notaryId: 0,
		personId: null,
		legalCompanyId:null,
		descriptionEnglish: '',
		descriptionSpanish: '',
		nationality: '',
		notary: '',
		notaryDate: null,
		powerExcerciseSpanish: '',
		powerExcerciseEnglish: '',
		documentUrl: '',
		type: '',
		powerId: 0,
		revokedPower: null,
		passport: '',
		
	};

	return result;
};

export interface DeedRepresentative {
	id: number;
	state: RepresentativeState;
	personId: number;
	powerRepresentative: PowerRepresentative[];
	powerTypeId: number;
}

export interface RepresentativeCollection {
	list: Representative[];
	totalNumberOfRows: number;
}

export const createEmptyRepresentativeCollection = (): RepresentativeCollection => {
	let result: RepresentativeCollection = {
		list: [],
		totalNumberOfRows: 0,
	};

	return result;
};
export interface RepresentativeDetailCollection {
	list: RepresentativeDetail[];
	totalNumberOfRows: number;
}

export const createEmptyRepresentativeDetailCollection = (): RepresentativeDetailCollection => {
	let result: RepresentativeDetailCollection = {
		list: [],
		totalNumberOfRows: 0,
	};

	return result;
};

export enum RepresentativeState {
	Otorgamiento = 1,
	Revocación = 2,
}

export interface PowerRepresentative {
	id: number;
	powerId: number;
	representativeId: number;
	createdOn: Date;
}

export const createEmptyPowerRepresentative = (): PowerRepresentative => {
	let result: PowerRepresentative = {
		id: 0,
		powerId: 0,
		representativeId: 0,
		createdOn: null,
	};
	return result;
};

export interface powerTypeRepresentativeInsider {
	id: number;
	descriptionSpanish: string;
	descriptionEnglish: string;
}
export interface PowerRepresentativeDetail {
	id: number;
	state: RepresentativeState;
	personId?: number;
	personName?: string;
	legalCompanyId?:number;
	legalCompanyName:string;
	companyId: number;
	companyName?: string;
	powerRepresentative?: PowerRepresentative[];
	powerTypeId: number;
	powerExcerciseId?: number;
	revokeOn: Date;
	departmentId: number;
	documents: DocumentFile[];
	powerToRevokeId?: number;
	//powerType: powerTypeRepresentativeInsider;
}

export const createEmptyPowerRepresentativeDetail = (): PowerRepresentativeDetail => {
	let result: PowerRepresentativeDetail = {
		id: 0,
		state: 0,
		revokeOn: null,
		powerTypeId: 0,
		personName: '',
		powerRepresentative: [createEmptyPowerRepresentative()],
		powerExcerciseId: null,
		personId: null,
		departmentId: null,
		documents: [],
		powerToRevokeId: 0,
		companyId:null,
		companyName:'',
		legalCompanyId: null,
		legalCompanyName:'',
		// powerType: {
		// 	id: 0,
		// 	descriptionSpanish: '',
		// 	descriptionEnglish: ''
		// }
	};

	return result;
};

export interface PowerAttachment {
	// id: number,
	title: string;
	// DocumentName: Date,
	documentUrl: string;
	// CreatedOn: Date,
	// DateTimeOffset: string,
	// CreatedBy: string,
	// CreatedByDisplayName: string,
	// ModifiedOn: Date,
	// ModifiedBy: string,
	// ModifiedByDisplayName: string,
	// PowerId: string,
}
