export interface PowerRevocationFilter {
	personId?: number;
	legalCompanyId?: number;
	powerTypeId: number;
	companyId: number;
	statePower: number;
	isEdit: boolean;
}

export const createEmptyPowerRevocationFilter = (): PowerRevocationFilter => {
	return {
		personId: null,
		legalCompanyId:null,
		powerTypeId: 0,
		companyId: 0,
		statePower: null,
		isEdit: false,
	};
};
