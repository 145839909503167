import { Toggle } from 'office-ui-fabric-react';
import React from "react";
import Select, { components } from "react-select";
import { Language } from '../../model';
import '../../styles/components.scss';

export interface ISelectOption {
    value: string | number;
    label: string;
    disolutionDate: string;
    liquidationDate: string;
};

export interface IProps {
    options: ISelectOption[];
    placeholder: string;
    handleCustomSelectChange: (values) => void;
    handleShowDissolvedChange: (event, checked) => void;
    showDissolved: boolean;
    language: Language;
    showDissolutionDate: boolean;
    selectedValues: ISelectOption[];
    isMultiSelection: boolean;
    isDisabled: boolean;    
}

interface IState {
    options: ISelectOption[];
    showDissolved: boolean;
}

export class CustomSelect extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options,
            showDissolved: this.props.showDissolved
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.options !== prevProps.options) {
            this.setState({
                options: this.props.options,
                showDissolved: this.props.showDissolved
            });
        }
    }

    render() {

        const CustomMenu = ({ children, ...props }) => {
            if(this.props.isDisabled){
                return(
                    <div></div>
                );
            }
            else
            {
                return (
                    <components.Menu {...props}>
                        <div className="companyToggle">
                            <Toggle 
                                inlineLabel 
                                label={this.props.language['SocietiesCombo']['showDissolved']}
                                checked = {this.state.showDissolved}
                                onChange={this.props.handleShowDissolvedChange} 
                            />
                        </div>
                        <div className="tableHeaderCS" >
                            <div className="rowCS headerCS">
                                <div className="cellHeaderTitleCS">{this.props.language['SocietiesCombo']['name']}</div>                    
                                <div className="cellHeaderCS">{this.props.language['SocietiesCombo']['dissolutionDate']}</div> 
                                <div className="cellHeaderCS">{this.props.language['SocietiesCombo']['settlementDate']}</div> 
                            </div>
                        </div>   
                        {children}      
                
                    </components.Menu>
                );
            }

        };    
            
        const CustomOptionWithDates = ({ ...props }) => {
            if(this.props.isDisabled){
                return (<div></div>);
            }
            else{
            return (
                    <components.Option {...props}>
                    <div className="tableBodyCS" >                    
                        <div className="rowCS">
                            <div className="cellTitleCS">{props.data.label}</div>
                            <div className="cellCS">{props.data.disolutionDate}</div>
                            <div className="cellCS">{props.data.liquidationDate}</div>
                        </div>
                    </div>
                    </components.Option>       
                );
            }
        };    
        
        const CustomOption = ({ ...props }) => {

            if(this.props.isDisabled){
                return (<div></div>);
            }
            else{
                return (
                    <components.Option {...props}>
                    <div className="tableBodyCS" >                    
                        <div className="rowCS">
                            <div className="cellFullWidthCS">{props.data.label}</div>
                        </div>
                    </div>
                    </components.Option>       
                );
            }
        };      
            
            // const ValueContainer = ({
            //     children,
            //     ...props
            //   }: ValueContainerProps<ISelectOption>) => {
            //     let [values, input] = children as any;
              
            //     if (Array.isArray(values)) {
            //       const val = (i: number) => values[i].props.children;
            //       const { length } = values;
              
            //       switch (length) {
            //         case 1:
            //           values = `${val(0)} selected`;
            //           break;
            //         // case 2:
            //         //   values = `${val(0)} and ${val(1)} selected`;
            //         //   break;
            //         // case 3:
            //         //   values = `${val(0)}, ${val(1)} and ${val(2)} selected`;
            //         //   break;
            //         default:
            //           const plural = values.length === 1 + 1 ? "" : "s";
            //           const otherCount = length - 1;
            //           values = `${val(0)} and ${otherCount} other${plural} selected`;
            //           break;
            //       }
            //     }
              
            //     return (
            //       <components.ValueContainer {...props}>
            //         {values}
            //         {input}
            //       </components.ValueContainer>
            //     );
            //   };          
            
            // const customStyles = {
            //     control: (provided, state) => ({
            //       ...provided,
            //       background: '#fff',
            //       borderColor: '#9e9e9e',
            //       minHeight: '30px',
            //       height: '30px',
            //       boxShadow: state.isFocused ? null : null,
            //     }),
            
            //     valueContainer: (provided, state) => ({
            //       ...provided,
            //       height: '30px',
            //       padding: '0 6px'
            //     }),
            
            //     input: (provided, state) => ({
            //       ...provided,
            //       margin: '0px',
            //     }),
            //     indicatorSeparator: state => ({
            //       display: 'none',
            //     }),
            //     indicatorsContainer: (provided, state) => ({
            //       ...provided,
            //       height: '30px',
            //     }),
            //   };          

        return (
            <Select           
                isMulti={this.props.isMultiSelection}
                name="Companies"
                options={this.state.options}
                //components={(this.props.showDissolutionDate) ? { ValueContainer: ValueContainer, Menu: CustomMenu, Option: CustomOptionWithDates } : { Option: CustomOption}}
                components={(this.props.showDissolutionDate) ? { Menu: CustomMenu, Option: CustomOptionWithDates } : { Option: CustomOption}}
                onChange={this.props.handleCustomSelectChange}
                value={this.props.selectedValues}
                className={this.props.isDisabled ? "react-select-container-disabled": "react-select-container"}
                classNamePrefix="react-select"
                isClearable={!this.props.isDisabled}
                isSearchable={!this.props.isDisabled}
                placeholder={this.props.placeholder}
                menuIsOpen={(this.props.isDisabled) ? false : undefined}
                //openMenuOnClick={false}
                //isClearable={!this.props.isMultiSelection}
                //isDisabled={this.props.isDisabled}
                //closeMenuOnSelect={false}
                //hideSelectedOptions={false}
                //styles={customStyles}
            />
        );
    }

}