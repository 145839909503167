export interface Department {
	id: number;
	descriptionSpanish: string;
	descriptionEnglish: string;
	tradeId: number;
}

export interface DepartmentCollection {
	list: Department[];
	totalNumberOfRows: number;
}
export const createEmptyDepartmentCollection = (): DepartmentCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};

export const createEmptyDepartment = (): Department => {
	return {
		id: 0,
		descriptionEnglish: '',
		descriptionSpanish: '',
		tradeId: 0,
	};
};
