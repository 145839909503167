import { connect } from 'react-redux';
import { StateReducer } from '../../reducers';
import { getDepartmentAction, saveDepartmentAction } from './actions/index';
import { Department, Language } from '../../model';
import { DepartmentComponent } from './crud/departmentComponent';

const mapStateToProps = (state: StateReducer) => ({
	departmentSave: state.departmentResponse,
	departmentDelete: state.departmentResponse,
	department: state.department,
	user: state.user,
	language: state.language,
	business: state.businessNames,
	loading: state.http?.inProgress,
});

const mapDispatchToProps = (dispatch: any) => ({
	saveDepartment: (department: Department, language: Language) => dispatch(saveDepartmentAction(department, language)),
	getDepartment: (id: number) => dispatch(getDepartmentAction(id)),
});

export const departmentContainer = connect(mapStateToProps, mapDispatchToProps)(DepartmentComponent);
