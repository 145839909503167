import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { GenericApi } from '../../../../api/generic';
import { BaseType } from '../../../../model/BaseType';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getPowersAction = () => (dispatch) => {
	dispatch(httpCallStartAction());

	GenericApi.GetAsync<BaseType>(ApiUrls.Powers).then((content) => {
		dispatch(getRepresentativesCompleted(content));
	});
};

const getRepresentativesCompleted = (powers: BaseType[]) => ({
	type: actionTypes.GET_POWER_NAMES,
	payload: powers,
	meta: {
		httpEnd: true,
	},
});
