import * as React from 'react';
import { languages } from '../const/resources';
import { ActiveLanguage } from '../../model/enums/activeLanguage';
import { Language } from '../../model/Language';

export interface IProps {
	language: Language;
	list: string;
}

export class NoResultsComponent extends React.Component<IProps, {}> {
	/* constructor(props: any) {
		super(props);
	}		Clean Warning Code */

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<div className='main-container__no_results'>
				{/* <Icon className='icon-results' iconName='SearchIssue' /> */}
				<img className='icon-results' src={require('../../assets/images/not-found.svg')} alt='not_found'></img>
				<span>
					{language['generic']['no-results']}
					{this.props.list.toLocaleLowerCase()}
				</span>
				<span>{language['generic']['no-results-review']}</span>
			</div>
		);
	}
}
