import * as React from 'react';
import { Link } from 'react-router-dom';
import { SpinButton} from 'office-ui-fabric-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getResourcesFile } from '../../utils/extensions';
import {Language,} from '../../model';

export interface IProps {
	items: any;
	currentPage: number;
	link: string;
	updateMaxPageItems: (itemsPage: number) => void;
	actualPageItems?: number;
	language: Language;
}

export interface IState {
	actualPageItems: number;
	actualPage: number;
}
class AdvancedPaginationComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			actualPageItems: this.props.actualPageItems !== undefined? this.props.actualPageItems : 10,
			actualPage: this.props.currentPage,
		};
	}
	componentDidUpdate(prevProps) {
		if (this.props.currentPage !== prevProps.currentPage) {
			this.setState({ actualPage: this.props.currentPage });
		}

		if (this.props.actualPageItems !== undefined && this.props.actualPageItems !== prevProps.actualPageItems) {
			this.setState({ actualPageItems: this.props.actualPageItems });
		}
	}

	public render() {
		let language = getResourcesFile(this.props.language);
		let pages = Math.ceil(this.props.items / this.state.actualPageItems);
		return (
			<div className='ms-Grid-row'>
				<div className='ms-Grid-col ms-sm12 ms-md8'></div>
				<div className='ms-Grid-col ms-sm12 ms-4 spin_pagination'>
					<div className='main-container__content_edit_block ms-spinButton-input'>
						<SpinButton
							defaultValue={this.state.actualPageItems.toString()}
							label={language['Pagination']['itemsperpage']}
							min={10}
							max={100}
							step={1}
							value={this.state.actualPageItems.toString()}
							onChange={this.handleMaxItemsChange}
							onIncrement={this.handleIncrementPageItems}
							onDecrement={this.handleDecrementPageItems}
							incrementButtonAriaLabel={'Increase value by 10'}
							decrementButtonAriaLabel={'Decrease value by 10'}
							onBlur={this.handleBlur}
						/>
					</div>
					<div className='main-container__content_edit_block ms-spinButton-input second'>
						<SpinButton
							defaultValue={this.state.actualPage.toString()}
							label={language['Pagination']['page']}
							min={1}
							max={pages}
							step={1}
							value={this.state.actualPage.toString()}
							onChange={this.handlePageChange}
							onIncrement={this.handleNextPage}
							onDecrement={this.handleLastPage}
							incrementButtonAriaLabel={'Increase value by 1'}
							decrementButtonAriaLabel={'Decrease value by 1'}
							onBlur={this.handleBlur}
						/>
					</div>
					<span className='pagination_text'> {language['Pagination']['of']} {pages}</span>
					<div className='main-container__content_pagination pagination_arrow'>
						<Link
							to={this.props.link + (this.state.actualPage - 1)}
							className={`arrow ${this.state.actualPage > 1 ? '' : 'isDisabled'}`}
						>
							<i className='ms-Icon ms-Icon--ChevronLeft' aria-hidden='true'></i>
						</Link>
					</div>
					<div className='main-container__content_pagination pagination_arrow'>
						<Link
							to={this.props.link + (this.state.actualPage + 1)}
							className={`arrow ${this.state.actualPage < pages ? '' : 'isDisabled'}`}
						>
							<i className='ms-Icon ms-Icon--ChevronRight' aria-hidden='true'></i>
						</Link>
					</div>
				</div>
			</div>
		);
	}

	private handleBlur = (event: any) => {
		this.forceUpdate();
	};

	private handleMaxItemsChange = (event: any) => {
		let value = parseInt(event.target.value);
		if (value >= 10 && value <= 100) {
			this.setState({ actualPageItems: value });
			this.props.updateMaxPageItems(value);
		}
	};

	private handleIncrementPageItems = (value: any) => {
		let valueUpdate = 0;

		if (parseInt(value) >= 50) {
			valueUpdate = 100;
		} else if (parseInt(value) >= 25) {
			valueUpdate = 50;
		} else if (parseInt(value) >= 10) {
			valueUpdate = 25;
		}

		if (valueUpdate !== this.state.actualPageItems) {
			this.setState({ actualPageItems: valueUpdate, actualPage: 1 });
			this.props.history.push(this.props.link + 1);
			this.props.updateMaxPageItems(valueUpdate);
		}

	};
	private handleDecrementPageItems = (value: any) => {
		let valueUpdate = 0;

		if (parseInt(value) <= 25) {
			valueUpdate = 10;
		} else if (parseInt(value) <= 50) {
			valueUpdate = 25;
		} else if (parseInt(value) <= 100) {
			valueUpdate = 50;
		}
		if (valueUpdate!== this.state.actualPageItems) {
			this.setState({ actualPageItems: valueUpdate, actualPage: 1 });
			this.props.history.push(this.props.link + 1);
			this.props.updateMaxPageItems(valueUpdate);
		}
	};

	private handleNextPage = (value: any) => {
		let maxPages = Math.ceil(this.props.items / this.state.actualPageItems);
		let valueUpdate = parseInt(value) + 1;
		if (valueUpdate <= maxPages) {
			this.setState({ actualPage: valueUpdate });
			this.props.history.push(this.props.link + valueUpdate);
		}
	};
	private handleLastPage = (value: any) => {
		let valueUpdate = parseInt(value) - 1;
		if (valueUpdate >= 1) {
			this.setState({ actualPage: valueUpdate });
			this.props.history.push(this.props.link + valueUpdate);
		}
	};
	private handlePageChange = (event: any) => {
		let maxPages = Math.ceil(this.props.items / this.state.actualPageItems);
		let value = parseInt(event.target.value);
		if (value >= 1 && value <= maxPages) {
			this.setState({ actualPage: value });
			this.props.history.push(this.props.link + value);
		} else if (value >= maxPages) {
			this.setState({ actualPage: maxPages });
			this.props.history.push(this.props.link + maxPages);
		} else {
			this.setState({ actualPage: 1 });
			this.props.history.push(this.props.link + 1);
		}
	};
}

export const AdvancedPagination = withRouter(AdvancedPaginationComponent);
