import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { RecordCompanyDetailPage } from './page';
import { getRecordCompanyAction } from './actions/getRecordCompany';
import {
	exportAdminBodyToExcelAction,
	exportActualStockholdersToExcelAction,
	exportHistoricalStockholdersToExcelAction,
} from './actions/exportToExcel';
import { RecordCompanyDetailFilter } from '../../../model/filters';
import { Language } from '../../../model';
import { savePowersRevokeAction } from './actions/savePowersRevoke';

const mapStateToProps = (state: StateReducer) => ({
	recordCompany: state.recordCompany,
	language: state.language,
});

const mapDispatchToProps = (dispatch: any) => ({
	getRecordCompany: (filter: RecordCompanyDetailFilter) => dispatch(getRecordCompanyAction(filter)),
	exportAdminBodyToExcel: (companyId: number[]) => dispatch(exportAdminBodyToExcelAction(companyId)),
	exportActualStockholdersToExcel: (companyId: number[]) => dispatch(exportActualStockholdersToExcelAction(companyId)),
	exportHistoricalStockholdersToExcel: (companyId: number[]) => dispatch(exportHistoricalStockholdersToExcelAction(companyId)),
	savePowerRevokeAllCorporate: (companyId: number, revokeMsg: string, protocolNum: string, language: Language) => dispatch(savePowersRevokeAction(companyId, revokeMsg, protocolNum,language)),
});

export const RecordCompanyDetailContainer = connect(mapStateToProps, mapDispatchToProps)(RecordCompanyDetailPage);
