import * as React from 'react';
import * as utils from '../../../../utils/extensions';
import PropTypes from 'prop-types';
import { AdminBodyCompany, Language, RecordCompanyCustom } from '../../../../model';
import { ActiveLanguage } from '../../../../model/enums/activeLanguage';
import { languages } from '../../../../common/const/resources';
import { PositionsPagination } from '../pagination/positionsPagination';
import { RecordCompanyDetailFilter } from '../../../../model/filters';
import { DefaultButton } from 'office-ui-fabric-react/lib/components/Button';

export interface IProps {
	positions: AdminBodyCompany[];
	totalRows: number;
	language: Language;
	filter: RecordCompanyDetailFilter;
	getRecordCompany: (filter: RecordCompanyDetailFilter) => Promise<RecordCompanyCustom>;
	exportToExcel: (companyId: number[]) => Promise<any>;
}

export interface IState {}

export class AdminBodyGrid extends React.Component<IProps, IState> {
	/*constructor(props: any) {
		super(props);
	}		Clean Warning Code */

	static contextTypes = {
		router: PropTypes.object,
	};

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				<div className='ms-Grid-row subtable_record'>
					<div className='ms-Grid-col ms-xl6'>
						<span className='title_subtable_record'>
							{language['corporate']['detail']['grids']['admin-body-grid']['title']}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton className='button-download' onClick={this.handleExportClick}>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th>
									<div className='table-header'>
										<span>{language['corporate']['detail']['grids']['admin-body-grid']['fields']['joint-admin']}</span>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['corporate']['detail']['grids']['admin-body-grid']['fields']['positions']}</span>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>
											{language['corporate']['detail']['grids']['admin-body-grid']['fields']['nomination-date']}
										</span>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>
											{language['corporate']['detail']['grids']['admin-body-grid']['fields']['expiration-date']}
										</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.positions.map((item) => {
								return this.getPowerRow(item);
							})}
						</tbody>
					</table>
				</div>
				<PositionsPagination
					filter={this.props.filter}
					getRecordCompany={this.props.getRecordCompany}
					totalRows={this.props.totalRows}
				/>
			</>
		);
	}

	public handleExportClick = () => {
		this.props.exportToExcel(this.props.filter.CompaniesId);
	};

	private getPowerRow(position: AdminBodyCompany) {
		let language = languages.languageES;
		let positionDescription = position.positionTypeDescriptionSpanish;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
			positionDescription = position.positionTypeDescriptionEnglish;
		}

		return (
			<tr>
				<td className='mainColumn'>{position.name}</td>
				<td>{positionDescription}</td>
				<td>{position.appointmentDate ? utils.getParsedLocaleDate(position.appointmentDate) : ''}</td>
				<td>
					{position.dateAppointmentFinal
						? utils.getParsedLocaleDate(position.dateAppointmentFinal)
						: language['corporate']['detail']['grids']['admin-body-grid']['undefined']}
				</td>
			</tr>
		);
	}
}
