import * as React from 'react';
import * as utils from '../../../utils/extensions';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Language } from '../../../model';
import { languages } from '../../../common/const/resources';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { classNames } from '../../../common/const/constants';
import {
	allEntityModule,
	onlyAuthorizationModule,
	onlyDeedsModule,
	onlyDocumentationModule,
	onlyPositionsSheetModule,
	onlySocietalSheetModule,
} from '../../../common/const/rolesAssignments';

import { AppInsights } from "applicationinsights-js";
import { store } from '../../../store';

export interface IProps {
	language: Language;
}

export interface IState {}

export class ArchiveManagementComponent extends React.Component<IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props: any) {
		super(props);
		this.state = { hasAuthorizedRole: false };
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let showAllEntities: boolean = utils.isInRoles(allEntityModule);
		let showAuthorizationModule: boolean = utils.isInRoles(onlyAuthorizationModule);
		let showDocumentationModule: boolean = utils.isInRoles(onlyDocumentationModule);
		let showSocietalSheetModule: boolean = utils.isInRoles(onlySocietalSheetModule);
		let showPositionsSheetModule: boolean = utils.isInRoles(onlyPositionsSheetModule);
		let showDeedsModule: boolean = utils.isInRoles(onlyDeedsModule);

		AppInsights.trackTrace("FRONT - ArchiveManagementComponent - Render - showAllEntities: " + showAllEntities + " -- user: " + store.getState().users.currentUser.name);		
		AppInsights.trackTrace("FRONT - ArchiveManagementComponent - Render - showAuthorizationModule: " + showAuthorizationModule + " -- user: " + store.getState().users.currentUser.name);	
		AppInsights.trackTrace("FRONT - ArchiveManagementComponent - Render - showDocumentationModule: " + showDocumentationModule + " -- user: " + store.getState().users.currentUser.name);	
		AppInsights.trackTrace("FRONT - ArchiveManagementComponent - Render - showSocietalSheetModule: " + showSocietalSheetModule + " -- user: " + store.getState().users.currentUser.name);	
		AppInsights.trackTrace("FRONT - ArchiveManagementComponent - Render - showPositionsSheetModule: " + showPositionsSheetModule + " -- user: " + store.getState().users.currentUser.name);	
		AppInsights.trackTrace("FRONT - ArchiveManagementComponent - Render - showDeedsModule: " + showDeedsModule + " -- user: " + store.getState().users.currentUser.name);	
		

		return (
			<>
				{showAllEntities && (
					<div className='ms-Grid-row main-container__block'>
						<div className='ms-Grid-row'>
							<div className='ms-Grid-col ms-lg10'>
								<h1 className='main-container__block-title'>{language['home']['entities']['title']}</h1>
							</div>
						</div>
						<div className='main-container__block-section'>
							<h3 className='main-container__block-subtitle'>{language['home']['entities']['main-section']['title']}</h3>
							<div className='main-container__block-section-content'>
								<Link to='/representatives/page/1' className='main-container__block-section-content-tile'>
									<div className='main-container__block-section-content-tile-icon'>
										<Icon iconName='Dictionary' className={classNames.homeIconPrimary} />
									</div>
									<div className='main-container__block-section-content-tile-text'>
										<span>{language['home']['entities']['main-section']['options']['representatives']}</span>
									</div>
								</Link>
								<Link
									to={showAuthorizationModule ? '/authorizations/page/1' : ''}
									className={`main-container__block-section-content-tile ${showAuthorizationModule ? '' : 'isDisabled'} `}
								>
									<div className='main-container__block-section-content-tile-icon'>
										<Icon iconName='Teamwork' className={classNames.homeIconPrimary} />
									</div>
									<div className='main-container__block-section-content-tile-text'>
										<span>{language['home']['entities']['main-section']['options']['authorizations']}</span>
									</div>
								</Link>
								{/* <Link to='/records/page/1' className='main-container__block-section-content-tile'>
									<div className='main-container__block-section-content-tile-icon'>
										<Icon iconName='Work' className={classNames.homeIconPrimary} />
									</div>
									<div className='main-container__block-section-content-tile-text'>
										<span>{language['home']['entities']['main-section']['options']['records']}</span>
									</div>
								</Link> */}
								<Link
									to={showDeedsModule ? '/deeds/page/1' : ''}
									className={`main-container__block-section-content-tile ${showDeedsModule ? '' : 'isDisabled'} `}
								>
									<div className='main-container__block-section-content-tile-icon'>
										<Icon iconName='FileSymlink' className={classNames.homeIconPrimary} />
									</div>
									<div className='main-container__block-section-content-tile-text'>
										<span>{language['home']['entities']['main-section']['options']['deeds']}</span>
									</div>
								</Link>
								<Link
									to={showDocumentationModule ? '/documentation/page/1' : ''}
									className={`main-container__block-section-content-tile ${showDocumentationModule ? '' : 'isDisabled'} `}
								>
									<div className='main-container__block-section-content-tile-icon'>
										<Icon iconName='Documentation' className={classNames.homeIconPrimary} />
									</div>
									<div className='main-container__block-section-content-tile-text'>
										<span>{language['home']['entities']['main-section']['options']['documentation']}</span>
									</div>
								</Link>
							</div>
						</div>
						<div className='main-container__block-section'>
							<h3 className='main-container__block-subtitle'>{language['home']['entities']['sheets-section']['title']}</h3>
							<div className='main-container__block-section-content'>
								{/* <Link to="/positions/page/1"><div className="ms-Grid-col ms-sm12 ms-lg2 main-container_link_file"><p>{language['home']['files']['sheets-section']['options']['charges-tab']}</p></div></Link>  */}
								<Link
									to={showSocietalSheetModule ? '/recordCompanies/page/1' : ''}
									className={`main-container__block-section-content-tile ${showSocietalSheetModule ? '' : 'isDisabled'} `}
								>
									<div className='main-container__block-section-content-tile-icon'>
										<Icon iconName='Storyboard' className={classNames.homeIconPrimary} />
									</div>
									<div className='main-container__block-section-content-tile-text'>
										<span>{language['home']['entities']['sheets-section']['options']['societal-tab']}</span>
									</div>
								</Link>
								<Link
									to={showPositionsSheetModule ? '/positions/page/1' : ''}
									className={`main-container__block-section-content-tile ${
										showPositionsSheetModule ? '' : 'isDisabled'
									} `}
								>
									<div className='main-container__block-section-content-tile-icon'>
										<Icon iconName='TaskManager' className={classNames.homeIconPrimary} />
									</div>
									<div className='main-container__block-section-content-tile-text'>
										<span>{language['home']['entities']['sheets-section']['options']['charges-tab']}</span>
									</div>
								</Link>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}

	// private readonly getMenuButton = (language) => {
	// 	return (
	// 		<DefaultButton
	// 			text={language['home']['new-button']}
	// 			className='main-container_button ms-lg12'
	// 			menuIconProps={null}
	// 			menuProps={{
	// 				shouldFocusOnMount: true,
	// 				items: [
	// 					{
	// 						key: 'Escritura',
	// 						onClick: () => {
	// 							this.context.router.history.push('/deeds/new');
	// 						},
	// 						iconProps: {
	// 							iconName: 'Certificate',
	// 							className: 'context-menu__icon',
	// 						},
	// 						name: language['home']['files']['new-section']['deed'],
	// 					},
	// 					{
	// 						key: 'Sociedad',
	// 						onClick: () => {
	// 							this.context.router.history.push('/companies/new');
	// 						},
	// 						iconProps: {
	// 							iconName: 'TeamWork',
	// 							className: 'context-menu__icon',
	// 						},
	// 						name: language['home']['files']['new-section']['society'],
	// 					},
	// 					{
	// 						key: 'Sociedad',
	// 						onClick: () => {
	// 							this.context.router.history.push('/authorizations/new');
	// 						},
	// 						iconProps: {
	// 							iconName: 'TeamWork',
	// 							className: 'context-menu__icon',
	// 						},
	// 						name: language['home']['files']['new-section']['authorization'],
	// 					},
	// 				],
	// 			}}
	// 		/>
	// 	);
	// };
}
