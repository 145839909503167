import { actionTypes } from '../../../../common/const/actionTypes';
import { CompanyApi } from '../../../../api/companies/companiesApi';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { BaseNameList } from '../../../../model';
import { RepresentativeDetailFilter } from '../../../../model/filters/RepresentativeFilter';

export const getCompaniesByPersonIdAction = (filter: RepresentativeDetailFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());

	CompanyApi.GetCompaniesByPersonId(filter, pageNumber, itemsPage).then((content) => {
		dispatch(GetCompaniesByPersonIdCompleted(content));
	});
};
const GetCompaniesByPersonIdCompleted = (companiesByPersonId: BaseNameList) => ({
	type: actionTypes.GET_COMPANIES_BY_PERSON,
	payload: companiesByPersonId,
	meta: {
		httpEnd: true,
	},
});
