import {
	ComboBox,
	DatePicker,
	DayOfWeek,
	IComboBoxOption,
	IDatePickerStrings,
	SelectableOptionMenuItemType
} from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import * as React from 'react';
import AsyncSelect from 'react-select/async';
import { languages } from '../../../common/const/resources';
import { CustomSelect, ISelectOption } from '../../../common/customSelect/customSelect';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import {
	AuthorizationAccountCollection,
	BaseName,
	BaseType,
	CompanyBase,
	Language,
	Person,
	SearchSelectModel,
	UserState,
} from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { AuthorizationFilter, createEmptyAuthorizationFilter } from '../../../model/filters/AuthorizationFilter';
import * as utils from '../../../utils/extensions';

export interface IProps {
	getAuthorizations: (filter: AuthorizationFilter, pageNumbers: number, itemsPage: number) => Promise<AuthorizationAccountCollection>;
	getCompanies: () => Promise<BaseName[]>;
	getTradeCompanies: (tradeId?: number) => Promise<BaseName[]>;
	getBusiness: () => Promise<BaseName[]>;
	getAuthorizationStatusesNames: () => Promise<BaseType[]>;
	getAuthorizationsUsers: (value: string) => Promise<Person[]>;
	filter: AuthorizationFilter;
	companies: CompanyBase[];
	tradeCompanies: CompanyBase[];
	onchangeFilter: any;
	language: Language;
	business: BaseType[];
	authorizationStatusesNames: BaseType[];
	authorizationsUsers: Person[];
	collapsed: boolean;
	user: UserState;
	itemsPage: number;
}

export interface IState {
	actualFilter: AuthorizationFilter;
	selectedCompany: number;
	selectedAuthorized: any;
	isBusinessDisabled: boolean;
	businessName: string;
	collapsed: boolean;
	showDissolved: boolean;
}

export class AuthorizationsFilterComponent extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			selectedCompany: 1,
			selectedAuthorized: null,
			isBusinessDisabled: false,
			collapsed: false,
			businessName: '',
			actualFilter: createEmptyAuthorizationFilter(),
			showDissolved: true,
		};
	}
	componentDidMount() {
		this.setState({ actualFilter: this.props.filter });
		if (this.props.user && this.props.user.currentUser && this.props.user.currentUser.roles !== undefined) {
			this.handleDisableBusiness();
		}
		this.props.getCompanies();
		this.props.getBusiness();
		this.props.getAuthorizationStatusesNames();
	}
	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let companies: IComboBoxOption[] = [];
		if (this.props.business) {
			companies.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
			companies.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			if(this.state.actualFilter.Business <= 0){
				this.props.companies.forEach((item) => {
					return companies.push({
						key: item.id,
						text: item.name,
						id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
						title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null,
					});
				});
			}else{
				this.props.tradeCompanies.forEach((item) => {
					return companies.push({
						key: item.id,
						text: item.name,
						id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
						title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null,
					});
				});
			}
			
			
		}

		let business: IComboBoxOption[] = [];
		if (this.props.business) {
			this.props.business.forEach((item) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			business.sort((a, b) => a.text.localeCompare(b.text))
			business.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			business.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}

		let authorizationStatuses: IComboBoxOption[] = [];
		if (this.props.authorizationStatusesNames) {
			

			this.props.authorizationStatusesNames.forEach((item) => {
				authorizationStatuses.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			authorizationStatuses.sort((a, b) => a.text.localeCompare(b.text))
			authorizationStatuses.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			authorizationStatuses.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}

		let users: SearchSelectModel[] = [];
		if (this.props.authorizationsUsers && this.props.authorizationsUsers.length > 0) {
			this.props.authorizationsUsers.forEach((item) => {
				users.push({ value: item.id, label: `${item.name} ${item.email ? '(' + item.email + ')' : ''}` });
			});
		}

		let calendarStrings: IDatePickerStrings = language['calendar'];

		// const onRenderOption = (option: any): JSX.Element => {
		// 	if (option.itemType === DropdownMenuItemType.Header) {
		// 		if(option.key == 0){
		// 			return (
		// 				<Toggle 
		// 				inlineLabel 
		// 				label={language['SocietiesCombo']['showDissolved']} 
		// 				checked = {this.state.showDissolved}
		// 				onChange={(event, checked)=>{this.setState({showDissolved: checked})}} />
		// 			);
		// 		}
		// 		else{	
		// 			return (
		// 				<table>
		// 					<tbody>
		// 						<tr>
		// 							<td style={{ width: 15, fontSize: 14, padding: 5 }}></td>
		// 							<td style={{ width: 200, fontSize: 14, padding: 0 }}>{option.key}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: 0 }}>{option.text}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: '0px 10px' }}>{option.title}</td>
		// 						</tr>
		// 					</tbody>
		// 				</table>
		// 			);
		// 		}
		// 	} else {
		// 		return (
		// 			<table>
		// 				<tbody>
		// 					<tr>
		// 						<td style={{ width: 200, fontSize: 14, padding: 5 }}>{option.text}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.title}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.id}</td>
		// 					</tr>
		// 				</tbody>
		// 			</table>
		// 		);
		// 	}
		// };

		let sortedCompanies = companies.sort((a, b) => a.text.localeCompare(b.text));
		// sortedCompanies.unshift({
		// 	key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'],
		// 	itemType: DropdownMenuItemType.Header,
		// });
		// sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });
		
		let selectedCompanies= (this.state.showDissolved) ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title === language['SocietiesCombo']['dissolutionDate'])
		let options: ISelectOption[] = selectedCompanies.map(company => {
			return {
				value:company.key.toString(),
				label:company.text, 
				disolutionDate:company.id, 
				liquidationDate: company.title
			};
		  });

		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div className={this.state.collapsed ? 'ms-Grid-row filter_header-collapse' : 'ms-Grid-row filter_header'}>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							{/* <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['society']['title']}</p>
								<VirtualizedComboBox
									autoComplete='on'
									allowFreeform={false}
									options={this.state.showDissolved ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title == language['SocietiesCombo']['dissolutionDate'])}
									placeholder={language['authorizations']['list']['filter']['options']['society']['placeholder']}
									onChange={this.handleCompanyChange}
									selectedKey={this.state.actualFilter.CompaniesId}									
									onRenderOption={onRenderOption}
									multiSelect={true}
								/>
							</div> */}
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['society']['title']}</p>
								<CustomSelect
									options={options}
									handleCustomSelectChange={this.handleReactSelectCompanyChange}
									showDissolved={this.state.showDissolved}
									handleShowDissolvedChange={(event, checked)=>{this.setState({showDissolved: checked})}}
									language={language}
									showDissolutionDate={true}
									selectedValues={this.state.actualFilter.selectedCompanies}
									isMultiSelection={true}
									isDisabled={false}
									placeholder={language['authorizations']['list']['filter']['options']['society']['placeholder']}
								/>
							</div>							
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['uniqueCode']['title']}</p>
								<TextField
									type='text'
									value={this.state.actualFilter.UniqueCode}
									onChange={this.handleUniqueCodeChange}
									onBlur={this.handleUniqueCodeBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['trade']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={business}
									placeholder={language['authorizations']['list']['filter']['options']['trade']['placeholder']}
									onChange={this.handleBusinessChange}
									selectedKey={business.length === 1 ? business[0].key : this.state.actualFilter.Business}
									disabled={this.state.isBusinessDisabled}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['cif']['title']}</p>
								<TextField
									type='text'
									value={this.state.actualFilter.Cif}
									onChange={this.handleCifChange}
									onBlur={this.handleCifBlur}
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['account']['title']}</p>
								<TextField
									type='text'
									value={this.state.actualFilter.Account}
									onChange={this.handleAccountChange}
									onBlur={this.handleAccountBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['authorizated-name']['title']}</p>
								<div className='search-select'>
									<i className={'ms-Icon ms-Icon--Search search-icon'} aria-hidden='true'></i>
									<AsyncSelect
										noOptionsMessage={() => language['generic']['list']['filter']['notOptions']}
										loadingMessage={() => language['generic']['list']['filter']['loadingText']}
										className={'search-person'}
										cacheOptions
										loadOptions={this.loadOptions}
										value={this.state.selectedAuthorized}
										onInputChange={this.handleInputChange}
										onChange={this.handleAuthorizedNameChange}
										placeholder={
											language['authorizations']['list']['filter']['options']['authorizated-name']['placeholder']
										}
									/>
								</div>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['authorizated-nif']['title']}</p>
								<TextField
									type='text'
									value={this.state.actualFilter.AuthorizedNif}
									onChange={this.handleAuthorizedNifChange}
									onBlur={this.handleAuthorizedNifBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['status']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={authorizationStatuses}
									placeholder={language['authorizations']['list']['filter']['options']['status']['placeholder']}
									onChange={this.handleStatusChange}
									selectedKey={this.state.actualFilter.Status}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['create-date']['title']}</p>
								<div className='range-picker'>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['from']}
										ariaLabel={language['generic']['form']['fields']['date']['from']}
										strings={calendarStrings}
										value={this.state.actualFilter.CreationDateFrom}
										maxDate={this.state.actualFilter.CreationDateTo}
										onSelectDate={this.handleCreationDateFromChange}
									/>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['to']}
										ariaLabel={language['generic']['form']['fields']['date']['to']}
										strings={calendarStrings}
										value={this.state.actualFilter.CreationDateTo}
										minDate={this.state.actualFilter.CreationDateFrom}
										onSelectDate={this.handleCreationDateToChange}
									/>
								</div>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['authorizations']['list']['filter']['options']['revocation-date']['title']}</p>
								<div className='range-picker'>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['from']}
										ariaLabel={language['generic']['form']['fields']['date']['from']}
										strings={calendarStrings}
										value={this.state.actualFilter.RevocationDateFrom}
										maxDate={this.state.actualFilter.RevocationDateTo}
										onSelectDate={this.handleRevocationDateFromChange}
									/>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['to']}
										ariaLabel={language['generic']['form']['fields']['date']['to']}
										strings={calendarStrings}
										value={this.state.actualFilter.RevocationDateTo}
										minDate={this.state.actualFilter.RevocationDateFrom}
										onSelectDate={this.handleRevocationDateToChange}
									/>
								</div>
							</div>
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterAuthorizations}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterAuthorizations}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.actualFilter.CompanyName.length > 0 ? '' : 'none' }}>
								<span className='key'>{language['authorizations']['list']['filter']['options']['society']['title']}</span>
								<span className='value'>{this.state.actualFilter.CompanyName?.join(", ")}</span>
							</p>
							<p style={{ display: this.state.actualFilter.UniqueCode !== '' ? '' : 'none' }}>
								<span className='key'>
									{language['authorizations']['list']['filter']['options']['uniqueCode']['title']}
								</span>
								<span className='value'>{this.state.actualFilter.UniqueCode}</span>
							</p>
							<p style={{ display: this.state.actualFilter.Business !== 0 ? '' : 'none' }}>
								<span className='key'>{language['authorizations']['list']['filter']['options']['trade']['title']}</span>
								<span className='value'>{this.state.actualFilter.BusinessName}</span>
							</p>
							<p style={{ display: this.state.actualFilter.Cif !== '' ? '' : 'none' }}>
								<span className='key'>{language['authorizations']['list']['filter']['options']['cif']['title']}</span>
								<span className='value'>{this.state.actualFilter.Cif}</span>
							</p>
							<p style={{ display: this.state.actualFilter.Account !== '' ? '' : 'none' }}>
								<span className='key'>{language['authorizations']['list']['filter']['options']['account']['title']}</span>
								<span className='value'>{this.state.actualFilter.Account}</span>
							</p>
							<p style={{ display: this.state.actualFilter.AuthorizedName !== '' ? '' : 'none' }}>
								<span className='key'>
									{language['authorizations']['list']['filter']['options']['authorizated-name']['title']}
								</span>
								<span className='value'>{this.state.actualFilter.AuthorizedName}</span>
							</p>
							<p style={{ display: this.state.actualFilter.AuthorizedNif !== '' ? '' : 'none' }}>
								<span className='key'>
									{language['authorizations']['list']['filter']['options']['authorizated-nif']['title']}
								</span>
								<span className='value'>{this.state.actualFilter.AuthorizedNif}</span>
							</p>
							<p style={{ display: this.state.actualFilter.Status !== 0 ? '' : 'none' }}>
								<span className='key'>{language['authorizations']['list']['filter']['options']['status']['title']}</span>
								<span className='value'>{this.state.actualFilter.StatusName}</span>
							</p>
							<p
								style={{
									display:
										this.state.actualFilter.CreationDateFrom != null || this.state.actualFilter.CreationDateTo != null
											? ''
											: 'none',
								}}
							>
								<span className='key'>
									{language['authorizations']['list']['filter']['options']['create-date']['title']}
								</span>
								<span className='value'>
									{utils.dateRangeToString(
										this.state.actualFilter.CreationDateFrom,
										this.state.actualFilter.CreationDateTo,
										this.props.language,
									)}
								</span>
							</p>
							<p
								style={{
									display:
										this.state.actualFilter.RevocationDateFrom != null ||
										this.state.actualFilter.RevocationDateTo != null
											? ''
											: 'none',
								}}
							>
								<span className='key'>
									{language['authorizations']['list']['filter']['options']['revocation-date']['title']}
								</span>
								<span className='value'>
									{utils.dateRangeToString(
										this.state.actualFilter.RevocationDateFrom,
										this.state.actualFilter.RevocationDateTo,
										this.props.language,
									)}
								</span>
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user) {
			this.handleDisableBusiness();
		}

		if (this.props.business !== prevProps.business) {
			let filter = { ...this.state.actualFilter };
			filter.BusinessName = this.props.language.name.includes(ActiveLanguage.English)
				? this.props.business.find((x) => x.id === filter.Business)?.descriptionEnglish
				: this.props.business.find((x) => x.id === filter.Business)?.descriptionSpanish;

			this.setState({ actualFilter: filter });
		}
	}

	private readonly handleDisableBusiness = (): void => {
		let isDisabled = false;
		let defaultOption = 0;
		let filter = { ...this.state.actualFilter };
		if (this.props.user && this.props.user.currentUser?.roles !== undefined) {
			defaultOption = utils.getBusinessByRole(this.props.user.currentUser?.roles[0]);
			if (defaultOption !== 0) {
				isDisabled = true;
				filter.Business = defaultOption;
				this.props.getAuthorizations(filter, 1, this.props.itemsPage);
			}
		}

		this.setState({ isBusinessDisabled: isDisabled, actualFilter: filter });
		this.props.onchangeFilter(filter);
	};

	private readonly handleCompanyChange = (event, option): void => {
		let filter = { ...this.state.actualFilter };
		if (option.selected === true) {
			filter.CompaniesId.push(option.key);
			filter.CompanyName.push(option.text);
		} else {
			filter.CompaniesId = filter.CompaniesId.filter((e) => e !== option.key);
			filter.CompanyName = filter.CompanyName.filter((e) => e !== option.text);
		}
		this.setState({ actualFilter: filter });
	};

	private readonly handleReactSelectCompanyChange = (values) => {

		let filter = { ...this.state.actualFilter };

		filter.CompaniesId.length = 0;
		filter.CompanyName.length = 0;
		filter.selectedCompanies.length = 0;

		if(values!==undefined && values!==null){

			values.forEach((item) => {
				filter.selectedCompanies.push({value:item.value.toString(), label:item.label, disolutionDate:item.disolutionDate, liquidationDate: item.liquidationDate});
				filter.CompaniesId.push(item.value);
				filter.CompanyName.push(item.label);
			})
		}

		this.setState({ actualFilter: filter });
	};	

	private readonly handleBusinessChange = async (event, option): Promise<void> => {
		let filter = { ...this.state.actualFilter };
		filter.Business = parseInt(option?.key);
		filter.BusinessName = option?.text;
		this.setState({ actualFilter: filter });
		let tradeId = option?.key;
		await this.props.getTradeCompanies(tradeId);
	};

	private readonly handleStatusChange = (event, option): void => {
		let filter = { ...this.state.actualFilter };
		filter.Status = parseInt(option?.key);
		filter.StatusName = option?.text;
		this.setState({ actualFilter: filter });
	};

	public readonly handleCifChange = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.Cif = value.target.value;
		this.setState({ actualFilter: filter });
	};
	public readonly handleCifBlur = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.Cif = value.target.value.toUpperCase();
		this.setState({ actualFilter: filter });
	};

	public readonly handleUniqueCodeChange = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.UniqueCode = value.target.value;
		this.setState({ actualFilter: filter });
	};
	public readonly handleUniqueCodeBlur = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.UniqueCode = value.target.value.toUpperCase();
		this.setState({ actualFilter: filter });
	};

	public readonly handleAccountChange = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.Account = value.target.value;
		this.setState({ actualFilter: filter });
	};
	public readonly handleAccountBlur = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.Account = value.target.value.toUpperCase();
		this.setState({ actualFilter: filter });
	};

	public readonly handleAuthorizedNifChange = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.AuthorizedNif = value.target.value;
		this.setState({ actualFilter: filter });
	};
	public readonly handleAuthorizedNifBlur = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.AuthorizedNif = value.target.value.toUpperCase();
		this.setState({ actualFilter: filter });
	};

	public readonly handleCreationDateFromChange = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.CreationDateFrom = value;
		this.setState({ actualFilter: filter });
	};
	public readonly handleCreationDateToChange = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.CreationDateTo = value;
		this.setState({ actualFilter: filter });
	};
	public readonly handleRevocationDateFromChange = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.RevocationDateFrom = value;
		this.setState({ actualFilter: filter });
	};
	public readonly handleRevocationDateToChange = (value): void => {
		let filter = { ...this.state.actualFilter };
		filter.RevocationDateTo = value;
		this.setState({ actualFilter: filter });
	};

	private readonly handleAuthorizedNameChange = (selectedAuthorized) => {
		let filter = { ...this.state.actualFilter };
		filter.AuthorizedId = parseInt(selectedAuthorized?.value);
		filter.AuthorizedName = selectedAuthorized?.label;
		this.setState({ actualFilter: filter, selectedAuthorized: selectedAuthorized });
	};

	private readonly filterAuthorizations = () => {
		let filter = { ...this.state.actualFilter };
		this.setState({ collapsed: true });
		this.props.onchangeFilter(filter);
		this.props.getAuthorizations(filter, 1, this.props.itemsPage);
	};

	private readonly cleanFilterAuthorizations = () => {
		let resetFilter = createEmptyAuthorizationFilter();
		resetFilter.Business = utils.getBusinessByRole(this.props.user.currentUser?.roles[0]);
		this.setState({ actualFilter: resetFilter, selectedAuthorized: null });
		this.props.onchangeFilter(resetFilter);
		this.props.getAuthorizations(resetFilter, 1, this.props.itemsPage);
	};
	private readonly handleFiltersCollapse = () => {
		let filter = { ...this.state.actualFilter };
		if (filter.BusinessName === '' || filter.BusinessName === undefined) {
			let business = this.props.business.find((x) => x.id === filter.Business);
			if (business) {
				filter.BusinessName = this.props.language.name.includes(ActiveLanguage.English)
					? business.descriptionEnglish
					: business.descriptionSpanish;
			}
		}
		this.setState({
			collapsed: !this.state.collapsed,
			actualFilter: filter,
		});
	};

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filterColors(inputValue));
		}, 1000);
	};

	private readonly filterColors = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.authorizationsUsers && this.props.authorizationsUsers.length > 0) {
			this.props.authorizationsUsers.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}
		return users.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
	};

	private readonly handleInputChange = (newValue: string) => {
		const inputValue = newValue
			.trimStart()
			.replace(/\s\s/g, ' ')
			.replace(/[^A-Za-z0-9\s]/g, '');
		if (inputValue.length >= 4) {
			this.props.getAuthorizationsUsers(inputValue);
		}
		return inputValue;
	};
}
