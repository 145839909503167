import { DocumentationApi } from '../../../api/documentation';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { Constants } from '../../../common/const/constants';
import { Language } from '../../../model';
import { DocumentationFilter } from '../../../model/filters/DocumentationFilter';
import { getResourcesFile, showError, showSuccess } from '../../../utils/extensions';
import { getDocumentationAction } from './getDocumentation';

export const deleteDocumentationAction = (filter: DocumentationFilter, currentLanguage: Language, id: number) => (dispatch) => {
    let language = getResourcesFile(currentLanguage);
    DocumentationApi.DeleteAsync(ApiUrls.Documentation, id).then((result: any) => {
        if (!result.error) {
            showSuccess(language['documentation']['messages']['success-delete']);
        } else {
            showError(language['documentation']['messages']['error-delete']);
        }

        dispatch(getDocumentationAction(filter, 1, Constants.ItemsPerPage));
        dispatch(deleteDocumentationCompleted(result));
    });
};

const deleteDocumentationCompleted = (result: boolean) => ({
    type: actionTypes.DELETE_DOCUMENTATION,
    payload: result
});