import { GenericApi } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { BaseType } from '../../../model';

export const getBusinessNamesAction = () => (dispatch) => {
    dispatch(httpCallStartAction());

    GenericApi.GetAsync<BaseType>(ApiUrls.Trade).then((result) => {
        dispatch(getBusinessNamesCompleted(result));
    });
};

const getBusinessNamesCompleted = (businessNames: BaseType[]) => ({
    type: actionTypes.GET_BUSINESS_NAMES,
    payload: businessNames,
    meta: {
        httpEnd: true,
    }
});