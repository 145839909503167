export interface AuthorizationAccountCollection {
	list: AuthorizationAccount[];
	totalNumberOfRows: number;
}

export interface AuthorizationAccount {
	id: number;
	account: string;
	authorizedId: number;
	companyId: string;
	descriptionSpanish: string;
	descriptionEnglish: string;
	stateId: number;
	name: string;
	authorizedDNI: string;
	companyName: string;
	companyCif: string;
	tradeSpanish: string;
	tradeEnglish: string;
	creationDate: Date;
	revokeDate: Date;
}

export const createEmptyAuthorizationList = (): AuthorizationAccountCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};

export const createEmptyAuthorization = (): AuthorizationAccount => {
	return {
		id: 0,
		account: '',
		authorizedId: 0,
		companyId: '',
		descriptionSpanish: '',
		descriptionEnglish: '',
		stateId: 0,
		name: '',
		authorizedDNI: '',
		companyName: '',
		companyCif: '',
		tradeSpanish: '',
		tradeEnglish: '',
		creationDate: null,
		revokeDate: null,
	};
};
