import { ActiveRepresentativeOrderFilter } from './ActiveRepresentativeOrderFilter';
import { OrderBy } from './OrderBy';

import { ISelectOption } from '../../common/customSelect/customSelect'

export interface RepresentativeFilter {
	CurrentFilter: ActiveRepresentativeOrderFilter;

	NameOrder: OrderBy;
	DniOrder: OrderBy;
	CompanyOrder: OrderBy;
	ProtocoloOrder: OrderBy;
	InscriptionOrder: OrderBy;
	StateOrder: OrderBy;
	TypeOrder: OrderBy;
	Subject: OrderBy;
	CompanyNameOrder: OrderBy;
	PowerTypeOrder: OrderBy;
	DocumentDateOrder:OrderBy;

	RepresentativeId: number;
	RepresentativeName: string;

	NotaryPeopleId?: number;
	NotaryPeopleName: string;

	Dni: string;
	Cif: string;
	UniqueCode?: string;
	CompaniesId: number[];
	Protocol: string;
	Inscription: string;
	State: string;
	StateId: number;
	Pagination: string;
	Type: string;
	CreationDateFrom: Date;
	CreationDateTo: Date;
	RevocationDateFrom: Date;
	RevocationDateTo: Date;
	PowerExerciseName: string;
	PowerExerciseId: number;
	PowerTypeName: string;
	PowerId: number;
	Country: string;
	CountryId: number;
	PassportOthers: string;
	PassportOthersId: number;
	Department: string;
	DepartmentId: number;
	CompanyName?: string[];
	Trade: string;
	TradeId: number;
	RepresentativeTrade: string;
	RepresentativeTradeId: number;
	StatusPower: string;
	StatusPowerId: number;
	SubjectValue: string;
	ItemsPage: number;

	selectedCompanies: ISelectOption[];
}

export interface RepresentativeDetailFilter {
	CompanyId: number;
	PersonId: number;
	CompanyName: string;
}

export const createEmptyRepresentativeFilter = (): RepresentativeFilter => {
	return {
		CurrentFilter: ActiveRepresentativeOrderFilter.Name,
		CompanyOrder: OrderBy.Asc,
		DniOrder: OrderBy.Asc,
		NameOrder: OrderBy.Asc,
		ProtocoloOrder: OrderBy.Asc,
		InscriptionOrder: OrderBy.Asc,
		StateOrder: OrderBy.Asc,
		TypeOrder: OrderBy.Asc,
		RepresentativeId: 0,
		RepresentativeName: '',
		Type: '0',
		Cif: '',
		UniqueCode: '',
		CompaniesId: [],
		CompanyName:[],
		Dni: '',
		PowerId: 0,
		Inscription: '',
		Protocol: '',
		State: '',
		StateId: 0,
		Pagination: 'true',
		CreationDateFrom: null,
		CreationDateTo: null,
		RevocationDateFrom: null,
		RevocationDateTo: null,
		CompanyNameOrder: OrderBy.Asc,
		PowerTypeOrder: OrderBy.Asc,
		PowerTypeName: '',
		Country: '',
		CountryId: 0,
		Trade: '',
		TradeId: 0,
		RepresentativeTrade: '',
	    RepresentativeTradeId: 0,
		PowerExerciseName: '',
		PowerExerciseId: 0,
		PassportOthers: '',
		PassportOthersId: 0,
		Department: '',
		DepartmentId: 0,
		Subject: OrderBy.Asc,
		StatusPower: '',
		StatusPowerId: 0,
		ItemsPage: 10,
		SubjectValue: '',
		NotaryPeopleId: null,
		NotaryPeopleName:'',
		DocumentDateOrder: OrderBy.Asc,
		selectedCompanies: []
	};
};

export const createEmptyRepresentativeDetailFilter = (): RepresentativeDetailFilter => {
	return {
		PersonId: 0,
		CompanyId: 0,
		CompanyName: '',
	};
};
