import { Icon } from 'office-ui-fabric-react/lib/Icon';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { languages } from '../../../common/const/resources';
import { onlyPeopleModule } from '../../../common/const/rolesAssignments';
import { routeUrls } from '../../../common/const/routes';
import { NoResultsComponent } from '../../../common/noResults/noResults';
import { SpinnerContainer } from '../../../common/spinner';
import { UnauthorizedView } from '../../../common/unauthorizedView/unauthorizedView';
import { BaseType, Language, Person, PersonCollection, User, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { PersonFilter } from '../../../model/filters/PersonFilter';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';
import { PeopleSearchComponent } from '../../people/list/search';
import { PeopleDataGrid } from './grid';

export interface IProps {
	peopleCollection: PersonCollection;
	business: BaseType[];
	csvDownloadLink: string;
	user: User;
	language: Language;
	currentUser: UserState;
	peopleNames: Person[];
	filter: PersonFilter;
	getBusiness: () => Promise<BaseType[]>;
	getPeople: (filter: PersonFilter, pageNumbers: number, itemsPage: number) => Promise<PersonCollection>;
	deletePerson: (filter: PersonFilter, language: Language, id: number) => Promise<boolean>;
	exportToExcel: (filter: PersonFilter) => Promise<any>;
	getPeopleNames: (filter: string) => Promise<Person[]>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	collapsed: boolean;
	filter: PersonFilter;
	orderByName: string;
	orderByDni: string;
	orderByPassport: string;
	orderByNacionality: string;
	orderByEmail: string;
	orderByTrade: string;
}

export class PeoplePage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);

		this.state = {
			isAuthorized: false,
			isLoading: true,
			collapsed: false,
			orderByName: 'ms-Icon--ChevronUpSmall',
			orderByDni: 'ms-Icon--ChevronUpSmall',
			orderByPassport: 'ms-Icon--ChevronUpSmall',
			orderByNacionality: 'ms-Icon--ChevronUpSmall',
			orderByEmail: 'ms-Icon--ChevronUpSmall',
			orderByTrade: 'ms-Icon--ChevronUpSmall',
			filter: this.props.filter,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyPeopleModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		let filter = {...this.props.filter}		
		this.props.getPeople(filter, page, this.props.filter.ItemsPage);
		this.props.getBusiness();
	}

	public componentDidUpdate(prevProps) {
		let currentUserOld = prevProps.currentUser;
		if (currentUserOld.currentUser === null) {
			let isAuth = utils.isInRoles(onlyPeopleModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}

		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if (newPage !== oldPage) {
			let filter = {...this.props.filter}
			filter.PersonId = 0;
			filter.Name = "";
			this.props.getPeople(filter, newPage, this.props.filter.ItemsPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin) || utils.isInRole(userRoles.ajAeropuertosH)|| utils.isInRole(userRoles.ajCintra)|| utils.isInRole(userRoles.ajConstruccion)|| utils.isInRole(userRoles.ajCorporacion)|| utils.isInRole(userRoles.ajFeim);

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['people']['list']['title']}</h1>
							{isAdmin && (
								<Link to='/people/new' className='button-addButton'>
									<Icon iconName='Add' />
									<span>{language['people']['list']['new-button']}</span>
								</Link>
							)}
						</div>
						<div className='ms-Grid'>
							<PeopleSearchComponent
								filter={this.state.filter}
								getPeople={this.props.getPeople}
								onchangeFilter={this.handleFilterChange}
								language={this.props.language}
								business={this.props.business}
								peopleNames={this.props.peopleNames}
								getPeopleNames={this.props.getPeopleNames}
								itemsPage={this.state.filter.ItemsPage}
								user={this.props.currentUser}
								page={page}
							/>
							{this.props.peopleCollection.totalNumberOfRows > 0 ? (
								<PeopleDataGrid
									deletePerson={this.props.deletePerson}
									user={this.props.user}
									language={this.props.language}
									filter={this.state.filter}
									page={page}
									peopleCollection={this.props.peopleCollection}
									getPeople={this.props.getPeople}
									exportToExcel={this.props.exportToExcel}
									onchangeFilter={this.handleFilterChange}
								/>
							) : (
								<NoResultsComponent language={this.props.language} list={language['people']['list']['title']} />
							)}
						</div>
					</>
				)}
			</>
		);
	}

	public readonly handleFilterChange = (newfilter: PersonFilter): void => {
		this.setState({ filter: newfilter });
		this.props.history.push(routeUrls.peoplePagination + 1);
	};
}
