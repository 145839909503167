import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { BaseType } from '../../../../model/BaseType';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { GenericApi } from '../../../../api/generic';

export const getPowerExerciseNamesAction = () => (dispatch) => {
	dispatch(httpCallStartAction());

	GenericApi.GetAsync<BaseType>(ApiUrls.PowerExcercises).then((result) => {
		dispatch(getPowerExerciseNamesActionCompleted(result));
	});
};

const getPowerExerciseNamesActionCompleted = (powerExerciseNames: BaseType[]) => ({
	type: actionTypes.GET_POWER_EXERCISE_NAMES,
	payload: powerExerciseNames,
	meta: {
		httpEnd: true,
	},
});
