import { CompanyApi } from '../../../api/companies/companiesApi';
import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { CompanyBase, TradeCompany } from '../../../model';


export const getCompaniesNamesAction = (moduleId: number) => (dispatch) => {
	dispatch(httpCallStartAction());

	CompanyApi.GetCompaniesByTradeFilter(moduleId).then((content) => {
		dispatch(getCompaniesNamesCompleted(content));
	});
};
const getCompaniesNamesCompleted = (companyCollection: CompanyBase[]) => ({
	type: actionTypes.GET_COMPANY_NAMES,
	payload: companyCollection,
	meta: {
		httpEnd: true,
	},
});

export const getTradeCompaniesAction = (moduleId: number, tradeId?: number) => (dispatch) => {
	dispatch(httpCallStartAction());

	CompanyApi.GetCompanyByTradeAsync(moduleId, tradeId).then((content) => {
		dispatch(getTradeCompaniesCompleted(content));
	});
};
const getTradeCompaniesCompleted = (companyCollection: TradeCompany[]) => ({
	type: actionTypes.GET_COMPANIES_WITH_TRADE,
	payload: companyCollection,
	meta: {
		httpEnd: true,
	},
});
