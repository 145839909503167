import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DepartmentFilter } from '../../../model/filters';
import { DepartmentCollection } from '../../../model';
import { DepartmentsApi } from '../../../api/departments/departmentApi';

export const getDepartmentCollectionAction = (filter: DepartmentFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	dispatch(setDepartmentFilterCompleted(filter));
	DepartmentsApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
		dispatch(getDepartmentCollectionCompleted(content));
	});
};
const getDepartmentCollectionCompleted = (departmentCollection: DepartmentCollection) => ({
	type: actionTypes.GET_DEPARTMENT_COLLECTION,
	payload: departmentCollection,
	meta: {
		httpEnd: true,
	},
});

const setDepartmentFilterCompleted = (filter: DepartmentFilter) => ({
    type: actionTypes.SET_DEPARTMENTS_FILTER,
    payload: filter,
    meta: {
        httpEnd: true,
    },
});