import { DeedTypeApi } from '../../../api/deedType/deedTypeApi';
import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DeedTypeCollection } from '../../../model';
import { DeedTypeFilter } from '../../../model/filters';

export const getDeedTypeCollectionAction = (filter: DeedTypeFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	dispatch(setDeedTypeFilterCompleted(filter))
	DeedTypeApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
		dispatch(getDeedTypeCollectionCompleted(content));
	});
};
const getDeedTypeCollectionCompleted = (deedTypeCollection: DeedTypeCollection) => ({
	type: actionTypes.GET_DEED_TYPE_COLLECTION,
	payload: deedTypeCollection,
	meta: {
		httpEnd: true,
	},
});

const setDeedTypeFilterCompleted = (filter: DeedTypeFilter) => ({
    type: actionTypes.SET_DEED_TYPE_FILTER,
    payload: filter,
    meta: {
        httpEnd: true,
    },
});
