import { Icon } from 'office-ui-fabric-react/lib/Icon';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { languages } from '../../../common/const/resources';
import { isAj, onlyBodyTypesModule, onlyDocumentationModule } from '../../../common/const/rolesAssignments';
import { routeUrls } from '../../../common/const/routes';
import { NoResultsComponent } from '../../../common/noResults/noResults';
import { SpinnerContainer } from '../../../common/spinner';
import { UnauthorizedView } from '../../../common/unauthorizedView/unauthorizedView';
import { BaseName, BaseType, DocumentTypeSubType, DocumentsCollection, Language, User, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { UserRoles } from '../../../model/enums/userRoles';
import { DocumentationFilter } from '../../../model/filters/DocumentationFilter';
import * as utils from '../../../utils/extensions';
import DocumentationDataGrid from './grid';
import { DocumentationSearchComponent } from './search';

export interface IProps {
	documentsCollection: DocumentsCollection;
	user: User;
	currentUser: UserState;
	language: Language;
	companies: BaseName[];
	business: BaseType[];
	countries: BaseName[];
	originals: BaseType[];
	documentTypes: DocumentType[];
	documentSubtypes: DocumentTypeSubType[];
	filter: DocumentationFilter;
	getDocumentation: (filter: DocumentationFilter, pageNumber: number, itemsPage: number) => Promise<DocumentsCollection>;
	deleteDocumentation: (filter: DocumentationFilter, language: Language, id: number) => Promise<boolean>;
	getCompanies: () => Promise<BaseName[]>;
	getBusiness: () => Promise<BaseType[]>;
	getCountries: () => Promise<BaseName[]>;
	getOriginals: () => Promise<BaseType[]>;
	getDocumentTypePrimaries: () => Promise<DocumentType[]>;
	getDocumentTypeSubTypes: () => Promise<DocumentTypeSubType[]>;
	exportToExcel: (filter: DocumentationFilter) => Promise<any>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	collapsed: boolean;
	filter: DocumentationFilter;
}

export class DocumentationPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props: any) {
		super(props);

		this.state = {
			isAuthorized: false,
			isLoading: true,
			collapsed: false,
			filter: this.props.filter,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyDocumentationModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}

		let filter = this.props.filter
		if (this.props.currentUser?.currentUser?.roles.length > 0) {
			this.props.getDocumentation(filter, page, this.state.filter.ItemsPage);
			this.setState({ filter: filter });
		} else {
			this.props.getDocumentation(this.state.filter, page, this.state.filter.ItemsPage);
		}

		this.props.getCompanies();
		this.props.getBusiness();
		this.props.getCountries();
		this.props.getOriginals();
		this.props.getDocumentTypePrimaries();
		this.props.getDocumentTypeSubTypes();
	}

	public componentDidUpdate(prevProps) {
		if (this.props.currentUser !== prevProps.currentUser) {
			let isAuth = utils.isInRoles(onlyBodyTypesModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}

		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}

		if(this.props.business !== prevProps.business){
			if(utils.isInRoles(isAj)){
				let defaultOption = utils.getBusinessByRole(this.props.currentUser?.currentUser.roles[0]);
				if (defaultOption !== 0) {
					let filter = { ...this.state.filter };
					filter.TradeId = defaultOption;
					this.setState({ filter: filter }, () => this.props.getDocumentation(filter, page, this.props.filter.ItemsPage));
				}
			}	
		}		
		
		if(this.props.countries !== prevProps.countries){
			if (this.props.countries.length === 1) {
				let netherlands = this.props.countries.find(x => x.descriptionEnglish === "Netherlands")?.id;
				if(netherlands !== undefined){
					let filter = { ...this.state.filter };
					filter.CountryId = netherlands;
					this.setState({ filter: filter }, () => this.props.getDocumentation(filter, page, this.props.filter.ItemsPage));				}
			}
		}

		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if (newPage !== oldPage) {
			this.props.getDocumentation(this.props.filter, newPage, this.props.filter.ItemsPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);

		let lector: boolean = false;
		if (this.props.user.rol === UserRoles.reader) {
			lector = true;
		}

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized &&
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['documentation']['list']['title']}</h1>
							{!lector ? (
								<Link to='/documentation/new' className='button-addButton'>
									<Icon iconName='Add' />
									<span>{language['documentation']['list']['new-button']}</span>
								</Link>
							) : (
								<span></span>
							)}
						</div>
						<div className='ms-Grid'>
							<DocumentationSearchComponent
								filter={this.state.filter}
								onchangeFilter={this.handleFilterChange}
								language={this.props.language}
								itemsPage={this.state.filter.ItemsPage}
								companies={this.props.companies}
								business={this.props.business}
								countries={this.props.countries}
								originals={this.props.originals}
								documentTypes={this.props.documentTypes}
								documentSubtypes={this.props.documentSubtypes}
								getDocumentation={this.props.getDocumentation}
								user={this.props.currentUser}
								page={page}
								getCompanies={this.props.getTradeCompanies}
								tradeCompanies={this.props.tradeCompanies}
							/>
							{this.props.documentsCollection.totalNumberOfRows > 0 ?
								<DocumentationDataGrid
									documentsCollection={this.props.documentsCollection}
									user={this.props.user}
									language={this.props.language}
									filter={this.state.filter}
									page={page}
									getDocumentation={this.props.getDocumentation}
									deleteDocumentation={this.props.deleteDocumentation}
									exportToExcel={this.props.exportToExcel}
									currentUser={this.props.currentUser}
									onchangeFilter={this.handleFilterChange}
								/>
								:
								<NoResultsComponent
									language={this.props.language}
									list={language['documentation']['list']['title']}
								/>
							}
						</div>
					</>
				}
			</>
		);
	}

	public readonly handleFilterChange = (newfilter: DocumentationFilter): void => {
		this.setState({ filter: newfilter });
		this.props.history.push(routeUrls.documentationPagination + 1);
	};
}
