export enum userRoles {
    adminMaster = 'SG_BA0613_01_P',
    admin = 'SG_BA0613_02_P',
    ajAeropuertos = 'SG_BA0613_03_P',
    ajAeropuertosH = 'SG_BA0613_04_P',
    ajCintra = 'SG_BA0613_05_P',
    ajCintraH = 'SG_BA0613_06_P',
    ajConstruccion = 'SG_BA0613_07_P',
    ajConstruccionH = 'SG_BA0613_08_P',
    ajCorporacion = 'SG_BA0613_09_P',
    ajCorporacionH = 'SG_BA0613_10_P',
    tesoAeropuertos = 'SG_BA0613_11_P',
    tesoCintra = 'SG_BA0613_12_P',
    tesoConstruccion = 'SG_BA0613_13_P',
    tesoCorporacion = 'SG_BA0613_14_P',
    ajFeim = 'SG_BA0613_15_P',
    ajFeimH = 'SG_BA0613_16_P'
}