import * as React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';

export interface MessageBarProps {
	Type: MessageBarType;
	Message: string;
	MessageVisible: boolean;
	ClassName?: string;
	close(): void;
}
export class MessageBarComponent extends React.Component<MessageBarProps, {}> {
	/* constructor(props) {
		super(props);
	}		Clean Warning Code */

	public render() {
		if (this.props.MessageVisible) {
			return (
				<div className={this.props.ClassName}>
					<MessageBar
						onDismiss={
							this.props.close != null
								? () => {
									this.props.close();
								}
								: null
						}
						messageBarType={this.props.Type}
						isMultiline={false}
					>
						{this.props.Message}
					</MessageBar>
				</div>
			);
		} else {
			return <div></div>;
		}
	}
}
