import * as React from 'react';
import { HeaderComponentContainer } from './header/pageContainer';
import { ApplicationProfile, Language } from '../model';
import { Account } from 'msal';
import { UnauthorizedView } from '../common/unauthorizedView/unauthorizedView';
import { UnauthenticatedView } from '../common/unauthenticathedView/unauthenticathedView';
import * as msal from  '../common/security/msal/msal';

export interface LayoutProps {
	children?: React.ReactNode;
	isUserAuthenticathed: boolean;
	fetchUserAuthorize: (account: Account, token: string) => void;
	currentUser: ApplicationProfile;
	account: Account;
	token: string;
	language: Language;
}

export class Layout extends React.Component<LayoutProps, {}> {
	public componentDidMount = async () => {
		if (this.props.isUserAuthenticathed) {
			await this.props.fetchUserAuthorize(this.props.account, this.props.token);
		}else{
			msal.authProvider.login()
			await this.props.fetchUserAuthorize(msal.authProvider.getAccount(), (await msal.authProvider.getAccessToken()).accessToken);
		}
	};

	public componentDidUpdate = (prevProps: LayoutProps) => {
		if (this.props.currentUser && this.props.currentUser !== prevProps.currentUser) {
		}
	};

	public render() {
		return (
			<div className='main'>
				<HeaderComponentContainer userName={this.props.account?.name} users={this.props.currentUser} />
				{this.props.currentUser?.roles === undefined || 
					(this.props.currentUser?.roles?.length === 0 && <UnauthorizedView language={this.props.language} />)}
				{this.props.currentUser?.roles?.length > 0 && (
					<div className='ms-Grid main-container'>
						<div className='ms-Grid-col ms-sm12 ms-lg1 ms-xl1'></div>
						<div className='ms-Grid-col ms-sm12 ms-lg10 ms-xl10'>{this.props.children}</div>
					</div>
				)}
				{!this.props.isUserAuthenticathed && <UnauthenticatedView language={this.props.language} />}
				{(this.props.currentUser === undefined || this.props.currentUser === null) && (<UnauthenticatedView language={this.props.language} />)}
			</div>
		);
	}
}
