import { actionTypes } from '../../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { BodyTypesCollection } from '../../../../model';
import { BodyTypesFilter } from '../../../../model/filters/BodyTypesFilter';
import { BodyApi } from '../../../../api/body';

export const getBodyTypesAction = (filter: BodyTypesFilter, actualPage: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	dispatch(setBodyTypeFilterCompleted(filter));
	BodyApi.FilterAsync(filter, actualPage, itemsPage).then((content) => {
		dispatch(getBodyTypesCompletedAction(content));
	});
};

const getBodyTypesCompletedAction = (bodyTypeCollection: BodyTypesCollection) => ({
	type: actionTypes.GET_BODY_TYPES,
	payload: bodyTypeCollection,
	meta: {
		httpEnd: true,
	},
});

const setBodyTypeFilterCompleted = (filter: BodyTypesFilter) => ({
    type: actionTypes.SET_BODY_TYPE_FILTER,
    payload: filter,
    meta: {
        httpEnd: true,
    },
});
