import { actionTypes } from '../common/const/actionTypes';
import {
	createEmptyDeedType,
	createEmptyDeedTypeCollection,
	createEmptyDeedTypeSubType,
	DeedType,
	DeedTypeCollection,
	DeedTypeSubType,
} from '../model/DeedType';
import { createEmptyDeepTypeFilter, DeedTypeFilter } from '../model/filters';

export const deedTypeFilterReducer = (state = createEmptyDeepTypeFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_DEED_TYPE_FILTER:
			return handleSetDeedTypeFilterCompleted(state, action.payload);
	}

	return state;
};
export const deedTypeCollectionReducer = (state = createEmptyDeedTypeCollection(), action) => {
	switch (action.type) {
		case actionTypes.GET_DEED_TYPE_COLLECTION:
			return handleGetDeedTypeCollectionCompleted(state, action.payload);
	}

	return state;
};

export const deedTypeReducer = (state = createEmptyDeedType(), action) => {
	switch (action.type) {
		case actionTypes.GET_DEED_TYPE:
			return handleGetDeedTypeCompleted(state, action.payload);
	}

	return state;
};
export const deedTypePrimariesReducer = (state = [createEmptyDeedType()], action) => {
	switch (action.type) {
		case actionTypes.GET_DEED_TYPE_PRIMARIES:
			return handleGetDeedTypePrimariesCompleted(state, action.payload);
	}

	return state;
};
export const deedTypeSubTypesReducer = (state = [createEmptyDeedTypeSubType()], action) => {
	switch (action.type) {
		case actionTypes.GET_DEED_TYPE_SUBTYPES:
			return handleGetDeedTypeSubTypesCompleted(state, action.payload);
	}

	return state;
};

export const deepTypeCrudReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.SAVE_DEED_TYPE_COMPLETED:
			return handleSaveDeedTypeCompleted(state, action.payload);
		case actionTypes.DELETE_DEED_TYPE:
			return handleDeleteDeepTypeCompleted(state, action.payload);
	}

	return state;
};

const handleGetDeedTypeCollectionCompleted = (state: DeedTypeCollection, payload: DeedTypeCollection) => {
	return payload;
};

const handleGetDeedTypeCompleted = (state: DeedType, payload: DeedType) => {
	return payload;
};
const handleGetDeedTypePrimariesCompleted = (state: DeedType[], payload: DeedType[]) => {
	return payload;
};
const handleGetDeedTypeSubTypesCompleted = (state: DeedTypeSubType[], payload: DeedTypeSubType[]) => {
	return payload;
};

const handleDeleteDeepTypeCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleSaveDeedTypeCompleted = (state: number, payload: number) => {
	return payload;
};
const handleSetDeedTypeFilterCompleted = (state: DeedTypeFilter, payload: DeedTypeFilter) => {
	return payload;
};
