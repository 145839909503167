import { DocumentTypeApi } from '../../../api/documentType/documentTypeApi';
import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DocumentTypeCollection } from '../../../model';
import { DocumentTypeFilter } from '../../../model/filters';

export const getDocumentTypeCollectionAction = (filter: DocumentTypeFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	dispatch(setDocumentTypeFilterCompleted(filter));

	DocumentTypeApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
		dispatch(getDocumentTypeCollectionCompleted(content));
	});
};
const getDocumentTypeCollectionCompleted = (documentTypeCollection: DocumentTypeCollection) => ({
	type: actionTypes.GET_DOCUMENT_TYPE_COLLECTION,
	payload: documentTypeCollection,
	meta: {
		httpEnd: true,
	},
});

const setDocumentTypeFilterCompleted = (filter: DocumentTypeFilter) => ({
	type: actionTypes.SET_DOCUMENT_TYPE_FILTER,
	payload: filter,
	meta: {
		httpEnd: true,
	},
});
