import { PeopleApi } from '../../../../api/people';
import { actionTypes } from '../../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getNameAzureAdAction = (email: string) => (dispatch) => {
	dispatch(httpCallStartAction());
	return PeopleApi.FindInAzureAD(email).then((result) => {
		dispatch(getNameAdCompleted(result));
		return result;
	});
};

const getNameAdCompleted = (result: string) => ({
	type: actionTypes.GET_NAME_AD,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
