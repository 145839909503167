import { actionTypes } from '../../../../common/const/actionTypes';
import { NotaryPeopleApi } from '../../../../api/notaryPeople';
import { NotaryPeople } from '../../../../model/NotaryPeople';
import { NotaryPeopleFilter } from '../../../../model/filters/NotaryPeopleFilter';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getNotaryPeopleAction = (filter: NotaryPeopleFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
    dispatch(httpCallStartAction());
    dispatch(setNotaryFilterCompleted(filter));
    NotaryPeopleApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
        dispatch(getNotaryPeopleCompleted(content));
    });
};

const getNotaryPeopleCompleted = (notaryPeopleCollection: NotaryPeople[]) => ({
    type: actionTypes.GET_NOTARYPEOPLE,
    payload: notaryPeopleCollection,
    meta: {
        httpEnd: true,
    }
});

const setNotaryFilterCompleted = (filter: NotaryPeopleFilter) => ({
    type: actionTypes.SET_NOTARYPERSON_FILTER,
    payload: filter,
    meta: {
        httpEnd: true,
    },
});
    