import { DeedTypeApi } from '../../../../api/deedType/deedTypeApi';
import { actionTypes } from '../../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { DeedType } from '../../../../model';

export const getDeedTypePrimariesAction = () => (dispatch) => {
    dispatch(httpCallStartAction());

    DeedTypeApi.GetDeedTypePrimaries().then((result) => {
        dispatch(getDeedTypePrimariesCompleted(result));
    });
};

const getDeedTypePrimariesCompleted = (deedTypeNames: DeedType[]) => ({
    type: actionTypes.GET_DEED_TYPE_PRIMARIES,
    payload: deedTypeNames,
    meta: {
        httpEnd: true,
    }
});