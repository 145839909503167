import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/components/TextField';
import * as React from 'react';
import { languages } from '../../../common/const/resources';
import { aJNoCoporacion } from '../../../common/const/rolesAssignments';
import { CustomSelect, ISelectOption } from '../../../common/customSelect/customSelect';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import { SearchPeople } from '../../../common/search/searchPeople';
import { BaseType, CompanyBase, Language, Person, PositionCollection, SearchSelectModel, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { PositionFilter, createEmptyPositionFilter } from '../../../model/filters/PositionFilter';
import * as utils from '../../../utils/extensions';

export interface IProps {
	peopleNames: Person[];
	positionTypes: BaseType[];
	positionTypeNames: BaseType[];
	companies: CompanyBase[];
	language: Language;
	filter: PositionFilter;
	onChangeFilter: any;
	itemsPage: number;
	user: UserState;
	getPositions: (filter: PositionFilter, pageNumbers: number, itemsPage: number) => Promise<PositionCollection>;
	getPeopleNames: (filter: string) => Promise<Person[]>;
}

export interface IState {
	filter: PositionFilter;
	selectedPerson: any;
	collapsed: boolean;
	showDissolved: boolean;
}

export class PositionSearchComponent extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			filter: createEmptyPositionFilter(),
			selectedPerson: null,
			showDissolved: true,
		};
	}

	componentDidMount() {
		let selectedPerson = null;
		let filter = {...this.props.filter}
		if (this.props.filter.PersonId > 0) {
			selectedPerson = { value: this.props.filter.PersonId, label: this.props.filter.Name };
		}
		// if (utils.isInRoles(aJNoCoporacion)) {
		// 	let defaultOption = utils.getBusinessByRole(this.props.user.currentUser.roles[0]);
		// 	if (defaultOption !== 0) {
		// 		filter.TradeId = defaultOption;
		// 	}
		// }
		this.setState({ selectedPerson: selectedPerson, filter: filter });
	}

	componentDidUpdate(prevProps, prevState) {
		let filter = { ...this.props.filter };
		if (this.props.positionTypeNames !== prevProps.positionTypeNames) {
			if (utils.isInRoles(aJNoCoporacion)) {
				let defaultOption = utils.getBusinessByRole(this.props.user.currentUser.roles[0]);
				if (defaultOption !== 0) {
					filter.TradeId = defaultOption;
				}
			}
			this.setState({filter: filter },()=> this.props.getPositions(filter, 1, this.props.itemsPage));
		}	
	} 

	

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let users: SearchSelectModel[] = [];
		if (this.props.peopleNames && this.props.peopleNames.length > 0) {
			this.props.peopleNames.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		let companies: IComboBoxOption[] = [];
		if (this.props.companies) {
			companies.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
			companies.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			this.props.companies.forEach((item) => {
				companies.push({
					key: item.id,
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null,
				});
			});
		}

		let PositionTypes: IComboBoxOption[] = [];
		if (this.props.positionTypeNames) {
			
			this.props.positionTypeNames.forEach((item) => {
				PositionTypes.push({
					key: item.id,
					text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish,
				});
			});
			PositionTypes.sort((a, b) => a.text.localeCompare(b.text))
			PositionTypes.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			PositionTypes.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}

		let states: IComboBoxOption[] = [];
		states.push(
			{ key: -1, text: language['generic']['form']['combo-box']['default'] },
			{ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
			{ key: 1, text: language['positions']['list']['filter']['options']['status']['current'] },
			{ key: 2, text: language['positions']['list']['filter']['options']['status']['notCurrent'] },
		);


		// const onRenderOption = (option: any): JSX.Element => {
		// 	if (option.itemType == DropdownMenuItemType.Header) {
		// 		if(option.key == 0){
		// 			return (
		// 				<Toggle 
		// 				inlineLabel 
		// 				label={language['SocietiesCombo']['showDissolved']} 
		// 				checked = {this.state.showDissolved}
		// 				onChange={(event, checked)=>{this.setState({showDissolved: checked})}} />
		// 			);
		// 		}
		// 		else{	
		// 			return (
		// 				<table>
		// 					<tbody>
		// 						<tr>
		// 							<td style={{ width: 15, fontSize: 14, padding: 5 }}></td>
		// 							<td style={{ width: 200, fontSize: 14, padding: 0 }}>{option.key}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: 0 }}>{option.text}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: '0px 10px' }}>{option.title}</td>
		// 						</tr>
		// 					</tbody>
		// 				</table>
		// 			);
		// 		}
		// 	} else {
		// 		return (
		// 			<table>
		// 				<tbody>
		// 					<tr>
		// 						<td style={{ width: 200, fontSize: 14, padding: 5 }}>{option.text}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.title}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.id}</td>
		// 					</tr>
		// 				</tbody>
		// 			</table>
		// 		);
		// 	}
		// };

		let sortedCompanies = companies.sort((a, b) => a.text.localeCompare(b.text));
		// sortedCompanies.unshift({
		// 	key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'],
		// 	itemType: DropdownMenuItemType.Header,
		// });
		// sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });		

		let selectedCompanies= (this.state.showDissolved) ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title === language['SocietiesCombo']['dissolutionDate'])
		let options: ISelectOption[] = selectedCompanies.map(company => {
			return {
				value:company.key.toString(),
				label:company.text, 
				disolutionDate:company.id, 
				liquidationDate: company.title
			};
		  });

		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div className={this.state.collapsed ? 'ms-Grid-row filter_header-collapse' : 'ms-Grid-row filter_header'}>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<SearchPeople
									title={language['positions']['list']['filter']['options']['name']['title']}
									placeholder={language['positions']['list']['filter']['options']['name']['placeholder']}
									language={this.props.language}
									selectedOption={utils.isNullOrEmpty(this.state.filter.Name) ? null : this.state.selectedPerson}
									loadOptions={this.loadOptions}
									handleNameChange={this.handlePersonNameChange}
									getUsers={this.props.getPeopleNames}
									onClearValue={this.onClearSearchPeople}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['positions']['list']['filter']['options']['nif']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Dni}
									onChange={this.handleDniChange}
									onBlur={this.handleDniBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['positions']['list']['filter']['options']['cif']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Cif}
									onChange={this.handleCifChange}
									onBlur={this.handleCifBlur}
								/>
							</div>
							{/* <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['positions']['list']['filter']['options']['society']['title']}</p>
								<VirtualizedComboBox
									autoComplete='on'
									allowFreeform={false}
									options={this.state.showDissolved ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title == language['SocietiesCombo']['dissolutionDate'])}
									placeholder={language['positions']['list']['filter']['options']['society']['placeholder']}
									onChange={this.handleSocietyChange}
									selectedKey={this.state.filter.CompaniesId}
									onRenderOption={onRenderOption}
									multiSelect={true}
								/>
							</div> */}
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['positions']['list']['filter']['options']['society']['title']}</p>
								<CustomSelect
									options={options}
									handleCustomSelectChange={this.handleReactSelectCompanyChange}
									showDissolved={this.state.showDissolved}
									handleShowDissolvedChange={(event, checked)=>{this.setState({showDissolved: checked})}}
									language={language}
									showDissolutionDate={true}
									selectedValues={this.state.filter.selectedCompanies}
									isMultiSelection={true}
									isDisabled={false}
									placeholder={language['positions']['list']['filter']['options']['society']['placeholder']}
								/>
							</div>							
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['positions']['list']['filter']['options']['position']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={PositionTypes}
									placeholder={language['positions']['list']['filter']['options']['status']['placeholder']}
									onChange={this.handlePositionChange}
									selectedKey={this.state.filter.PositionTypeId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['positions']['list']['filter']['options']['status']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={states}
									placeholder={language['positions']['list']['filter']['options']['status']['placeholder']}
									onChange={this.handleStateChange}
									selectedKey={this.state.filter.StateId}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterPositions}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterPositions}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.filter.Name !== '' ? '' : 'none' }}>
								<span className='key'>{language['positions']['list']['filter']['options']['name']['title']}</span>
								<span className='value'>{this.state.filter.Name}</span>
							</p>
							<p style={{ display: this.state.filter.Dni !== '' ? '' : 'none' }}>
								<span className='key'>{language['positions']['list']['filter']['options']['dni']['title']}</span>
								<span className='value'>{this.state.filter.Dni}</span>
							</p>
							<p style={{ display: this.state.filter.Cif !== '' ? '' : 'none' }}>
								<span className='key'>{language['positions']['list']['filter']['options']['cif']['title']}</span>
								<span className='value'>{this.state.filter.Cif}</span>
							</p>
							<p style={{ display: this.state.filter.CompaniesId.length > 0 ? '' : 'none' }}>
								<span className='key'>{language['positions']['list']['filter']['options']['society']['title']}</span>
								<span className='value'>{this.state.filter.CompaniesName?.join(", ")}</span>
							</p>
							<p style={{ display: this.state.filter.PositionTypeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['positions']['list']['filter']['options']['position']['title']}</span>
								<span className='value'>{this.state.filter.PositionTypeName}</span>
							</p>
							<p style={{ display: this.state.filter.StateId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['positions']['list']['filter']['options']['status']['title']}</span>
								<span className='value'>{this.state.filter.State}</span>
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	private readonly handlePersonNameChange = (selectedPerson) => {
		let filter = { ...this.state.filter };
		filter.PersonId = parseInt(selectedPerson?.value);
		filter.Name = selectedPerson?.label;
		this.setState({ filter: filter, selectedPerson: selectedPerson });
	};

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filterColors(inputValue));
		}, 1000);
	};

	private readonly filterColors = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.peopleNames && this.props.peopleNames.length > 0) {
			this.props.peopleNames.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};

	private readonly onClearSearchPeople = (): void => {
		let filter = { ...this.state.filter };
		let selectedPerson = { ...this.state.selectedPerson };
		filter.PersonId = null;
		filter.Name = null;
		selectedPerson.value = null;
		selectedPerson.label = null;
		this.setState({ filter: filter, selectedPerson: selectedPerson });
	};

	// public readonly handleSocietyChange = (event, option): void => {
	// 	let filter = { ...this.state.filter };
	// 	if (option.selected === true) {
	// 		filter.CompaniesId.push(option.key);
	// 		filter.CompaniesName.push(option.text);
	// 	} else {
	// 		filter.CompaniesId = filter.CompaniesId.filter((e) => e !== option.key);
	// 		filter.CompaniesName = filter.CompaniesName.filter((e) => e !== option.text);
	// 	}
	// 	this.setState({ filter: filter });
	// };

	private readonly handleReactSelectCompanyChange = (values) => {

		let filter = { ...this.state.filter };

		filter.CompaniesId.length = 0;
		filter.CompaniesName.length = 0;
		filter.selectedCompanies.length = 0;

		if(values!==undefined && values!==null){

			values.forEach((item) => {
				filter.selectedCompanies.push({value:item.value.toString(), label:item.label, disolutionDate:item.disolutionDate, liquidationDate: item.liquidationDate});
				filter.CompaniesId.push(item.value);
				filter.CompaniesName.push(item.label);
			})
		}

		this.setState({ filter: filter });
	};		

	public readonly handleCifChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.Cif = value.target.value;
		this.setState({ filter: filter });
	};
	public readonly handleCifBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.Cif = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	public readonly handleDniChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.Dni = value.target.value;
		this.setState({ filter: filter });
	};
	public readonly handleDniBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.Dni = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	public readonly handlePositionChange = (event, option): void => {
		let filter = { ...this.state.filter };
		filter.PositionTypeId = parseInt(option?.key);
		filter.PositionTypeName = option?.text;
		this.setState({ filter: filter });
	};

	public readonly handleStateChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.StateId = parseInt(option?.key);
		filter.State = option?.text;
		this.setState({ filter: filter });
	};

	public readonly filterPositions = () => {
		let filter = { ...this.state.filter };
		this.setState({ collapsed: true });
		this.props.onChangeFilter(filter);
		this.props.getPositions(filter, 1, this.props.itemsPage);
	};

	private readonly cleanFilterPositions = () => {
		let resetFilter = createEmptyPositionFilter();
		this.setState({ filter: resetFilter });
		this.props.onChangeFilter(resetFilter);
		this.props.getPositions(resetFilter, 1, this.props.itemsPage);
	};

	private readonly handleFiltersCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};
}
