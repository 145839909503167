import { actionTypes } from '../../../../common/const/actionTypes';
import { PositionFilter } from '../../../../model/filters/';
import { PositionCollection } from '../../../../model/';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { PositionApi } from '../../../../api/positions'

export const getPositionsAction = (filter: PositionFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
    dispatch(httpCallStartAction());
    dispatch(setPositionFilterCompleted(filter));
    PositionApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
        dispatch(getPositionsCompleted(content));
    });
};
const getPositionsCompleted = (positionCollection
    : PositionCollection) => ({
        type: actionTypes.GET_POSITIONS,
        payload: positionCollection,
        meta: {
            httpEnd: true,
        }
    });

const setPositionFilterCompleted = (filter: PositionFilter) => ({
    type: actionTypes.SET_POSITION_FILTER,
    payload: filter,
    meta: {
        httpEnd: true,
    },
});
    
