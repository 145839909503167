import { connect } from 'react-redux';
import { DeedType, Language } from '../../model';
import { DeedTypeFilter } from '../../model/filters';
import { StateReducer } from '../../reducers';
import {
    deleteDeedTypeAction, exportToExcelAction, getDeedTypeAction, getDeedTypeCollectionAction,
    getDeedTypePrimariesAction,
    getDeedTypeSubTypeAction,
    saveDeedTypeAction
} from './actions';
import { DeedTypeHome } from './deedTypeHome';

const mapStateToProps = (state: StateReducer) => ({
    language: state.language,
    user: state.user,
    deedType: state.deedType,
    deedTypeCollection: state.deedTypeCollection,
    deedTypeFilter: state.deedTypeFilter,
    deedTypeResponse: state.deedTypeResponse,
    currentUser: state.users,
    deedTypeSubTypes: state.deedTypeSubTypes,
    deedTypePrimaries: state.deedTypePrimaries,
    filter: state.deedTypeFilter
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteDeedType: (filter: DeedTypeFilter, language: Language, id: number) => dispatch(deleteDeedTypeAction(filter, language, id)),
    exportToExcel: (filter: DeedTypeFilter) => dispatch(exportToExcelAction(filter)),
    getDeedType: (id: number) => dispatch(getDeedTypeAction(id)),
    getDeedTypeCollection: (filter: DeedTypeFilter, pageNumber: number, itemsPage: number) => dispatch(getDeedTypeCollectionAction(filter, pageNumber, itemsPage)),
    saveDeedType: (deedType: DeedType, language: Language) => dispatch(saveDeedTypeAction(deedType, language)),
    getDeedTypePrimaries: () => dispatch(getDeedTypePrimariesAction()),
    getDeedTypeSubTypes: () => dispatch(getDeedTypeSubTypeAction())
});

export const DeedTypesContainer = connect(mapStateToProps, mapDispatchToProps)(DeedTypeHome);
