import { OrderBy } from '../filters/OrderBy';
import { ActivePersonOrderFilter } from '../filters/ActivePersonOrderFilter';

export interface PersonFilter {
	NameOrder: OrderBy;
	DniOrder: OrderBy;
	EmailOrder: OrderBy;
	PassportOrder: OrderBy;
	NacionalityOrder: OrderBy;
	TradeOrder: OrderBy;
	Name: string;
	DNI: string;
	Email: string;
	TradeId: number;
	TradeName?: string;
	PersonId: number;

	CurrentFilter: ActivePersonOrderFilter;

	ItemsPage: number;
}
export const createEmptyPersonFilter = (): PersonFilter => {
	return {
		NameOrder: OrderBy.Asc,
		DniOrder: OrderBy.Asc,
		EmailOrder: OrderBy.Asc,
		PassportOrder: OrderBy.Asc,
		NacionalityOrder: OrderBy.Asc,
		TradeOrder: OrderBy.Asc,
		Name: '',
		DNI: '',
		Email: '',
		TradeId: 0,
		TradeName: '',
		PersonId: 0,

		CurrentFilter: ActivePersonOrderFilter.Name,
		ItemsPage: 10,
	};
};
