import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Constants } from '../../../common/const/constants';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { SpinnerContainer } from '../../../common/spinner';
import { Language, PositionDetail } from '../../../model';
import { PositionDetailFilter, createEmptyPositionDetailFilter } from '../../../model/filters/';
import { formatNameCapitalize, getResourcesFile } from '../../../utils/extensions';
import { PositionDetailGrid } from './grid';

export interface IProps {
	position: PositionDetail;
	positionDetailFilter: PositionDetailFilter;
	language: Language;
	pdfDownloadLink: string;
	getPosition: (filter: PositionDetailFilter, pageNumber: number, itemsPage: number) => Promise<PositionDetail>;
	exportToPdf: (personId: number) => Promise<string>;
}

export interface IState {
	filter: PositionDetailFilter;
	itemsPage: number;
	previousPage: string;
}

export class PositionDetailPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.goBack = this.goBack.bind(this);
		this.state = {
			filter: {
				PersonId: this.props.positionDetailFilter.PersonId,
			},
			itemsPage: 10,
			previousPage: new URL(window.location.href).searchParams.get("previousPage")
		};
	}

	public componentDidMount() {
		let page = 1;
		let personId = 0;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		if (Number(this.props.match.params['id']) > 0) {
			personId = Number(this.props.match.params['id']);
		}
		if (this.props.positionDetailFilter && this.props.positionDetailFilter.PersonId !== 0) {
			this.props.getPosition(this.state.filter, page, Constants.ItemsPerPage);
		} else {
			let filter: PositionDetailFilter = createEmptyPositionDetailFilter();
			filter.PersonId = personId;
			this.props.getPosition(filter, page, Constants.ItemsPerPage);
			this.setState({ filter: filter });
		}
	}

	public componentDidUpdate(prevProps) {
		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if (newPage !== oldPage) {
			this.props.getPosition(this.state.filter, newPage, Constants.ItemsPerPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);
		let firstPositionEntry: PositionDetail = null;
		if (this.props.position) {
			firstPositionEntry = this.props.position;
		}

		let personName: string = '';
		let personDni: string = '';
		let personAddress: string = '';
		let personNationality: string = '';

		if (firstPositionEntry != null) {
			personName = formatNameCapitalize(firstPositionEntry?.name);
			personDni = firstPositionEntry.dni;
			personAddress = firstPositionEntry.address;
			personNationality = firstPositionEntry.nationality;
		}

		let language = getResourcesFile(this.props.language);
		return (
			<>
				<SpinnerContainer message='Cargando...' />
				<div className='main-container__pageTitle detail_title'>
					<span onClick={this.goBack} className='back_arrow'>
						<Icon iconName='ChromeBack' />
					</span>
					<h1>{language['positions']['detail']['title']}</h1>
					<DefaultButton className='button-download' onClick={this.handleExportClick}>
						<i className='ms-Icon ms-Icon--PDF'></i>
						<span>{language['positions']['detail']['export-button']}</span>
					</DefaultButton>
				</div>
				<div id='divToPrint'>
					<div id='ms-Grid-row' className='ms-Grid-row representative_info_row'>
						<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 main-container__content_edit_block'>
							<div className='detail_representative_name'>
								<p>{personName}</p>
							</div>
							<div className='personal_data'>
								<p>
									{language['positions']['detail']['fields']['personal-data']['dni']}
									<b>{personDni}</b>
								</p>
								<p>
									{language['positions']['detail']['fields']['personal-data']['personal-address']}
									<b>{personAddress}</b>
								</p>
								<p>
									{language['positions']['detail']['fields']['personal-data']['nacionality']}
									<b>{personNationality}</b>
								</p>
							</div>
						</div>
					</div>
					<div className='ms-Grid-row grid_standard_width'>
						<div className='ms-Grid-col ms-sm12 main-container__content_edit_block grid_title_count'>
							<p>
								{this.props.position.totalNumberOfRows > 1 ? (
									<span>
										{this.props.position?.totalNumberOfRows}{' '}
										{language['positions']['detail']['fields']['table']['results']}
									</span>
								) : this.props.position?.totalNumberOfRows === 1 ? (
									<span>
										{this.props.position?.totalNumberOfRows}{' '}
										{language['positions']['detail']['fields']['table']['result']}
									</span>
								) : (
									<span>{language['positions']['detail']['fields']['table']['noResults']}</span>
								)}
							</p>
						</div>
						<PositionDetailGrid positionInfo={this.props.position} language={this.props.language} />
					</div>
					<div className='ms-Grid-row'>
						<div className='ms-Grid-col ms-sm12 ms-md8'></div>
						<div className='ms-Grid-col ms-sm12 ms-4'>
							{this.props.position?.totalNumberOfRows > 0 ? (
								<AdvancedPagination
									currentPage={page}
									items={this.props.position?.totalNumberOfRows}
									link={`/positions/detail/${this.state.filter.PersonId}/`}
									updateMaxPageItems={this.handleItemsPerPageChange}
									language={this.props.language}
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</>
		);
	}

	public goBack() {
		this.props.history.push(this.state.previousPage);
	}

	public handleExportClick = () => {
		let filter: PositionDetailFilter = { ...this.state.filter };
		this.props.exportToPdf(filter.PersonId);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		this.setState({ itemsPage: itemsPage });
		this.props.getPosition(this.state.filter, 1, itemsPage);
	};
}
