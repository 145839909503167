import { SocietyTypes } from './enums/societyTypes';
import { ActionTypes } from './enums/actionTypes';

export interface CompanyBase {
	id: number;
	name: string;
	tradeSpanish: string;
	tradeEnglish: string;
	cif: string;
	countrySpanish: string;
	countryEnglish: string;
	cnae: string;
	codeLEI: string;
	socialAddress?: string;
	constitutionDate?: Date;
	fechaDisolucion?: Date
	fechaLiquidacion?: Date
}

export interface CompanyDetail extends CompanyBase {
	societyType: SocietyTypes;
	legalFormId: number;
	countryId: number;
	currencyId?: number;
	branchId?: number;
	titlesIssued: boolean;
	actionType?: ActionTypes;
}

export interface CompanyCollection {
	list: CompanyBase[];
	totalNumberOfRows: number;
}

export interface RecordCompanyCustom {
	companyDetails: RecordCompanyDetails;
}

export interface RecordCompanyDetails {
	id: number;
	cif: string;
	cifEuropeo: string;
	interlocutor: string;
	name: string;
	tradeSpanish: string;
	tradeEnglish: string;
	ute: boolean;
	socialCapital: number;
	socialAddress: string;
	countrySpanish: string;
	countryEnglish: string;
	location: string;
	address: string;
	number: number;
	postalCode: string;
	notary: string;
	notaryDate: Date;
	protocolNumber: string;
	merchandRecord: string;
	volume: string;
	section: string;
	folio: string;
	sheet: string;
	inscriptionDate: Date;
	positionCompany: AdminBodyCompany[];
	positionCompanyTotalNumberRows: number;
	stockholderCompany: StockholderCompany[];
	stockholderCompanyTotalNumberRows: number;
	historicalStockholderCompany: StockholderCompany[];
	historicalStockholderCompanyTotalNumberRows: number;
	constitutionDate?: Date;
}

export interface AdminBodyCompany {
	id: number;
	name: string;
	appointmentDate: Date;
	dateAppointmentFinal: Date;
	positionTypeId: string;
	positionTypeDescriptionSpanish: string;
	positionTypeDescriptionEnglish: string;
}

export interface StockholderCompany {
	code: string;
	companyId: number;
	name: string;
	stockFrom: Date;
	stockTo: Date;
	percentage: number;
	total: number;
}
export interface CompanySearch{
	id: number;
	name: string;
	interlocutor: string;
}
export interface Company {
	id: number;
	name: string;
	interlocutor: string;
	countryId: number;
	cif: string;
	cifEuropeo: string;
	cnae: string;
	codeLEI: string;
	socialAddress: string;
	legalFormId: number;
	country: string;
	currencyId: number;
	branchId: number;
	tradeSpanish: string;
	tradeEnglish: string;
	tradeId: number;
	socialCapitalId: number;
	createdOn: Date;
	createdBy: string;
	createdByDisplayName: string;
	modifiedOn: Date;
	modifiedBy: string;
	modifiedByDisplayName: string;
}

export interface AudityCompany {
	id: number;
	name: string;
	companyName: string;
	namedExercises: string;
}

export interface SocialCapitalBlock {
	socialCapital: string;
	socialCapitalRecords: RecordSocialCapital[];
}

export interface RecordSocialCapital {
	socialCapitalSecurities: string;
	socialCapitalDisbursement: string;
	socialCapitalNominal: string;
	socialCapitalTotalNumberStock: number;
	socialCapitalLastNumbering: string;
}

export interface TradeCompany {
	id: number;
	name: string;
	tradeId: number;
	cif: string;
	interlocutor: string;
}

export interface PowersRevokeRecordCompany extends CompanySearch {
	revokeMsg: string;
}

export const createEmptyTradeCompanyList = (): TradeCompany[] => {
	return [];
};

export const createEmptyCompanies = (): CompanyCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};
export const createEmptyCompany = (): CompanyBase => {
	return {
		cif: '',
		cnae: '',
		codeLEI: '',
		constitutionDate: null,
		id: 0,
		name: '',
		socialAddress: '',
		tradeEnglish: '',
		tradeSpanish: '',
		countrySpanish: '',
		countryEnglish: '',
	};
};
export const createEmptyCompanySearch = (): CompanySearch => {
	return {
		id: 0,
		name: '',
		interlocutor: '',
	};
};