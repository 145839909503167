import * as fetch from 'isomorphic-fetch';
import { SiteProps } from '../../model/Config';
import { ApiUrls } from '../../common/const/api';
import { OrderBy } from '../../model/filters/OrderBy';
import * as utils from '../../utils/extensions';
import { PositionCollection, PositionDetail } from '../../model/';
import { PositionDetailFilter, PositionFilter } from '../../model/filters/';
import { ActivePositionOrderFilter } from '../../model/filters/';
import * as msal from '../../common/security/msal';

const FilterAsync = async (filter: PositionFilter, pageNumber: number, itemsPage: number): Promise<PositionCollection> => {
	let baseUrl = `${SiteProps.SiteURL}/api/${ApiUrls.Positions}/GetByFilter`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActivePositionOrderFilter.CompanyName:
			queryString += '&CompanyNameOrder=';
			if (filter.CompanyNameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActivePositionOrderFilter.PositionTypeSpanish:
			queryString += '&PositionTypeNameSpanishOrder=';
			if (filter.PositionTypeNameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActivePositionOrderFilter.PositionTypeEnglish:
			queryString += '&PositionTypeNameEnglishOrder=';
			if (filter.PositionTypeNameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		case ActivePositionOrderFilter.Dni:
			queryString += '&DniOrder=';
			if (filter.DniOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
		default:
			queryString += '&NameOrder=';
			if (filter.NameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
	}

	if (filter.CompaniesId.length > 0) {
		queryString += '&CompaniesId=' + filter.CompaniesId.toString() + '';
	}
	if (!utils.isNullOrEmpty(filter.Cif)) {
		queryString += '&Cif=' + encodeURIComponent(filter.Cif) + '';
	}
	if (!utils.isNullOrEmpty(filter.Dni)) {
		queryString += '&Dni=' + encodeURIComponent(filter.Dni) + '';
	}
	if (!utils.isNullOrEmpty(filter.Name)) {
		queryString += '&Name=' + encodeURIComponent(filter.Name) + '';
	}
	if (filter.PositionTypeId > 0) {
		queryString += '&PositionTypeId=' + filter.PositionTypeId + '';
	}
	if (filter.Valid != null) {
		queryString += '&Valid=' + filter.Valid + '';
	}
	if (!utils.isNullOrEmpty(filter.State)) {
		queryString += '&State=' + encodeURIComponent(filter.State) + '';
	}
	if (filter.TradeId > 0) {
		queryString += '&TradeId=' + filter.TradeId + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
};

// async function GetPosition(personId: number): Promise<PositionDetail> {
//     let baseUrl = `${SiteProps.SiteURL}/api/${ApiUrls.Positions}/GetRecordPosition`;

//     const token = await msal.authProvider.getIdToken();

//     let obj: RequestInit = {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token.idToken.rawIdToken}`,
//         },
//     };
//     let url = `${baseUrl}?PersonId=${personId}`;

//     return fetch(url, obj).then(response => (response.json()));
// }

async function FilterDetailAsync(filter: PositionDetailFilter, pageNumber: number, itemsPage: number): Promise<PositionDetail> {
	let baseUrl = `${SiteProps.SiteURL}/api/${ApiUrls.Positions}/GetRecordPosition`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	if (filter.PersonId > 0) {
		queryString += '&PersonId=' + filter.PersonId;
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
}

async function ExportToPDF(baseUrl: string, personId: number /*, totalNumberRows: number*/): Promise<string> {
	let baseURL = `${SiteProps.SiteURL}/api/${baseUrl}/GetDataPdf?PersonId=${personId}`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};
	return fetch(baseURL, obj).then((response: any) => utils.downloadBlob(response, utils.getPdfNames('position_detail')));
}

async function ExportToExcel(filter: PositionFilter): Promise<any> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Positions}/ExportToExcel`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let randomGuid: string = utils.generateRandomGuid(false);
	let url = `${baseURL}?`;
	let queryString = GetFilterQueryString(filter);

	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('positions-files')),
	);
}

function GetFilterQueryString(filter: PositionFilter): string {
	let queryString: string = '';

	if (filter.Name != null) {
		queryString += '&Name=' + filter.Name;
	}

	if (filter.CompaniesId.length > 0) {
		queryString += '&CompaniesId=' + filter.CompaniesId.toString() + '';
	}

	if (filter.Dni != null) {
		queryString += '&Dni=' + filter.Dni;
	}

	if (filter.Cif != null) {
		queryString += '&Cif=' + filter.Cif;
	}

	if (filter.PositionTypeId != null) {
		queryString += '&PositionTypeId=' + filter.PositionTypeId;
	}

	// if (filter.Valid != null) {
	// 	queryString += '&Valid=' + filter.Valid;
	// }

	return queryString;
}

export const PositionApi = {
	FilterAsync,
	FilterDetailAsync,
	ExportToExcel,
	ExportToPDF,
};
