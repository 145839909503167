import { GenericApi } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { BaseType } from '../../../model';

export const getAuthorizationStatusNamesAction = () => (dispatch) => {
	dispatch(httpCallStartAction());

	GenericApi.GetAsync<BaseType>(ApiUrls.AuthorizationStatus).then((result) => {
		dispatch(getAuthorizationStatusNamesCompleted(result));
	});
};

const getAuthorizationStatusNamesCompleted = (authorizationStatusNames: BaseType[]) => ({
	type: actionTypes.GET_AUTHORIZATION_STATUS_NAMES,
	payload: authorizationStatusNames,
	meta: {
		httpEnd: true,
	},
});
