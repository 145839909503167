import { actionTypes } from '../common/const/actionTypes';
import { BodyTypes, BodyTypesCollection, createBodyType, createEmptyBodyTypes } from '../model/BodyTypes';
import { BodyTypesFilter, createEmptyBodyTypesFilter } from '../model/filters/BodyTypesFilter';

export const bodyTypeFilterReducer = (state = createEmptyBodyTypesFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_BODY_TYPE_FILTER:
			return handleBodyTypeFilterCompleted(state, action.payload);
	}

	return state;
};
export const bodyTypesListReducer = (state = createEmptyBodyTypes(), action) => {
	switch (action.type) {
		case actionTypes.GET_BODY_TYPES:
			return handleGetBodyTypesCompleted(state, action.payload);
	}

	return state;
};

export const bodyTypeReducer = (state = createBodyType(), action) => {
	switch (action.type) {
		case actionTypes.GET_BODY_TYPE:
			return handleGetBodyTypeCompleted(state, action.payload);
	}

	return state;
};

export const bodyTypeFormReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.SAVE_BODY_TYPE_COMPLETED:
			return handleSaveBaseTypeCompleted(state, action.payload);
		case actionTypes.DELETE_BODY_TYPE:
			return handleDeleteBaseTypeCompleted(state, action.payload);
	}

	return state;
};

const handleGetBodyTypesCompleted = (state: BodyTypesCollection, payload: BodyTypesCollection) => {
	return payload;
};

const handleGetBodyTypeCompleted = (state: BodyTypes, payload: BodyTypes) => {
	return payload;
};

const handleDeleteBaseTypeCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleSaveBaseTypeCompleted = (state: number, payload: number) => {
	return payload;
};
const handleBodyTypeFilterCompleted = (state: BodyTypesFilter, payload: BodyTypesFilter) => {
	return payload;
};
