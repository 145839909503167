import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Language } from '../../model';
import { ArchiveManagementComponent } from './list/page';

export interface IProps {
	language: Language;
}

export class ArchiveManagementPage extends React.Component<RouteComponentProps<{}> & IProps, {}> {
	public render() {
		return <ArchiveManagementComponent language={this.props.language} />;
	}
}
