import { actionTypes } from '../common/const/actionTypes';
import { User } from '../model/';

const createEmptyUser = (): User => ({
	name: '',
	token: '',
	rol: '',
	email: '',
	roles: [],
	preferredLanguage: ''
});

export const userReducer = (state = createEmptyUser(), action) => {
	switch (action.type) {
		case actionTypes.GET_USER:
			return handleUserCompleted(state, action.payload);
	}
	return state;
};

const handleUserCompleted = (state: User, payload: User) => {
	return payload;
};
