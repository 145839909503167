import { ISelectOption } from '../../common/customSelect/customSelect';
import { ActiveDocumentationOrderFilter } from './ActiveDocumentationOrderFilter';
import { OrderBy } from './OrderBy';

export interface DocumentationFilter {
	CompanyOrder: OrderBy;
	DocTypeOrder: OrderBy;
	DocSubtypeOrder: OrderBy;
	DateOrder: OrderBy;
	CopyOrder: OrderBy;
	CountryOrder: OrderBy;
	ProjectOrder: OrderBy;
	CommentsOrder: OrderBy;
	RemarkOrder: OrderBy;

	CompaniesId: number[];
	CompaniesName: string[];
	TradeId: number;
	TradeName: string;
	DocTypeId: number;
	DocTypeName: string;
	DocSubtypeId: number;
	DocSubtypeName: string;
	OriginalId: number;
	OriginalName: string;
	CountryId: number;
	CountryName: string;
	Copy: string;
	Location: string;
	Project: string;
	Remark: string;
	FromDate: Date;
	ToDate: Date;
	UniqueCode?: string;
	Simple: string;

	CurrentFilter: ActiveDocumentationOrderFilter;
	ItemsPage: number;
	selectedCompanies: ISelectOption[];
}

export const createEmptyDocumentationFilter = (): DocumentationFilter => {
	return {
		CurrentFilter: ActiveDocumentationOrderFilter.Company,
		CompanyOrder: OrderBy.Asc,
		DocTypeOrder: OrderBy.Asc,
		DocSubtypeOrder: OrderBy.Asc,
		DateOrder: OrderBy.Asc,
		CopyOrder: OrderBy.Asc,
		CountryOrder: OrderBy.Asc,
		ProjectOrder: OrderBy.Asc,
		CommentsOrder: OrderBy.Asc,
		RemarkOrder: OrderBy.Asc,

		CompaniesId: [],
		CompaniesName: [],
		TradeId: 0,
		TradeName: '',
		DocTypeId: 0,
		DocTypeName: '',
		DocSubtypeId: 0,
		DocSubtypeName: '',
		OriginalId: 0,
		OriginalName: '',
		CountryId: 0,
		CountryName: '',
		Copy: '',
		Location: '',
		Project: '',
		Remark: '',
		FromDate: null,
		ToDate: null,
		UniqueCode: '',
		Simple: '',
		ItemsPage: 10,
		selectedCompanies: []
	};
};
