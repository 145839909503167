import { TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { SearchPeople } from '../../../../common/search/searchPeople';
import { Language, NotaryData, NotaryPeople, SearchSelectModel } from '../../../../model';
import * as utils from '../../../../utils/extensions';
import { getResourcesFile } from '../../../../utils/extensions';

interface IProps {
	notaryData: NotaryData;
	notaryPeople: NotaryPeople[];
	updateNotaryData: (notaryData: NotaryData) => void;
	getNotaryPeopleNames: (filter: string) => void;
	language: Language;
	isReadOnly: boolean;
}

export class NotaryDataTab extends React.Component<IProps, {}> {
	public render() {

		let selectedNotary = { value: this.props.notaryData?.notaryPeopleId, label: this.props.notaryData?.notaryPeopleName };

		let language = getResourcesFile(this.props.language);
		return (
			<div>
				<div className='ms-Grid-row'>
					<div className='ms-Grid-col ms-md3 content_edit_block_filter'>
						<div className='searchPeopleLongWidth'>
							<SearchPeople
								title={language['deeds']['form']['sections']['notarial-data']['fields']['notary']['title']}
								placeholder={language['deeds']['form']['sections']['notarial-data']['fields']['notary']['placeholder']}
								language={this.props.language}
								selectedOption={utils.isNullOrEmpty(selectedNotary.label) ? null : selectedNotary}
								loadOptions={this.loadOptions}
								handleNameChange={this.handleNotaryPeopleChange}
								getUsers={this.props.getNotaryPeopleNames}
								disabled={this.props.isReadOnly}
								onClearValue={this.onClearSearchPeople}
								showDeleteButton={true}
							/>
						</div>
					</div>
					<div className='ms-Grid-col ms-md3 content_edit_block_filter'>
					<div className='countryLongWidth'>
						<p>{language['deeds']['form']['sections']['notarial-data']['fields']['country']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value = {utils.isNullOrEmpty(this.props.notaryData?.city) ? '' : this.props.notaryData?.city}
							onChange={this.handleCityChange}
							onBlur={this.handleCityBlur}
							disabled={this.props.isReadOnly}
							maxLength={100}
						/>
						</div>
					</div>					
				</div>
			</div>
		);
	}

	private readonly onClearSearchPeople = (): void => {
		if(!this.props.isReadOnly){
			let notaryData = { ...this.props.notaryData };	
			notaryData.notaryPeopleId = null;
			notaryData.notaryPeopleName = null;
			this.props.updateNotaryData(notaryData);
		}		
	};

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filter(inputValue));
		}, 1000);
	};

	private readonly filter = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.notaryPeople && this.props.notaryPeople.length > 0) {
			this.props.notaryPeople.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};	

	private readonly handleNotaryPeopleChange = (selected): void => {
		let notaryData = { ...this.props.notaryData };	
		notaryData.notaryPeopleId = parseInt(selected?.value);
		notaryData.notaryPeopleName = selected?.label;
		this.props.updateNotaryData(notaryData);
	};	

	private readonly handleNotaryChange = (value) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.notary = value.target.value;
		this.props.updateNotaryData(notaryData);
	};
	private readonly handleNotaryBlur = (value) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.notary = value.target.value.toUpperCase();
		this.props.updateNotaryData(notaryData);
	};
	private readonly handleCityChange = (value) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.city = value.target.value;
		this.props.updateNotaryData(notaryData);
	};
	private readonly handleCityBlur = (value) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.city = value.target.value.toUpperCase();
		this.props.updateNotaryData(notaryData);
	};

	private readonly handleDocumentDateChange = (value): void => {
		let notaryData = { ...this.props.notaryData };
		notaryData.notaryDate = value;
		this.props.updateNotaryData(notaryData);
	};

	private readonly onClearDate = (): void => {
		this.handleDocumentDateChange(null);
    };		
}
