import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { GenericApi } from '../../../api/generic';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { BaseType } from '../../../model';

export const getOriginalsAction = () => (dispatch) => {
    dispatch(httpCallStartAction());

    GenericApi.GetAsync<BaseType>(ApiUrls.Original).then((result) => {
        dispatch(getOriginalsCompleted(result));
    });
};

const getOriginalsCompleted = (originals: BaseType[]) => ({
        type: actionTypes.GET_ORIGINALS,
        payload: originals,
        meta: {
            httpEnd: true,
        }
    });