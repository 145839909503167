import { actionTypes } from '../../../common/const/actionTypes';
import { AuthorizationFilter } from '../../../model/filters';

export const setAuthorizarionFilterAction = (filter: AuthorizationFilter) => (dispatch) => {
	dispatch(setAuthorizationFilterActionCompleted(filter));
};
const setAuthorizationFilterActionCompleted = (authorizationFilter: AuthorizationFilter) => ({
	type: actionTypes.SET_AUTHORIZATION_FILTER,
	payload: authorizationFilter,
});
