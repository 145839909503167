import { actionTypes } from '../../../../common/const/actionTypes';
import { PositionFilter } from '../../../../model/filters';

export const setPositionFilterAction = (filter: PositionFilter) => (dispatch) => {
	dispatch(setPositionFilterActionCompleted(filter));
};
const setPositionFilterActionCompleted = (positionFilter: PositionFilter) => ({
	type: actionTypes.SET_POSITION_FILTER,
	payload: positionFilter,
});
