import { actionTypes } from '../../../common/const/actionTypes';
import {
	AuthorizationFilter,
	BodyTypesFilter,
	CompanyFilter,
	createEmptyAuthorizationFilter,
	createEmptyBodyTypesFilter,
	createEmptyCompanyFilter,
	createEmptyDeedFilter,
	createEmptyDepartmentFilter,
	createEmptyDocumentationFilter,
	createEmptyDocumentTypeFilter,
	createEmptyPersonFilter,
	createEmptyPositionDetailFilter,
	createEmptyPositionFilter,
	createEmptyPositionTypeFilter,
	createEmptyRecordCompanyDetailFilter,
	createEmptyRepresentativeDetailFilter,
	createEmptyRepresentativeFilter,
	DeedFilter,
	DepartmentFilter,
	DocumentationFilter,
	DocumentTypeFilter,
	PersonFilter,
	PositionDetailFilter,
	PositionFilter,
	PositionTypeFilter,
	RecordCompanyDetailFilter,
	RepresentativeDetailFilter,
	RepresentativeFilter,
} from '../../../model/filters';

export const cleanFilterAction = () => (dispatch) => {
	let companyFilter: CompanyFilter = createEmptyCompanyFilter();
	let deedFilter: DeedFilter = createEmptyDeedFilter();
	let representativeFilter: RepresentativeFilter = createEmptyRepresentativeFilter();
	let representativeDetailFilter: RepresentativeDetailFilter = createEmptyRepresentativeDetailFilter();
	let positionFilter: PositionFilter = createEmptyPositionFilter();
	let positionDetailFilter: PositionDetailFilter = createEmptyPositionDetailFilter();
	let recordCompanyDetailFilter: RecordCompanyDetailFilter = createEmptyRecordCompanyDetailFilter();
	let authorizationFilter: AuthorizationFilter = createEmptyAuthorizationFilter();
	let departmentFilter: DepartmentFilter = createEmptyDepartmentFilter();
	let positionTypeFilter: PositionTypeFilter = createEmptyPositionTypeFilter();
	let documentTypeFilter: DocumentTypeFilter = createEmptyDocumentTypeFilter();
	let personFilter: PersonFilter = createEmptyPersonFilter();
	let bodyTypeFilter: BodyTypesFilter = createEmptyBodyTypesFilter();
	let documentationFilter: DocumentationFilter = createEmptyDocumentationFilter();

	dispatch(cleanComapanyFilterActionCompleted(companyFilter));
	dispatch(cleanDeedFilterActionCompleted(deedFilter));
	dispatch(cleanRepresentativeFilterActionCompleted(representativeFilter));
	dispatch(cleanRepresentativeDetailFilterActionCompleted(representativeDetailFilter));
	dispatch(cleanPositionFilterActionCompleted(positionFilter));
	dispatch(cleanPositionDetailFilterActionCompleted(positionDetailFilter));
	dispatch(cleanRecordCompanyDetailFilterActionCompleted(recordCompanyDetailFilter));
	dispatch(cleanAuthorizationsFilterActionCompleted(authorizationFilter));
	dispatch(cleanDepartmentFilterActionCompleted(departmentFilter));
	dispatch(cleanPositionTypeFilterActionCompleted(positionTypeFilter));
	dispatch(cleanDocumentTypeFilterActionCompleted(documentTypeFilter));
	dispatch(cleanPersonFilterActionCompleted(personFilter));
	dispatch(cleanBodyTypeFilterActionCompleted(bodyTypeFilter));
	dispatch(cleanDocumentationFilterActionCompleted(documentationFilter));
};
const cleanComapanyFilterActionCompleted = (companyFilter: CompanyFilter) => ({
	type: actionTypes.SET_COMPANIES_FILTER,
	payload: companyFilter,
});
const cleanDeedFilterActionCompleted = (deedFilter: DeedFilter) => ({
	type: actionTypes.SET_DEED_FILTER,
	payload: deedFilter,
});
const cleanRepresentativeFilterActionCompleted = (representativeFilter: RepresentativeFilter) => ({
	type: actionTypes.SET_REPRESENTATIVE_FILTER,
	payload: representativeFilter,
});
const cleanRepresentativeDetailFilterActionCompleted = (representativeDetailFilter: RepresentativeDetailFilter) => ({
	type: actionTypes.SET_REPRESENTATIVE_DETAIL_FILTER,
	payload: representativeDetailFilter,
});
const cleanPositionFilterActionCompleted = (positionFilter: PositionFilter) => ({
	type: actionTypes.SET_POSITION_FILTER,
	payload: positionFilter,
});
const cleanPositionDetailFilterActionCompleted = (positionDetailFilter: PositionDetailFilter) => ({
	type: actionTypes.SET_POSITION_DETAIL_FILTER,
	payload: positionDetailFilter,
});
const cleanRecordCompanyDetailFilterActionCompleted = (recordCompanyDetailFilter: RecordCompanyDetailFilter) => ({
	type: actionTypes.SET_RECORD_COMPANY_DETAIL_FILTER,
	payload: recordCompanyDetailFilter,
});
const cleanAuthorizationsFilterActionCompleted = (authorizationFilter: AuthorizationFilter) => ({
	type: actionTypes.SET_AUTHORIZATION_FILTER,
	payload: authorizationFilter,
});
const cleanDepartmentFilterActionCompleted = (departmentsFilter: DepartmentFilter) => ({
	type: actionTypes.SET_DEPARTMENTS_FILTER,
	payload: departmentsFilter,
});
const cleanPositionTypeFilterActionCompleted = (positionTypeFilter: PositionTypeFilter) => ({
	type: actionTypes.SET_POSITION_TYPES_FILTER,
	payload: positionTypeFilter,
});
const cleanDocumentTypeFilterActionCompleted = (documentTypeFilter: DocumentTypeFilter) => ({
	type: actionTypes.SET_DOCUMENT_TYPE_FILTER,
	payload: documentTypeFilter,
});
const cleanPersonFilterActionCompleted = (personFilter: PersonFilter) => ({
	type: actionTypes.SET_PERSON_FILTER,
	payload: personFilter,
});
const cleanBodyTypeFilterActionCompleted = (bodyTypeFilter: BodyTypesFilter) => ({
	type: actionTypes.SET_BODY_TYPE_FILTER,
	payload: bodyTypeFilter,
});
const cleanDocumentationFilterActionCompleted = (documentationFilter: DocumentationFilter) => ({
	type: actionTypes.SET_DOCUMENTATION_FILTER,
	payload: documentationFilter,
});
