import { actionTypes } from '../common/const/actionTypes';
import {  ApplicationProfile } from '../model/';

export interface UserState {
	currentUser: ApplicationProfile;
}

const initialState: UserState = {
	currentUser: null,
};

export const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_CURRENT_USER_COMPLETED:
			return handleUserCompleted(state, action.payload);
	}
	return state;
};

const handleUserCompleted = (state: UserState, payload: ApplicationProfile) => {
	return {
		...state,
		currentUser: payload,
	};
};
