import { GenericApi } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DocumentType } from '../../../model';

export const getDocumentTypeAction = (id: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	GenericApi.GetByIdAsync<DocumentType>(ApiUrls.DocumentTypes, id).then((result) => {
		dispatch(getDocumentTypeCompleted(result));
	});
};

const getDocumentTypeCompleted = (result: DocumentType) => ({
	type: actionTypes.GET_DOCUMENT_TYPE,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
