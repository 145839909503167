import * as React from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps, withRouter } from 'react-router';
import { User, Language, BaseType, BodyTypesCollection, OrderBy } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { UserRoles } from '../../../model/enums/userRoles';
import { languages } from '../../../common/const/resources';
import { DefaultButton } from 'office-ui-fabric-react/lib/components/Button/DefaultButton/DefaultButton';
import * as utils from '../../../utils/extensions';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { BodyTypesFilter } from '../../../model/filters/BodyTypesFilter';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { ActiveBodyTypesFilter } from '../../../model/filters/ActiveBodyTypesFilter';
import { routeUrls } from '../../../common/const/routes';

export interface IProps {
	bodyTypesCollection: BodyTypesCollection;
	business: BaseType[];
	user: User;
	language: Language;
	filter: BodyTypesFilter;
	handleItemsPerPageChange: (itemsPage: number) => any;
	getBodyTypes: (filter: BodyTypesFilter, pageNumber: number, itemsPage: number) => Promise<BodyTypesCollection>;
	exportToExcel: (filter: BodyTypesFilter) => Promise<any>;
	deleteBodyType: (filter: BodyTypesFilter, language: Language, id: number, itemsPage: number) => Promise<boolean>;
}

export interface IState {
	showModal: boolean;
	id: number;
	isAuthorized: boolean;
	isLoading: boolean;
	collapsed: boolean;
	actualFilter: BodyTypesFilter;
	orderByDescriptionSpanish: string;
	orderByDescriptionEnglish: string;
	orderByTradeId: string;
}

class BodyTypesDataGrid extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			showModal: false,
			isAuthorized: false,
			isLoading: true,
			collapsed: false,
			actualFilter: this.props.filter,
			orderByTradeId: 'ms-Icon--ChevronUpSmall',
			orderByDescriptionSpanish: 'ms-Icon--ChevronUpSmall',
			orderByDescriptionEnglish: 'ms-Icon--ChevronUpSmall',
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	
	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ actualFilter: this.props.filter });
		}
	}
	public render() {
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		const { actualFilter } = this.state;
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let optionText: string = language['generic']['list']['table']['options']['edit'];
		if (this.props.user.rol === UserRoles.reader) {
			optionText = language['generic']['list']['table']['options']['view'];
		}
		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.bodyTypesCollection.totalNumberOfRows > 1 ? (
								<span>{this.props.bodyTypesCollection.totalNumberOfRows} {language['generic']['list']['table']['results']}</span>
							) : this.props.bodyTypesCollection.totalNumberOfRows === 1 ? (
								<span>{this.props.bodyTypesCollection.totalNumberOfRows} {language['generic']['list']['table']['result']}</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={this.props.bodyTypesCollection.totalNumberOfRows > 0 ? 'button-download' : 'button-download isDisabled'}
							onClick={this.handleExportClick}>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th>
									<div className='table-header'>
										<span>{language['body-types']['list']['filter']['options']['description-es']['title']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveBodyTypesFilter.DescriptionSpanish === actualFilter.CurrentFilter &&
													actualFilter.DescriptionSpanishOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionSpanish}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveBodyTypesFilter.DescriptionSpanish === actualFilter.CurrentFilter &&
													actualFilter.DescriptionSpanishOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionSpanish}
											></i>
										</div>
									</div>
								</th>
								<th>
									<div className='table-header'>
										<span>{language['body-types']['list']['filter']['options']['description-en']['title']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveBodyTypesFilter.DescriptionEnglish === actualFilter.CurrentFilter &&
													actualFilter.DescriptionEnglishOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionEnglish}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveBodyTypesFilter.DescriptionEnglish === actualFilter.CurrentFilter &&
													actualFilter.DescriptionEnglishOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionEnglish}
											></i>
										</div>
									</div>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.props.bodyTypesCollection?.list.map((item) => {
								return (
									<tr>
										<td>{item.descriptionSpanish}</td>
										<td>{item.descriptionEnglish}</td>
										<td className='deleteItem'>
											<div>
												<DefaultButton
													text='. . .'
													disabled={!isAdmin}
													menuProps={{
														shouldFocusOnMount: true,
														items: [
															{
																key: 'edit',
																onClick: () => {
																	this.props.history.push('/bodyTypes/new/' + item.id);
																},
																iconProps: {
																	iconName: 'Edit',
																	className: 'context-menu__icon',
																},
																name: optionText,
															},
															{
																key: 'delete',
																iconProps: {
																	iconName: 'Delete',
																	className: 'context-menu__icon',
																},
																onClick: () => {
																	this.ShowModal(item.id);
																},
																name: language['generic']['list']['table']['options']['delete'],
															},
														],
													}}
													className='tdNoBorder'
												/>
												<DialogComponent
													showModal={this.state.showModal}
													TitleDialog={language['generic']['dialog']['title']}
													QuestionDialog={language['generic']['dialog']['question']}
													onSuccess={() => {
														this.Delete();
													}}
													onFail={() => {
														this.Fail();
													}}
													CancelTitle={language['generic']['dialog']['cancel']}
													SuccessTitle={language['generic']['dialog']['confirm']}
												/>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{
					<AdvancedPagination
						currentPage={this.props.page}
						items={this.props.bodyTypesCollection.totalNumberOfRows}
						link={routeUrls.bodyTypesPagination}
						updateMaxPageItems={this.handleItemsPerPageChange}
						actualPageItems={this.state.actualFilter.ItemsPage}
						language={this.props.language}
					/>
				}
			</>
		);
	}

	private readonly Delete = () => {
		this.props.deleteBodyType(this.props.filter, this.props.language, this.state.id, this.props.itemsPage);
		this.Fail();
	};

	private Fail() {
		this.setState({ showModal: false, id: 0 });
	}

	private ShowModal(id: number) {
		this.setState({ showModal: true, id: id });
	}

	public readonly handleOrderByDescriptionSpanish = (event) => {
		let filter: BodyTypesFilter = this.props.filter;
		filter.CurrentFilter = ActiveBodyTypesFilter.DescriptionSpanish;
		let orderBy: string = '';

		if (filter.DescriptionSpanishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DescriptionSpanishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DescriptionSpanishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDescriptionSpanish: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getBodyTypes(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDescriptionEnglish = (event) => {
		let filter: BodyTypesFilter = this.props.filter;
		filter.CurrentFilter = ActiveBodyTypesFilter.DescriptionEnglish;
		let orderBy: string = '';

		if (filter.DescriptionEnglishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DescriptionEnglishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DescriptionEnglishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDescriptionEnglish: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getBodyTypes(filter, 1, filter.ItemsPage);
	};

	public handleExportClick = () => {
		let filter: BodyTypesFilter = this.props.filter;
		this.props.exportToExcel(filter);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: BodyTypesFilter = { ...this.state.actualFilter };
		filter.ItemsPage = itemsPage
		this.setState({ actualFilter: filter });
		this.props.getBodyTypes(filter, 1, itemsPage);
	};
}

export default withRouter(BodyTypesDataGrid);
