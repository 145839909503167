import { actionTypes } from '../common/const/actionTypes';
import { createEmptyDocumentDetail, createEmptyDocuments, DocumentDetail, DocumentsCollection } from '../model';
import { createEmptyDocumentationFilter, DocumentationFilter } from '../model/filters';

export const documentationReducer = (state = createEmptyDocuments(), action) => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENTATION:
			return handleGetDocumentationCompleted(state, action.payload);
	}
	return state;
};

export const documentationFormReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.DELETE_DOCUMENTATION:
			return handleDeleteDocumentationCompleted(state, action.payload);
		case actionTypes.SAVE_DOCUMENTATION_COMPLETED:
			return handleSaveDocumentationCompleted(state, action.payload);
	}
	return state;
};

export const documentReducer = (state = createEmptyDocumentDetail(), action) => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENT:
			return handleGetDocumentCompleted(state, action.payload);
	}

	return state;
};

export const documentationFilterReducer = (state = createEmptyDocumentationFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_DOCUMENTATION_FILTER:
			return handleSetDocumentationFilterCompleted(state, action.payload);
	}

	return state;
};

const handleSaveDocumentationCompleted = (state: number, payload: number) => {
	return payload;
};

const handleGetDocumentationCompleted = (state: DocumentsCollection, payload: DocumentsCollection) => {
	return payload;
};

const handleDeleteDocumentationCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleGetDocumentCompleted = (state: DocumentDetail, payload: DocumentDetail) => {
	return payload;
};

const handleSetDocumentationFilterCompleted = (state: DocumentationFilter, payload: DocumentationFilter) => {
	return payload;
};
