import { actionTypes } from '../common/const/actionTypes';
import { BaseCode } from '../model/Base';

const createEmptyBaseCodeList = (): BaseCode[] => {
	return [];
};

export const baseCodeListReducer = (state = createEmptyBaseCodeList(), action) => {
	switch (action.type) {
		case actionTypes.GET_LEGAL_FORMS:
			return handleGetBaseCodeListCompleted(state, action.payload);
	}

	return state;
};

const handleGetBaseCodeListCompleted = (state: BaseCode[], payload: BaseCode[]) => {
	return payload;
};
