import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { PositionType } from '../../../model';
import { GenericApi } from '../../../api/generic';
import { ApiUrls } from '../../../common/const/api';

export const getPositionTypeAction = (id: number) => (dispatch) => {
    dispatch(httpCallStartAction());
    GenericApi.GetByIdAsync<PositionType>(ApiUrls.PositionTypes, id).then((result) => {
        dispatch(getPositionTypeCompleted(result));
    });
};

const getPositionTypeCompleted = (result: PositionType) => ({
    type: actionTypes.GET_POSITION_TYPE,
    payload: result,
    meta: {
        httpEnd: true,
    }
});