import { actionTypes } from '../../../../common/const/actionTypes';
import { CompanyFilter } from '../../../../model/filters';

export const setCompanyFilterAction = (filter: CompanyFilter) => (dispatch) => {
	dispatch(setCompanyFilterActionCompleted(filter));
};
const setCompanyFilterActionCompleted = (companiesFilter: CompanyFilter) => ({
	type: actionTypes.SET_COMPANIES_FILTER,
	payload: companiesFilter,
});
