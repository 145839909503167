import { actionTypes } from '../common/const/actionTypes';
import {
	CompanyBase,
	CompanyCollection,
	CompanySearch,
	createEmptyCompanies,
	createEmptyCompany, createEmptyTradeCompanyList,
	TradeCompany
} from '../model';
import { CompanyFilter, createEmptyCompanyFilter } from '../model/filters/CompanyFilter';

export const companyFilterReducer = (state = createEmptyCompanyFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_COMPANIES_FILTER:
			return handleSetCompanyFilterCompleted(state, action.payload);
	}

	return state;
};
export const companyListReducer = (state = createEmptyCompanies(), action) => {
	switch (action.type) {
		case actionTypes.GET_COMPANIES:
			return handleGetCompaniesCompleted(state, action.payload);
	}

	return state;
};

export const companyReducer = (state = createEmptyCompany(), action) => {
	switch (action.type) {
		case actionTypes.GET_COMPANY:
			return handleGetCompanyCompleted(state, action.payload);
	}

	return state;
};
export const companySearchReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.GET_COMPANY_SEARCH:
			return handleGetCompanySearchCompleted(state, action.payload);
	}

	return state;
};
export const companyFormReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.SAVE_COMPANY_COMPLETED:
			return handleSaveCompanyCompleted(state, action.payload);
		case actionTypes.DELETE_COMPANY:
			return handleDeleteCompanyCompleted(state, action.payload);
	}

	return state;
};

export const tradeCompanyListReducer = (state = createEmptyTradeCompanyList(), action) => {
	switch (action.type) {
		case actionTypes.GET_COMPANIES_WITH_TRADE:
			return handleGetTradeCompanyListCompleted(state, action.payload);
	}

	return state;
};

const handleGetCompaniesCompleted = (state: CompanyCollection, payload: CompanyCollection) => {
	return payload;
};

const handleGetCompanyCompleted = (state: CompanyBase, payload: CompanyBase) => {
	return payload;
};
const handleGetCompanySearchCompleted = (state: CompanySearch[], payload: CompanySearch[]) => {
	return payload;
};
const handleDeleteCompanyCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleSaveCompanyCompleted = (state: number, payload: number) => {
	return payload;
};
const handleSetCompanyFilterCompleted = (state: CompanyFilter, payload: CompanyFilter) => {
	return payload;
};

const handleGetTradeCompanyListCompleted = (state: TradeCompany[], payload: TradeCompany[]) => {
	return payload;
};
