import { DeedApi } from '../../../../api/deed';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import useHistory from '../../../../history';
import { Deed, Language } from '../../../../model';
import { getResourcesFile, showError, showSuccess } from '../../../../utils/extensions';
const browserHistory = useHistory;

export const saveDeedAction = (deed: Deed, currentLanguage: Language) => (dispatch) => {
    dispatch(httpCallStartAction());
    let language = getResourcesFile(currentLanguage);
    if (deed.id > 0) {
        handleUpdateDeed(deed, language, dispatch);
    } else {
        handleCreateDeed(deed, language, dispatch);
    }
};

const handleUpdateDeed = (deed: Deed, language: any, dispatch) => {
    DeedApi.UpdateAsync(ApiUrls.Deed, deed).then((result: any) => {
        if (result.error) {
            showError(language['deeds']['form']['messages']['error-update'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDeedCompleted(result));
        }
        else {
            showSuccess(language['deeds']['form']['messages']['success-update']);
            browserHistory.goBack();
            dispatch(saveDeedCompleted(result));
        }
    });
}

const handleCreateDeed = (deed: Deed, language: any, dispatch) => {
    DeedApi.AddAsync(ApiUrls.Deed, deed).then((result: any) => {
        if (result > 0) {
            showSuccess(language['deeds']['form']['messages']['success']);
            browserHistory.goBack();
            dispatch(saveDeedCompleted(result));
        } else {
            showError(language['deeds']['form']['messages']['error-update'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDeedCompleted(result));
        }
    });
}

const saveDeedCompleted = (result: number) => ({
    type: actionTypes.SAVE_DEED_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});