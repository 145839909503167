import * as React from 'react';
import * as utils from '../../../utils/extensions';
import { RouteComponentProps } from 'react-router';
import { BaseName, BaseType, Language, CompanyCollection, User, UserState } from '../../../model/';
import { CompanyFilter } from '../../../model/filters/';
import { RecordCompaniesSearchComponent } from './search';
import { aJNoCoporacion, onlySocietalSheetModule } from '../../../common/const/rolesAssignments';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import { languages } from '../../../common/const/resources';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { SpinnerContainer } from '../../../common/spinner';
import { UnauthorizedView } from '../../../common/unauthorizedView/unauthorizedView';
import { RecordCompanyDataGrid } from './grid';
import { NoResultsComponent } from '../../../common/noResults/noResults';
import { routeUrls } from '../../../common/const/routes';

export interface IProps {
	companies: CompanyCollection;
	companyNames: BaseName[];
	tradeNames: BaseType[];
	countryNames: BaseName[];
	user: User;
	language: Language;
	currentUser: UserState;
	filter: CompanyFilter;

	getCompanies: (filter: CompanyFilter, pageNumbers: number, itemsPage: number) => Promise<CompanyCollection>;
	getCompanyNames: () => Promise<BaseName[]>;
	getTradeNames: () => Promise<BaseType[]>;
	getCountryNames: () => Promise<BaseName[]>;
	setCompanyFilter: (filter: CompanyFilter) => CompanyFilter;
	exportToExcel: (filter: CompanyFilter) => Promise<any>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	isAdmin: boolean;
	collapsed: boolean;
	filter: CompanyFilter;
}

export class RecordCompaniesListPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isAuthorized: false,
			isLoading: true,
			isAdmin: false,
			collapsed: false,
			filter: this.props.filter,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlySocietalSheetModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let isAdmin = utils.isInRole(userRoles.admin);
		this.setState({ isAdmin: isAdmin });
		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		if (this.props.location.state != null) {
			this.props.getCompanies(this.props.location.state.companyFilter, page, this.state.filter.ItemsPage);
		} else {
			this.props.getCompanies(this.props.filter, page, this.props.filter.ItemsPage);
		}

		this.props.getCompanyNames();
		this.props.getTradeNames();
		this.props.getCountryNames();
	}

	public componentDidUpdate(prevProps) {
		let currentUserOld = prevProps.currentUser;
		if (currentUserOld.currentUser === null) {
			let isAuth = utils.isInRoles(onlySocietalSheetModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}
		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];

		if(this.props.tradeNames !== prevProps.tradeNames){
			if(utils.isInRoles(aJNoCoporacion)){
				let defaultOption = utils.getBusinessByRole(this.props.currentUser?.currentUser.roles[0]);
				if (defaultOption !== 0) {
					let filter = { ...this.state.filter };
					filter.TradeId = defaultOption;
					this.setState({ filter: filter }, () => this.props.getCompanies(filter, newPage, this.props.filter.ItemsPage));
				}
			}	
		}	
		
		if (newPage !== oldPage) {
			this.props.getCompanies(this.props.filter, newPage, this.props.filter.ItemsPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['corporate']['list']['title']}</h1>
						</div>
						<div className='ms-Grid'>
							<RecordCompaniesSearchComponent
								filter={this.state.filter}
								companyNames={this.props.companyNames}
								tradeNames={this.props.tradeNames}
								countryNames={this.props.countryNames}
								getCompanies={this.props.getCompanies}
								onchangeFilter={this.handleFilterChange}
								language={this.props.language}
								collapsed={this.state.collapsed}
								itemsPage={this.state.filter.ItemsPage}
								user={this.props.currentUser}
								getTradeCompanies={this.props.getTradeCompanies}
								tradeCompanies={this.props.tradeCompanies}
							/>
							{this.props.companies.list?.length > 0 ? (
								<RecordCompanyDataGrid
									language={this.props.language}
									companies={this.props.companies}
									user={this.props.user}
									filter={this.state.filter}
									page={page}
									getCompanies={this.props.getCompanies}
									setCompanyFilter={this.props.setCompanyFilter}
									exportToExcel={this.props.exportToExcel}
									onchangeFilter={this.handleFilterChange}
								/>
							) : (
								<NoResultsComponent
									language={this.props.language}
									list={language['corporate']['list']['no-result-title']}
								/>
							)}
						</div>
					</>
				)}
			</>
		);
	}

	public readonly handleFilterChange = (newfilter: CompanyFilter): void => {
		this.setState({ filter: newfilter });
		this.props.history.push(routeUrls.recordCompaniesPagination + 1);
	};
}
