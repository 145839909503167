import { IComboBoxOption, TextField } from 'office-ui-fabric-react';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { languages } from '../../../common/const/resources';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { BaseType, Department, Language, PositionType } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { createEmptyPositionType } from '../../../reducers/positionTypeReducer';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
	savePositionType: (positionype: PositionType, language: Language) => Promise<number>;
	getPositionType: (id: number) => PositionType;
	positionType: PositionType;
	positionTypeSave: number | boolean;
	language: Language;
	business: BaseType[];
	loading: boolean;
	getBusiness: () => Promise<BaseType[]>;
}
export interface IState {
	errorMessages: string[];
	newPositionType: Department;
}
export class PositionTypeComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			errorMessages: [],
			newPositionType: createEmptyPositionType(),
		};
	}
	public componentDidMount() {
		if (this.props.match.params['id'] != null) {
			this.props.getPositionType(this.props.match.params['id']);
		}
		this.props.getBusiness();
	}

	public componentWillReceiveProps(props) {
		if (props.positionType !== this.props.positionType) {
			this.setState({ newPositionType: { ...props.positionType } });
		}
	}

	public render() {
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		let validationErrors = this.state.errorMessages?.map((item: string) => {
			return <MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />;
		});

		let businessCombo: IComboBoxOption[] = [];
		this.props.business.forEach((item) => {
			businessCombo.push({
				key: item.id,
				text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish,
			});
		});
		return (
			<>
				<div className='ms-Grid-row'>
					<h1>
						{this.props.match.params['id'] == null ? language['position-types']['new'] : language['position-types']['edit']}
					</h1>
				</div>
				<div className='ms-Grid-col ms-sm12 ms-lg9 ms-xl10'>
					<div className='ms-Grid-row'>
						<div className='ms-Grid-col ms-sm12 ms-xl12'>
							<div className='main-container__content_edit ms-Grid-col ms-sm12 ms-xl3'>
								<p>{language['position-types']['fields']['descriptionSpanish']['title']}</p>
								<TextField
									type='text'
									value={this.state.newPositionType.descriptionSpanish}
									onChange={this.handleDescriptionSpanishChange}
									onBlur={this.handleDescriptionSpanishBlur}
									maxLength={100}
								/>
								<p>{language['position-types']['fields']['descriptionEnglish']['title']}</p>
								<TextField
									type='text'
									value={this.state.newPositionType.descriptionEnglish}
									onChange={this.handleDescriptionEnglishChange}
									onBlur={this.handleDescriptionEnglishBlur}
									maxLength={100}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='ms-Grid-row'>
					<hr />
					{validationErrors}
					{isAdmin && (
						<button
							type='button'
							className={`button-primary ${!this.props.loading ? '' : 'isDisabled'} `}
							name='saveDepartment'
							onClick={this.savePositionType}
						>
							{language['position-types']['save-button']}
						</button>
					)}
					<button onClick={utils.navigateBack} type='button' name='back' className='button-cancel'>
						{language['position-types']['cancel-button']}
					</button>
				</div>
			</>
		);
	}

	private readonly handleDescriptionSpanishChange = (value) => {
		let state = { ...this.state };
		state.newPositionType.descriptionSpanish = value.target.value;
		this.setState(state);
	};
	private readonly handleDescriptionSpanishBlur = (value) => {
		let state = { ...this.state };
		state.newPositionType.descriptionSpanish = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleDescriptionEnglishChange = (value) => {
		let state = { ...this.state };
		state.newPositionType.descriptionEnglish = value.target.value;
		this.setState(state);
	};
	private readonly handleDescriptionEnglishBlur = (value) => {
		let state = { ...this.state };
		state.newPositionType.descriptionEnglish = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly savePositionType = (): number => {
		let positionType = { ...this.state.newPositionType };
		if (this.validatePositionType(positionType)) {
			return this.props.savePositionType(positionType, this.props.language);
		}
		return 0;
	};
	private readonly validatePositionType = (positionType: PositionType) => {
		let errorMessages: string[] = [];
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		let hasDescriptionSpanish = !utils.isNullOrEmpty(positionType.descriptionSpanish);
		let hasDescriptionEnglish = !utils.isNullOrEmpty(positionType.descriptionEnglish);
		let isPositionTypeValid = hasDescriptionSpanish && hasDescriptionEnglish;
		if (!hasDescriptionSpanish) {
			errorMessages.push(language['generic']['messages']['required-spanish-description']);
		}
		if (!hasDescriptionEnglish) {
			errorMessages.push(language['generic']['messages']['required-english-description']);
		}

		if (!isPositionTypeValid) {
			this.setState({ errorMessages });
		}

		return isPositionTypeValid;
	};
}
